import React from 'react'
import { Message } from 'primereact/message'
import store from '../reducer/store'
import '../css/generals/header.css'

export default function Header () {
  const { user } = store.getState()
  const role = user.roles
  const msg = role?.roleName === 'Usuarios sin Rol'

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <button className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            href={() => false}
            role="button"
          >
            <i className="fas fa-bars" />
          </a>
        </li>
      </button>
      {msg &&
        <Message
        style={{
          border: 'solid #ff5757',
          borderWidth: '1px 1px 1px 0.5rem',
          backgroundColor: 'white'
        }}
        severity="error"
        text="No tienes un rol asignado. Contacta al administrador."
        />
      }
    </nav>
  )
}

import React, { useState } from 'react'
import { postData } from '../../../../actions/servicesHttp'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'
import styles from '../../../../css/RxartSecure/ActionsRS.module.css'
import ModalRSUnlockCode from '../modalRSUnlockCode'
import ModalRSStatusHistory from '../modalRSStatusHistory'
import ModalRSTicketsIssued from '../modalRSTicketsIssued'
import $ from 'jquery'

export default function ActionsRS ({ data, actions, setActions, device }) {
  // console.log(data, actions, device)
  // ? urls de la API cuco
  const [rxartSecureTicketUnlockUrl] = useState('RxartSecure?endpoint=calc_ticket_unlock')

  // Estados para mostrar o no los modales
  const [modalsShow, setModalsShow] = useState({
    newTicket: false,
    statusHistory: false,
    ticketsIssued: false
  })

  const [unlockCode, setUnlockCode] = useState({
    ct: '',
    uc: ''
  })

  const [loading, setLoading] = useState(false)

  const deviceStatusOptions = [
    { name: 'noChangeDevice', value: 'NCD', label: 'No cambiar de estado' },
    { name: 'enabledDevice', value: 'LE', label: 'Cambiar a estado normal / habilitado' },
    { name: 'lockDevice', value: 'BL', label: 'Bloquear dispositivo' },
    { name: 'unlockDevice', value: 'FR', label: 'Liberar control del dispositivo' }
  ]

  // const [statusDevice, setStatusDevice] = useState()

  const clearErrorMessages = () => {
    $('#errorCt').text('')
    $('#inputCt').removeClass(`${styles.error}`)
    $('#errorUc').text('')
    $('#inputUc').removeClass(`${styles.error}`)
  }

  const validationsInputs = () => {
    let response = true

    const messageRequire = 'No contiene datos válidos. Por favor, verifique la información ingresada.'
    const ct = document.getElementById('inputCt')
    const uc = document.getElementById('inputUc')

    if (ct?.value === '') {
      $('#inputCt').addClass(`${styles.error}`)
      $('#errorCt').text(messageRequire)
      response = false
    }
    if (uc?.value === '') {
      $('#inputUc').addClass(`${styles.error}`)
      $('#errorUc').text(messageRequire)
      response = false
    }
    if (response) clearErrorMessages()
    return response
  }
  //* llamados de la API cuco
  const calculateUnlockCode = async (id, code, url) => {
    $('#btnSucessModalRSUnlockCode').prop('disabled', true)
    const codeUnlock = document.getElementById('codeUnlock')
    setLoading(true)
    codeUnlock.style.display = 'none'
    const ticketCodeUnlock = document.getElementById('ticketCodeUnlock')
    const validation = validationsInputs()
    if (validation) {
      const body = JSON.stringify({ id_rxart: id, ct: code?.ct.replace(/ /g, ''), uc: code?.uc.replace(/ /g, '') })
      const response = await postData(url, body)
      if (response.status === 200) {
        // console.log(response.data.unlock_code)
        ticketCodeUnlock.innerText = response.data.unlock_code
        codeUnlock.style.display = 'block'
      } else {
        console.log({ error: true, code: response.code })
      }
    }
    setLoading(false)
    $('#btnSucessModalRSUnlockCode').prop('disabled', false)
  }

  const handleInputChange = (e) => {
    const input = e.target.name

    if (input === 'noChangeDevice' || input === 'lockDevice' || input === 'unlockDevice' || input === 'enabledDevice') {
    //   console.log(e.target.value)
      setActions({
        ...actions,
        statusid: e.target.value
      })
    } else {
      setActions({
        ...actions,
        [e.target.name]: e.target.value
      })
    }
  }

  // ?Va seteando el estado para reenderizar los modales
  const handleModal = (type, boolean) => {
    setModalsShow({ ...modalsShow, [type]: boolean })
  }

  // ?Función de cerrar modal
  const closeModalRSUnlockCode = () => {
    const codeUnlock = document.getElementById('codeUnlock')
    const ticketCodeUnlock = document.getElementById('ticketCodeUnlock')
    handleModal('newTicket', false)
    setUnlockCode({
      ...unlockCode,
      ct: '',
      uc: ''
    })
    codeUnlock.style.display = 'none'
    ticketCodeUnlock.innerText = ''
  }

  return (
        <div id="actionsRS" className={styles.actionsContainer}>
            <div className={styles.buttonsContainer}>
                <Button className={styles.buttonsRS} size="small" type="button" label="Historial de estado" severity="secondary" tooltip="Ver historial de estados" tooltipOptions={{ position: 'bottom' }} onClick={() => handleModal('statusHistory', true)}/>
                <Button className={styles.buttonsRS} size="small" type="button" label="Tickets emitidos" severity="secondary" tooltip="Ver tickets emitidos" tooltipOptions={{ position: 'bottom' }} onClick={() => handleModal('ticketsIssued', true)}/>
                <Button className={styles.buttonsRS} size="small" type="button" label="Código de desbloqueo" severity="secondary" tooltip="Calcular código de desbloqueo" tooltipOptions={{ position: 'bottom' }} onClick={() => handleModal('newTicket', true)} />
                <Button className={styles.buttonsRS} size="small" type="button" label="Nuevo ticket" severity="secondary" tooltip="Generar nuevo ticket" tooltipOptions={{ position: 'bottom' }}/>
            </div>
            <div>
                <p className={styles.title}>Cambio de estado al escribir:</p>
                <div className={styles.deviceStatusOptionsContainer}>
                    {deviceStatusOptions?.map((option) => {
                      if (device.statusid === option.value) { return (<></>) } else {
                        return (<div key={option.name} className={styles.inputContainer}>
                            <input type='radio' id={option.name} name={option.name} value={option.value} onChange={handleInputChange} checked={ option.value === actions.statusid} />
                            <label htmlFor={option.name} className={styles.labelDeviceStatusOptions}>{option.label}</label>
                        </div>)
                      }
                    })}
                </div>
                <div className={styles.statusChangeInformationContainer}>
                    <label>Razón/información del cambio de estado</label>
                    <textarea name="info" onChange={handleInputChange} className={styles.statusChangeInformation} value={actions.info} />
                </div>
            </div>

            {/* //*MODAL DE CODIGO DE DESBLOQUEO */}
          <ModalRSUnlockCode
            show={modalsShow.newTicket}
            onHide={() => handleModal('newTicket', false)}
            title="Calcular código"
            btnError="Cerrar"
            btnSuccess="Calcular"
            code={unlockCode}
            setCode={setUnlockCode}
            onClick={() => calculateUnlockCode(data?.id_rxart, unlockCode, rxartSecureTicketUnlockUrl)}
            closeModal={() => closeModalRSUnlockCode()}
            loading={loading}
          />
          {/* //*MODAL DE HISTORIAL DE ESTADO DEL DISPOSITIVO */}
          <ModalRSStatusHistory
            show={modalsShow.statusHistory}
            onHide={() => handleModal('statusHistory', false)}
            title="Historial de estado del dispositivo"
            btnError="Cerrar"
          />

          {/* //*MODAL DE TICKETS EMITIDOS */}
          <ModalRSTicketsIssued
            show={modalsShow.ticketsIssued}
            onHide={() => handleModal('ticketsIssued', false)}
            title="Tickets emitidos"
            btnError="Cerrar"
          />
        </div>
  )
}

ActionsRS.propTypes = {
  actions: PropTypes.object,
  setActions: PropTypes.func,
  data: PropTypes.object,
  device: PropTypes.object
}

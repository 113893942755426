export function preferencesUseTimeJson () {
  const items = {
    restriction: { // restricción por días y horarios
      name: 'restriction',
      label: 'activar restricción por días y horarios del tiempo de uso'
    },
    editRestrictionSettings: { // días, hora de inicio, hora de finalización
      name: 'editRestrictionSettings',
      label: 'editar días y horarios del tiempo de uso'
    }
  }

  return (items)
}

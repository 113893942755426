import ModalGeneric from '../modal'
import DataTableDemo from '../../generals/datatables/datatable'
import PropTypes from 'prop-types'
import { getData } from '../../../actions/servicesHttp'
import React, { useState, useEffect } from 'react'

export default function ModalDebugLog ({ onHide, show, devices, component }) {
  const [data, setData] = useState([])
  const deviceId = devices[0]

  useEffect(() => {
    if (show && component === 'errorLogs') {
      const fetchLogs = async () => {
        try {
          const { status, data } = await getData('device/debug-log', deviceId)
          if (status === 200) {
            setData(data)
          } else {
            setData('No existen datos para mostrar.')
          }
        } catch (error) {
          setData('No existen datos para mostrar.')
        }
      }

      fetchLogs()
    }
  }, [show, component, deviceId])

  const newData = []
  for (const item of data) {
    const maxArray = Math.max(
      item?.level?.length,
      item?.date?.length,
      item?.method?.length,
      item?.line?.length,
      item?.message?.length
    )

    item.level.length = maxArray
    item.date.length = maxArray
    item.method.length = maxArray
    item.line.length = maxArray
    item.message.length = maxArray

    const keys = Array.from({ length: maxArray }).map((_, index) => ({
      level: item.level[index] || 'Sin datos',
      date: item.date[index] ? new Date(item.date[index]).toLocaleString('es-AR') : 'Sin fecha para mostrar',
      method: item.method[index] || 'Sin datos',
      line: item.line[index] || 'Sin datos',
      message: item.message[index] || 'Sin datos'
    }))

    newData.push(...keys)
  }

  return (
    <>
      <ModalGeneric
        show={show}
        onHide={onHide}
        size="xl"
        id="cases"
        onClose={onHide}
        title="Log de Errores"
        btnError="Cerrar"
        btnSuccess="Agregar"
        viewBtnSuccess={false}
      >
        <DataTableDemo
          data={newData}
          table="errorLogs"
          rowSelecteds={false}
          rowStates={false}
          exportCsv={false}
          exportPdf={false}
          actionDashboard={false}
          actionDelete={false}
          viewBtnAction={false}
          navLink={true}
          searchs={['message', 'level']}
          editUsers={false}
          columns={[
            {
              field: 'level',
              header: 'Tipo de Log'
            },
            {
              field: 'line',
              header: 'Línea'
            },
            {
              field: 'date',
              header: 'Fecha'
            },
            {
              field: 'method',
              header: 'Método'
            },
            {
              field: 'message',
              header: 'Mensaje'
            }
          ]}
        />
      </ModalGeneric>
    </>
  )
}

ModalDebugLog.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  devices: PropTypes.array,
  component: PropTypes.string
}

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../css/Roles/OptionsSectionsTree.module.css'
import { MultiSelectDataTable } from '../../../generals/MultiSelectDataTable'

export default function InputsTree ({ inputs, role, setRole, onChange, component, label }) {
  return (
        <div id={`${component}Inputs`}>
            { inputs?.length === 0
              ? (<div></div>)
              : (inputs?.map((element) => {
                  if (element.name === 'columDataTableDevices') {
                    return (
                <div key={element.name} className={styles.inputContainer}>
                  <div className={styles.divButton}>
                    <label className={styles.labelCheckBox}>{element.label}</label>
                    <MultiSelectDataTable option={element.option} label={element.keyJson} value={element.value} role={role} optionLabel={'header'} setRole={setRole} />
                   </div>
                </div>)
                  } else {
                    return (
                      <div key={element.name} className={styles.inputContainer}>
                          <input type="checkbox" id={element.name} name={element.name} value={role[element.keyJson][element.name]} onChange={onChange} className={`${styles.checkBox} ${label}`} checked={role[element.keyJson][element.name]}/>
                          <label className={styles.labelCheckBox}>{element.label}</label>
                      </div>)
                  }
                })
                )
            }

        </div>
  )
}

InputsTree.propTypes = {
  inputs: PropTypes.array,
  role: PropTypes.object,
  setRole: PropTypes.func,
  component: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
}

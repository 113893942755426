import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet'
import { config } from '../../config'
import styles from '../../css/devices/LocationHistory.module.css'
import 'leaflet/dist/leaflet.css'
import iconMap from '../../components/generals/iconMap'
import MapClickLocation from '../Geofences/mapClickLocation'
import ChangeView from '../Geofences/ChangeView'
import PropTypes from 'prop-types'

export default function MapaGeofences ({ info, type, setGeofenceData, unitArea }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    setData(info)
  }, [info])

  if (data) {
    return (
      <MapContainer
        className="divMaps"
        center={config.map.center}
        zoom={config.map.zoom_specific}
        scrollWheelZoom={true}
      >
        <MapClickLocation setGeofenceData={setGeofenceData} info={info} type={type}/>
        {info.place && (
          <ChangeView
            center={[info.lat, info.lon]}
          />
        )}

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data && data.place
          ? (
          <Marker key={data.id} position={[data.lat, data.lon]} icon={iconMap}>
            <Popup>
              <div className={styles.popup}>
                <span>
                  Ubicación:
                  {data.place}
                </span>
              </div>
            </Popup>
          </Marker>
            )
          : (
          <Marker key={data.id} position={[data.lat, data.lon]} icon={iconMap}></Marker>
            )}
        {data && data.area
          ? (
            <Circle
              center={[data.lat, data.lon]}
              radius={unitArea && unitArea === 'km' ? data.area * 1000 : data.area}
              pathOptions={{ color: data.color_range }}
            />
            )
          : (
            <></>
            )}
      </MapContainer>
    )
  } else {
    return (
      <MapContainer
        className="divMaps"
        center={config.map.center}
        zoom={config.map.zoom_general}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    )
  }
}

MapaGeofences.propTypes = {
  type: PropTypes.string,
  unitArea: PropTypes.string,
  setGeofenceData: PropTypes.func,
  info: PropTypes.object
}

import React, { useState, useEffect } from 'react'
import { getData, postData, putData } from '../../../actions/servicesHttp'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import SelectionTree from '../../generals/tree'
import styles from '../../../css/modals/ModalRoles.module.css'
import stylesOptions from '../../../css/Roles/OptionsSectionsTree.module.css'
import { optionsTreeJson } from './JSON/treeJson'
import OptionsSectionsTree from './components/OptionsSectionsTree'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { config } from '../../../config'
import { selectedAllCheckBox, checkBoxs, getJson } from './utils/utils'

export default function ModalRoles ({ title, show, btnError, btnSuccess, closeModal, reload, data }) {
  // ? url de la API
  const [roleUrl] = useState('roles')

  //* estados de componentes
  const [selectedKey, setSelectedKey] = useState('0')
  const [component, setComponent] = useState('all')
  const [role, setRole] = useState()
  const [checkSelectAll, setCheckSelectAll] = useState(false)
  const id = data?.id
  const [checks, setChecks] = useState([])

  const onChangeCheckBoxs = (role, array, set, setCheckBoxSelectAll) => {
    const labelsChecks = checkBoxs()
    const numberOfLabels = labelsChecks.length

    let count = 0
    let countJson = 0
    let validation
    let selectAllValidation
    let arrayChecks

    labelsChecks.forEach(element => {
      const section = role[element + 'Page']
      const json = getJson(element)

      if (json) {
        countJson = Object.keys(json).length
        for (const clave in section) {
          if (section[clave] === true) count += 1
        }

        if (element === 'devices') {
          countJson -= 1
        } else if (element === 'dashboardGroup' && title === 'Editar rol') {
          count -= 1
        } else if (element === 'dashboardDevice' && title === 'Editar rol') {
          count -= 1
        }
        validation = count === countJson

        selectAllValidation = array?.length === numberOfLabels

        if (validation) {
          if (!array?.includes(element)) {
            arrayChecks = array
            arrayChecks.push(element)
            set(arrayChecks)
          }
        } else {
          if (array?.includes(element)) {
            arrayChecks = array
            arrayChecks = arrayChecks.filter(item => item !== element)
            set(arrayChecks)
          }
        }

        if (selectAllValidation) {
          setCheckBoxSelectAll(true)
        } else {
          setCheckBoxSelectAll(false)
        }

        count = 0
      }
    })
  }

  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value)
    if (e.target.name === 'roleName') {
      setRole({
        ...role,
        [e.target.name]: e.target.value
      })
    }
  }

  //* función para mostrar el popup
  const showPopup = (iconPopup, titlePopup) => {
    Swal.fire({
      position: 'center',
      icon: iconPopup,
      title: titlePopup,
      showConfirmButton: false,
      timer: 2500
    })
  }

  const clearNameRoleInput = () => {
    setRole({
      ...role,
      roleName: ''
    })
  }

  const jsonValidation = (data) => {
    const json = data
    const keys = Object.keys(json)
    validateSpeedDialButton(json)
    keys.forEach(key => {
      if (key === 'roleName') {
        const name = json[key].trim()
        json[key] = name
      } else if (key === 'version') {
        const version = json[key]
        json[key] = version
      } else if (key === 'id') {
        const id = json[key]
        json[key] = id
      } else {
        const subKeys = Object.keys(json[key])
        subKeys.forEach(subKey => {
          let keyObject = json[key]
          const array = Object.entries(keyObject)
          const filter = array.filter(([key, value]) => value === true)
          keyObject = Object.fromEntries(filter)
          json[key] = keyObject
        })
      }
    })

    return json
  }

  // //* roles que se cambian en caso de hacer funciones especificas
  const validateSpeedDialButton = (json) => {
    if (json.dashboardDevicePage) {
      const dashboardDevice = Object.keys(json?.dashboardDevicePage)
      dashboardDevice.forEach((key) => {
        if (key.includes('action') && json?.dashboardDevicePage[key] === true) {
          json.dashboardDevicePage.buttonSpeedDial = true
        }
      })
    }

    if (json.dashboardGroupPage) {
      const dashboardGroup = Object.keys(json?.dashboardGroupPage)
      dashboardGroup.forEach((key) => {
        if (key.includes('action') && json?.dashboardGroupPage[key] === true) {
          json.dashboardGroupPage.buttonSpeedDial = true
        }
      })
    }
  }

  const requestRole = async (url, type) => {
    $('#btnSucessModalRoles').prop('disabled', true)
    let validation = true

    const columDataTableDevices = role.devicesPage.columDataTableDevices
    const dataRole = jsonValidation(role)
    if (columDataTableDevices !== undefined) {
      const devicesPage = dataRole.devicesPage
      devicesPage.columDataTableDevices = columDataTableDevices
      dataRole.devicesPage = devicesPage
    }

    if (dataRole?.roleName === '') {
      showPopup('error', 'No se puede crear roles sin nombre!')
      validation = false
      $('#btnSucessModalRoles').prop('disabled', false)
    } else {
      const { data: existingRoles } = await getData(url, undefined)
      //   console.log(existingRoles)
      if (type === 'POST' && existingRoles?.some((roles) => roles.roleName === dataRole.roleName?.trim())) {
        showPopup('error', 'El role ya existe!')
        clearNameRoleInput()
        validation = false
        $('#btnSucessModalRoles').prop('disabled', false)
      }
    }

    if (validation) {
      dataRole.id = id
      const body = JSON.stringify(dataRole)
      const response = type === 'POST' ? await postData(url, body) : await putData(url, body)

      if (response.status === 200 && response.data) {
        // console.log(response.data)
        showPopup('success', 'Role creado con éxito!')
      } else if (response.status === 201 && response.data) {
        showPopup('success', 'Role actualizado con éxito!')
      } else {
        console.log({ error: true, code: response.code })
        showPopup('error', 'Upps... Algo salió mal!')
      }
      reload()
      $('#btnSucessModalRoles').prop('disabled', false)
      closeModalRoles()
    }
  }

  const closeModalRoles = (title) => {
    setSelectedKey('0')
    setComponent('all')
    setRole(undefined)
    setChecks([])
    setCheckSelectAll(false)
    closeModal()
  }

  useEffect(() => {
    if (selectedKey) {
      let view
      view = optionsTreeJson()
      view = view.filter((option) => option.key === selectedKey)
      view = view[0]?.json
      setComponent(view)
    }
  }, [selectedKey])

  useEffect(() => {
    if (show) {
      if (title === 'Crear rol') {
        setRole(config.defaultRole)
      }
      if (title === 'Editar rol') {
        setRole(data)
        onChangeCheckBoxs(data, checks, setChecks, setCheckSelectAll)
      }
    }
  }, [show])

  useEffect(() => {
    if (role) {
      onChangeCheckBoxs(role, checks, setChecks, setCheckSelectAll)
    }
  }, [role])

  if (role !== undefined) {
    return (
      <Modal
      show={show}
                size="xl"
                onHide={closeModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '1.5rem' }}>
                    <div className={styles.modalRolesContainer}>
                      <SelectionTree selection={selectedKey} setSelectecTree={setSelectedKey} />
                      <div className={styles.functionalitiesContainer}>
                          <div className={styles.inputContainer}>
                              <label>Nombre del rol</label>
                              <input type="text" autoComplete="off" name="roleName" onChange={(e) => { handleInputChange(e) }} value={role?.roleName}/>
                              {component === 'all' && <div className={stylesOptions.containerTitleSection}>
                                <input type='checkbox' name={'checkBox_all'} id={'checkBox_all'} className={stylesOptions.checkBox} checked={checkSelectAll} onClick={(e) => {
                                  setCheckSelectAll(e.target.checked)
                                  selectedAllCheckBox(e.target.checked, setRole, role, title)
                                  if (e.target.checked === true) setChecks(checkBoxs())
                                  if (e.target.checked === false) setChecks([])
                                }} />
                                <p className={stylesOptions.title} style={{ textDecoration: 'none' }}>Seleccionar Todo</p>
                              </div>}
                          </div>
                          {component !== undefined ? (<OptionsSectionsTree show={show} component={component} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} title={title} />) : <></>}
                      </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => closeModalRoles(title)} variant="danger">
                        {btnError}
                    </Button>
                    <Button onClick={() => requestRole(roleUrl, title === 'Editar rol' ? 'PUT' : 'POST')} variant="primary" id="btnSucessModalRoles" >
                        {btnSuccess}
                    </Button>
                </Modal.Footer>
            </Modal>
    )
  }
}

ModalRoles.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string,
  closeModal: PropTypes.func,
  reload: PropTypes.func,
  data: PropTypes.object
}

import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'
import '../../../css/generals/dataTable.css'

export default function NavLinkButton ({ url, title }) {
  return (
        <NavLink
          to={`/mdm/${url}`}
          className="nav-link hoverly"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }}
        >
            <Button className="p-button-secondary">{title}</Button>
        </NavLink>
  )
}

NavLinkButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
}

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import styles from '../../css/modals/Modal.module.css'
import styleschecks from '../../css/generals/Checkbox.module.css'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
export default function ModalGeneric ({ show, onHide, size, title, wsState, btnSuccess, action, onChange, id, onClose, actionSuccess, configDefault, viewBtnSuccess, viewBtnClose, objects, btnError, key, destiny, children, table }) {
  function handleOnSubmit (e) {
    e.preventDefault()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={id}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={styles.modalBody}
          style={{
            display:
              configDefault === true || action === 'desbloquear'
                ? 'none'
                : 'block',
            marginTop: table === 'sendCommands' && '0'
          }}
        >
          {/* cargar modal */}
          {table === 'sendCommands' && '0'
            ? (<h5>Websocket: {wsState}</h5>)
            : null
          }
          {children !== undefined
            ? (
                children
              )
            : action === 'desbloquear'
              ? (
            <></>
                )
              : (
            <form onSubmit={handleOnSubmit}>
              {objects &&
                objects.map((el) =>
                  el.type === 'checkbox'
                    ? (
                    <div
                      key={el.key}
                      className={styleschecks.modalBodyCommand}
                      style={{
                        justifyContent: 'space-between',
                        display: el.display === false ? 'none' : 'flex'
                      }}
                    >
                      <label className={styleschecks.sliderLabel}>
                        {el.label}
                      </label>
                      <label className={styleschecks.switch}>
                        <input
                          key={key}
                          id={el.id}
                          value={el.value}
                          checked={el.value}
                          name={el.name}
                          onChange={onChange}
                          type="checkbox"
                          className="focus"
                        />
                        <span
                          className={`${styleschecks.slider} ${styleschecks.round} ${styleschecks.inputsLabels}`}
                        />
                      </label>
                    </div>
                      )
                    : el.type === 'password'
                      ? (
                    <div
                      key={el.key}
                      className={styles.spacePasswords}
                      style={{
                        display: el.display === false ? 'none' : 'block'
                      }}
                    >
                      <label className="label-users">{el.label}: </label>
                      <input
                        key={key}
                        placeholder={el.placeholder}
                        id={el.id}
                        value={el.value}
                        onChange={onChange}
                        type="password"
                        className="inputs-users focus"
                      />
                    </div>
                        )
                      : el.type === 'options'
                        ? (
                    <div
                      className={
                        el.idMess === 'sendMessagesDestiny'
                          ? styleschecks.containerDivMessage
                          : styleschecks.containerDiv
                      }
                      style={{
                        display: el.display === false ? 'none' : 'flex'
                      }}
                    >
                      <label className={styleschecks.sliderLabel}>
                        {el.label}
                      </label>
                      <select
                        id={el.id}
                        className={styleschecks.focus}
                        onChange={onChange}
                        /* className="form-control" */
                        style={{ width: '100%' }}
                        name="resolution_images"
                        defaultValue={
                          title === 'Enviar Mensaje' ||
                          title === 'Enviar Mensaje al Grupo'
                            ? destiny
                            : 'default'
                        }
                      >
                        {title === 'Enviar Mensaje' ||
                        title === 'Enviar Mensaje al Grupo'
                          ? null
                          : (
                          <option value="default" disabled>
                            {el.default}
                          </option>
                            )}

                        {el.options &&
                          el.options.map((option, key) => (
                            <option key={key} value={option.value}>
                              {option.title}
                            </option>
                          ))}
                      </select>
                    </div>
                          )
                        : el.type === 'area'
                          ? (
                    <div
                      className={
                        el.id === 'sendMessagesMessage' ||
                        el.id === 'sendMessagesMessageDash'
                          ? styleschecks.containerDivMessage
                          : styleschecks.containerDiv
                      }
                      style={{
                        display: el.display === false ? 'none' : 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                      }}
                    >
                      <div style={{ width: 'auto' }}>
                        <label className={styleschecks.sliderLabel}>
                          {el.label}
                        </label>
                        <small className="text-muted">{el.small}</small>
                      </div>

                      <div style={{ width: '100%' }}>
                        <textarea
                          key={el.key}
                          style={{
                            height: '7rem',
                            resize: 'none'
                          }}
                          placeholder={el.placeholder}
                          id={el.id}
                          name={el.name}
                          value={el.value}
                          onChange={onChange}
                          type={el.type}
                          readOnly={el?.readOnly}
                          className={
                            (styles.inputModal,
                            styles.inputUsers,
                            styleschecks.focus)
                          }
                        />
                      </div>
                    </div>
                            )
                          : el.type === 'range'
                            ? (
                    <div
                      className="containerDiv"
                      style={{
                        display: el.display === false ? 'none' : 'block'
                      }}
                    >
                      <label className="slider-label">{el.label}</label>
                      <input
                        key={el.key}
                        id={el.id}
                        value={el.value}
                        name={el.name}
                        onChange={onChange}
                        type={el.type}
                        min="0"
                        max="100"
                      />
                      <small className="text-muted">{`${el.value}%`}</small>
                    </div>
                              )
                            : (
                    <div
                      className={
                        el.id === 'sendMessagesTitle' ||
                        el.id === 'sendMessagesTitleDash'
                          ? styleschecks.containerDivMessage
                          : styleschecks.containerDiv
                      }
                      id={`div${el.id}`}
                      style={{
                        justifyContent:
                          el.type === 'number' ? 'space-between' : 'center',
                        margin: '0px 0px 15px 0px',
                        display: el.display === false ? 'none' : 'flex'
                      }}
                    >
                      <div>
                        <label className={styleschecks.sliderLabel}>
                          {el.label}
                        </label>
                        <small className="text-muted">{el.small}</small>
                      </div>
                      <div
                        style={{
                          width: el.type === 'number' ? 'fit-content' : '100%',
                          justifyContent: 'flex-end',
                          display: 'flex'
                        }}
                      >
                        <input
                          key={el.key}
                          placeholder={el.placeholder}
                          id={el.id}
                          name={el.name}
                          value={el.value}
                          onChange={onChange}
                          type={el.type}
                          style={{
                            width: el.type === 'number' ? '6rem' : '100%'
                          }}
                          className={styleschecks.focus}
                        />
                      </div>
                    </div>
                              )
                )}
            </form>
                )}
        </Modal.Body>

        <Modal.Footer>
          {viewBtnClose === false
            ? null
            : (
            <Button onClick={onClose} variant="danger">
              {btnError}
            </Button>
              )}
          {viewBtnSuccess === false
            ? null
            : (
            <Button
              onClick={actionSuccess}
              variant="primary"
              id="btnModalSucess"
            >
              {btnSuccess}
            </Button>
              )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

ModalGeneric.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string,
  wsState: PropTypes.string,
  btnSuccess: PropTypes.string,
  action: PropTypes.string,
  onChange: PropTypes.func,
  key: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func,
  actionSuccess: PropTypes.func,
  viewBtnSuccess: PropTypes.bool,
  configDefault: PropTypes.bool,
  viewBtnClose: PropTypes.bool,
  objects: PropTypes.array,
  btnError: PropTypes.string,
  destiny: PropTypes.string
}

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../css/Roles/OptionsSectionsTree.module.css'
import ContainerInputsTree from './ContainerInputsTree'
import { getJson, checkBoxs } from '../utils/utils'

export default function OptionsSectionsTree ({ checks, setChecks, show, role, setRole, component, setCheckSelectAll, title }) {
  const handleInputChange = (e, label1, array, set, setCheckBoxSelectAll, title) => {
    // console.log(e.target.name, e.target.checked, array)
    const label2 = label1.slice(0, -4)
    const value = e.target.checked
    const section = role[label1]
    const json = getJson(label2)
    const labelsChecks = checkBoxs()
    const numberOfLabels = labelsChecks.length
    let count = 0
    let arrayChecks
    let validation
    let selectAllValidation

    setRole({
      ...role,
      [label1]: {
        ...section,
        [e.target.name]: value
      }
    })

    if (json) {
      section[e.target.name] = value
      let countJson = Object.keys(json).length
      for (const clave in section) {
        if (section[clave] === true) count += 1
      }

      if (label2 === 'devices') {
        countJson -= 1
      }
      if (label2 === 'dashboardGroup' && title === 'Editar rol') {
        count -= 1
      }
      if (label2 === 'dashboardDevice' && title === 'Editar rol') {
        count -= 1
      }
      validation = count === countJson

      if (validation) {
        if (!array?.includes(label2)) {
          arrayChecks = array
          arrayChecks.push(label2)
          set(arrayChecks)
        }
      } else {
        if (array?.includes(label2)) {
          arrayChecks = array
          arrayChecks = arrayChecks.filter(element => element !== label2)
          set(arrayChecks)
        }
      }
      selectAllValidation = arrayChecks?.length === numberOfLabels

      if (selectAllValidation) {
        setCheckBoxSelectAll(true)
      } else {
        setCheckBoxSelectAll(false)
      }
    }
  }

  return (
    <div className={styles.inputsTreeContainer}>
      {(component === 'home' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='home' title='Inicio' onChange={(e) => handleInputChange(e, 'homePage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'devices' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='devices' title='Equipos' onChange={(e) => handleInputChange(e, 'devicesPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'dashboardDevice' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='dashboardDevice' title='Dashboard de Equipos' onChange={(e) => handleInputChange(e, 'dashboardDevicePage', checks, setChecks, setCheckSelectAll, title)} edit={title === 'Editar rol'} />}
      {(component === 'groups' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='groups' title='Grupos' onChange={(e) => handleInputChange(e, 'groupsPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'dashboardGroup' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='dashboardGroup' title='Dashboard de Grupos' onChange={(e) => handleInputChange(e, 'dashboardGroupPage', checks, setChecks, setCheckSelectAll, title)} edit={title === 'Editar rol'}/>}
      {(component === 'users' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='users' title='Usuarios' onChange={(e) => handleInputChange(e, 'usersPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'roles' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='roles' title='Roles' onChange={(e) => handleInputChange(e, 'rolesPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'geofences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='geofences' title='Geocercas' onChange={(e) => handleInputChange(e, 'geofencesPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'applications' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='applications' title='Aplicaciones' onChange={(e) => handleInputChange(e, 'applicationsPage', checks, setChecks, setCheckSelectAll, title)}/>}
      {(component === 'preferencesSettings' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesSettings' title='Preferencias de Configuraciones Generales' onChange={(e) => handleInputChange(e, 'preferencesSettingsPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'preferencesGeofence' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesGeofence' title='Preferencias de Geocercas' onChange={(e) => handleInputChange(e, 'preferencesGeofencePage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'preferencesReports' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesReports' title='Preferencias de Reportes' onChange={(e) => handleInputChange(e, 'preferencesReportsPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'preferencesRxartSecure' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesRxartSecure' title='Preferencias de Rxart Secure' onChange={(e) => handleInputChange(e, 'preferencesRxartSecurePage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'preferencesUseTime' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesUseTime' title='Preferencias de Tiempo de Uso' onChange={(e) => handleInputChange(e, 'preferencesUseTimePage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'preferencesLosses' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesLosses' title='Preferencias de Extravios' onChange={(e) => handleInputChange(e, 'preferencesLossesPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'preferencesServer' || component === 'preferences' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='preferencesServer' title='Preferencias del Servidor' onChange={(e) => handleInputChange(e, 'preferencesServerPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'safeWebHome' || component === 'safeWeb' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='safeWebHome' title='Inicio de SafeWeb' onChange={(e) => handleInputChange(e, 'safeWebHomePage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'safeWebLists' || component === 'safeWeb' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='safeWebLists' title='Listas de SafeWeb' onChange={(e) => handleInputChange(e, 'safeWebListsPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'safeWebWords' || component === 'safeWeb' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='safeWebWords' title='Palabras Claves de SafeWeb' onChange={(e) => handleInputChange(e, 'safeWebWordsPage', checks, setChecks, setCheckSelectAll, title)} />}
      {(component === 'safeWebPreferences' || component === 'safeWeb' || component === 'all') && <ContainerInputsTree show={show} role={role} setRole={setRole} checks={checks} setChecks={setChecks} setCheckSelectAll={setCheckSelectAll} component={component} label='safeWebPreferences' title='Preferencias de SafeWeb' onChange={(e) => handleInputChange(e, 'safeWebPreferencesPage', checks, setChecks, setCheckSelectAll, title)} />}
    </div>
  )
}

OptionsSectionsTree.propTypes = {
  checks: PropTypes.array,
  role: PropTypes.object,
  show: PropTypes.bool,
  setRole: PropTypes.func,
  component: PropTypes.string,
  setChecks: PropTypes.func,
  setCheckSelectAll: PropTypes.func,
  title: PropTypes.string
}

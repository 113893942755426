export default function formatUseTimeApps (data, dateFilter, unit) {
  // console.log(data, dateFilter)
  let labelsArray = []
  const datesArray = []

  for (let i = 0; i < dateFilter - 1; i++) {
    // console.log(i, dateFilter)
    let day = formatDays(i)
    day = labels(day)
    labelsArray.push(day)
    datesArray.push(0)
  }

  labelsArray = labelsArray.reverse()

  data.forEach(element => {
    let day = new Date(element.date).toISOString().split('T')[0]
    day = labels(day)

    const isEqual = (element) => element === day
    const index = labelsArray.findIndex(isEqual)
    datesArray[index] += element.totalTimeSeconds
  })

  // console.log(labelsArray, datesArray)

  return {
    data: datesArray.map(time => time / unit),
    labels: labelsArray
  }
}

export const formatDays = (rest) => {
  const today = new Date()
  let endDate = today.setUTCDate(today.getUTCDate() - rest)
  // console.log(endDate)
  endDate = new Date(endDate)?.toISOString().split('T')[0]
  // endDate = new Date(endDate).toLocaleDateString()
  return endDate
}

export const labels = (date) => {
  const label = date.split('-').reverse().join('-')
  return label
}

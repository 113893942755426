export function preferencesServerJson () {
  const items = {
    restoreDB: {
      name: 'restoreDB',
      label: 'restauración de la base de datos'
    },
    editMdmPreferences: {
      name: 'editMdmPreferences',
      label: 'editar las preferencias del MDM del servidor'
    },
    editSafeWebPreferences: {
      name: 'editSafeWebPreferences',
      label: 'editar las preferencias de Safeweb del servidor'
    },
    editRxartSecurePreferences: {
      name: 'editRxartSecurePreferences',
      label: 'editar las preferencias de Rxart Secure del servidor'
    }
  }

  return (items)
}

export function safeWebPreferencesJson () {
  const items = {
    history: { // guardado del historial
      name: 'history',
      label: 'guardar el historial de navegación'
    },
    defaultResponse: { // respuesta por defecto
      name: 'defaultResponse',
      label: 'activar respuesta por defecto'
    },
    editSafeWeb: { // Lista negra, lista blanca, palabras claves, activar safeweb
      name: 'editSafeWeb',
      label: 'edición y activación de la navegación'
    }
    // columnsDataTableWords: [],
  }

  return (items)
}

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import { deleteData } from '../../actions/servicesHttp'
import { popupSimple } from '../generals/popups'

export default function ModalDeleteGroupPreference ({ onHide, title, show, btnError, btnSuccess, group }) {
  // ? urls de la API
  const [groupPreferenceUrl] = useState('preferences/group')

  const deleteGroupPreference = async (url, id) => {
    const { data } = await deleteData(url, id)

    if (data.result) {
      popupSimple('success', 'Eliminación éxitosa!')
    } else {
      popupSimple('error', 'Eliminación fallida!')
    }

    onHide()
  }

  return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                key={'deleteGroupPreference'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Eliminara las preferencia personalizadas del grupo y conservará las preferencias generales.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} variant="danger">
                        {btnError}
                    </Button>
                    <Button onClick={() => deleteGroupPreference(groupPreferenceUrl, group[0])} variant="primary" id="btnModalSucess" >
                        {btnSuccess}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  )
}

ModalDeleteGroupPreference.propTypes = {
  onHide: PropTypes.func,
  title: PropTypes.string,
  show: PropTypes.bool,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string,
  group: PropTypes.number
}

export function applicationsJson () {
  const items = {
    // columnsDataTable: [],
    editCategoryApplication: {
      name: 'editCategoryApplication',
      label: 'categorizar aplicaciones'
    },
    exportPdfApplications: {
      name: 'exportPdfApplications',
      label: 'exportar aplicaciones en formato pdf'
    },
    exportCsvApplications: {
      name: 'exportCsvApplications',
      label: 'exportar aplicaciones en formato csv'
    }
  }

  return (items)
}

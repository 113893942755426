import React, { useState, useEffect } from 'react'
import DataTableDemo from '../../components/generals/datatables/datatable'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import ModalGeneric from '../../components/modals/modal'
import { getData, postData } from '../../actions/servicesHttp'
import store from '../../reducer/store'
import $ from 'jquery'
import Header from '../../components/generals/header'
import styles from '../../css/devices/Devices.module.css'
import stylesIndex from './../../css/Index.module.css'

export default function Groups () {
  const { user } = store.getState()
  const [grupos, setGrupos] = useState(null)
  const [modal, setModal] = useState(false)
  const [group, setNewGroup] = useState({
    user_id: parseInt(user.userId),
    visible: true,
    name: '',
    users: [],
    devices: []
  })
  const role = user.roles

  useEffect(() => {
    getGroups()
  }, [])

  const getGroups = async () => {
    const response = await getData('groups')
    setGrupos(response.data)
  }

  function handleOnChange (e) {
    setNewGroup({
      ...group,
      name: e.target.value
    })
  }

  function closeModal () {
    setModal(false)
    setNewGroup({
      ...group,
      name: ''
    })
  }

  function deleted () {
    setNewGroup({
      ...group,
      name: ''
    })
    getGroups()
  }

  const createGroup = async () => {
    $('#btnModalSucess').prop('disabled', true)

    if (group.name.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No se puede crear grupos sin nombre!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
      return
    }

    const { data: existingGroups } = await getData('groups', undefined)

    if (existingGroups.some((groups) => groups.name === group.name.trim())) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'El grupo ya existe!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
      deleted()
      return
    }

    const { data } = await postData('groups', group)

    if (data?.result === true) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Grupo creado con éxito!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
      getGroups()
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error al crear el grupo!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    }

    setModal(false)
    setNewGroup({
      ...group,
      name: ''
    })
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Grupos" />
        <div
          className={`container-toggle ${styles.btns}`}
          style={{ display: user.range === 1 ? 'flex' : 'none' }}
        >
          {role?.groupsPage.newGroup && <Button onClick={() => setModal(true)} variant="dark">
            <i className="fas fa-users" style={{ marginRight: '0.5rem' }}></i>
            Nuevo grupo
          </Button>}
        </div>

        <DataTableDemo
          data={
            grupos && grupos !== null
              ? grupos.filter((group) => group.visible !== false)
              : []
          }
          reload={deleted}
          editUsers={false}
          rowStates={false}
          rowSelecteds={false}
          exportsPdf={role?.groupsPage.exportPdfGroups}
          exportCsv={role?.groupsPage.exportCsvGroups}
          actionDelete={role?.groupsPage.removeGroups}
          actionDashboard={role?.groupsPage.seeDashboardGroup}
          table="groups"
          searchs={['name']}
          columns={[
            {
              field: 'name',
              header: 'Nombre'
            }
          ]}
        />
      </div>

      {/* MODAL PARA CREAR GRUPO */}
      <ModalGeneric
        show={modal}
        onHide={closeModal}
        id="newGroup"
        onChange={handleOnChange}
        onClose={closeModal}
        title="Crear grupo"
        objects={[
          {
            key: 'key1',
            label: 'Nombre',
            placeholder: 'Nombre...',
            value: group.name,
            name: 'name',
            type: 'text'
          }
        ]}
        btnError="Cerrar"
        actionError={closeModal}
        btnSuccess="Crear"
        actionSuccess={createGroup}
      />
    </div>
  )
}

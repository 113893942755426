import React from 'react'
import ModalGeneric from './modal'
import styles from '../../css/generals/Checkbox.module.css'
import stylesPreferences from '../../css/preferences/Preferences.module.css'
import PropTypes from 'prop-types'

export default function ModalRestoreDB ({ showModal, closeModal, restoreDB, passwords, handleOnChangePasswords, viewPasswords, setViewPasswords }) {
  return (
        <>
            <ModalGeneric
                show={showModal}
                onHide={closeModal}
                id="restoreDB"
                size="md"
                onClose={closeModal}
                title="Restauración de base de datos"
                btnError="Cerrar"
                actionError={closeModal}
                btnSuccess="Restaurar"
                actionSuccess={restoreDB}
            >
                <div>
                    <div>
                        <label className={styles.sliderLabel}>
                            Escriba la contraseña
                        </label>
                    </div>
                    <div className={`${stylesPreferences.inputContainerModal}`}>
                        <input
                            placeholder="Escriba la contraseña"
                            id="pass1"
                            value={passwords?.pass1}
                            onChange={handleOnChangePasswords}
                            type={ viewPasswords?.pass1 && viewPasswords?.pass1 === true ? 'text' : 'password'}
                            className={`${styles.focus} ${stylesPreferences.input}`}
                        />
                        <button
                            onClick={() => setViewPasswords({ ...viewPasswords, pass1: !viewPasswords?.pass1 }) }
                            className={stylesPreferences.buttonShow}
                        >
                            {viewPasswords?.pass1 ? (<i className="fas fa-eye"></i>) : (<i className="fas fa-eye-slash"></i>)}
                        </button>
                    </div>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <div>
                        <label className={styles.sliderLabel}>
                            Repetir la contraseña
                        </label>
                    </div>
                    <div className={`${stylesPreferences.inputContainerModal}`}>
                        <input
                            placeholder="Escriba la contraseña"
                            id="pass2"
                            value={passwords?.pass2}
                            onChange={handleOnChangePasswords}
                            type={ viewPasswords?.pass2 && viewPasswords?.pass2 === true ? 'text' : 'password'}
                            className={`${styles.focus} ${stylesPreferences.input}`}
                        />
                        <button
                            onClick={() => setViewPasswords({ ...viewPasswords, pass2: !viewPasswords?.pass2 }) }
                            className={stylesPreferences.buttonShow}
                        >
                            {viewPasswords?.pass2 ? (<i className="fas fa-eye"></i>) : (<i className="fas fa-eye-slash"></i>)}
                        </button>
                    </div>
                </div>
            </ModalGeneric>
        </>
  )
}

ModalRestoreDB.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  restoreDB: PropTypes.func,
  passwords: PropTypes.object,
  handleOnChangePasswords: PropTypes.func,
  viewPasswords: PropTypes.object,
  setViewPasswords: PropTypes.func
}

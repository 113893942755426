import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { getData, postData, putData } from '../../actions/servicesHttp'
import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import DataTableDemo from '../../components/generals/datatables/datatable'
import Modal from 'react-bootstrap/Modal'
import ModalUsers from '../../components/modals/modalUsers'
import Header from '../../components/generals/header'
import store from '../../reducer/store'
import stylesUsers from '../../css/users/Users.module.css'
import styles from '../../css/generals/Checkbox.module.css'
import stylesIndex from './../../css/Index.module.css'

export default function Users () {
  const { user } = store.getState()
  const role = user.roles

  const [modals, setModals] = useState({
    create: false,
    edit: false,
    group: false
  })

  const [selectedRows, setSelectedRows] = useState([])
  const [data, setData] = useState()
  const [inputs, setInputs] = useState({
    user: '',
    name: '',
    last: '',
    password: '',
    phone: '',
    email: '',
    range: 1,
    geo_id: 1,
    roles: []
  })

  const [newGroup, setNewGroup] = useState({
    user_id: parseInt(user.userId),
    visible: true,
    name: '',
    users: undefined
  })
  const [edits, setEdits] = useState(false)
  const [emailEdits, setEmailEdits] = useState(false)
  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    const response = await getData('users', undefined, undefined)
    const users = response.data.filter((user) => user.id !== 1)
    setData(users)
  }

  const setRolesUser = (info) => {
    const roles = info
    setInputs({ ...inputs, roles })
    setEdits({ ...edits, roles })
  }

  function showErrors (error, type) {
    if (type === 'add') {
      if (error.user === false) {
        $('#user').addClass('error')
        $('#userError').text('Ya existe un usuario con este nombre')
      }
      if (error.email === false) {
        $('#email').addClass('error')
        $('#emailError').text('Ya existe un usuario con este email')
      }
    } else {
      if (error.user === false) {
        $('#editUser').addClass('error')
        $('#editUserError').text('Ya existe un usuario con este nombre')
      }
      if (error.email === false) {
        $('#editEmail').addClass('error')
        $('#editEmailError').text('Ya existe un usuario con este email')
      }
    }
  }

  function validationsForms (type) {
    let response = true

    const messageRequire = 'Este campo es Obligatorio'
    if (type === 'add') {
      if (inputs.user === '') {
        $('#user').addClass('error')
        $('#userError').text(messageRequire)
        response = false
      }
      if (inputs.name === '') {
        $('#name').addClass('error')
        $('#nameError').text(messageRequire)
        response = false
      }
      if (inputs.last === '') {
        $('#last').addClass('error')
        $('#lastError').text(messageRequire)
        response = false
      }
      if (inputs.password === '') {
        $('#password').addClass('error')
        $('#passwordError').text(messageRequire)
        response = false
      }
      if (inputs.phone === '') {
        $('#phone').addClass('error')
        $('#phoneError').text(messageRequire)
        response = false
      }
      if (inputs.email === '') {
        $('#email').addClass('error')
        $('#emailError').text(messageRequire)
        response = false
      }
      if (inputs.roles.length === 0) {
        $('#roleError').text(messageRequire)
        response = false
      }
      // if (inputs.range === '') {
      //   $('#range').addClass('error')
      //   $('#rangeError').text(messageRequire)
      //   response = false
      // }
      if (
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
          inputs.email
        ) === false
      ) {
        $('#email').addClass('error')
        $('#emailError').text('Email con formato inválido')
        response = false
      }
    } else {
      if (edits.user === '') {
        $('#editUser').addClass('error')
        $('#editUserError').text(messageRequire)
        response = false
      }
      if (edits.name === '') {
        $('#editName').addClass('error')
        $('#editNameError').text(messageRequire)
        response = false
      }
      if (edits.last === '') {
        $('#editLast').addClass('error')
        $('#editLastError').text(messageRequire)
        response = false
      }
      if (edits.password === '') {
        $('#editPassword').addClass('error')
        $('#editPasswordError').text(messageRequire)
        response = false
      }
      if (edits.phone === '') {
        $('#editPhone').addClass('error')
        $('#editPhoneError').text(messageRequire)
        response = false
      }
      if (edits.email === '') {
        $('#editEmail').addClass('error')
        $('#editEmailError').text(messageRequire)
        response = false
      }
      if (edits.roles.length === 0) {
        $('#editRoleError').text(messageRequire)
        response = false
      }
      // if (edits.range === '') {
      //   $('#editRange').addClass('error')
      //   $('#editRangeError').text(messageRequire)
      //   response = false
      // }
      if (
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
          edits.email
        ) === false
      ) {
        $('#editEmail').addClass('error')
        $('#editEmailError').text('Email con formato inválido')
        response = false
      }
    }
    return response
  }

  const addUser = async () => {
    $('#btnModalSucess').prop('disabled', true)
    if (validationsForms('add')) {
      const { data } = await postData('users', inputs)
      if (data.result === true) {
        setModals({
          ...modals,
          create: false
        })
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Usuario creado con exito!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
        getUsers()
      } else {
        showErrors(data, 'add')
      }
    }
  }

  const editUser = async (id) => {
    $('#btnModalSucess').prop('disabled', true)
    if (validationsForms('edit')) {
      const response = await putData('users', edits)

      if (response.data.result === true) {
        setModals({
          ...modals,
          edit: false
        })
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Usuario actualizado con exito!',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
        getUsers()
      } else {
        showErrors(response, 'edit')
      }
    }
  }

  function clearInputs () {
    setNewGroup({
      user_id: parseInt(user.userId),
      visible: true,
      name: '',
      users: undefined
    })
  }

  const createGroup = async () => {
    $('#btnModalSucess').prop('disabled', true)

    if (newGroup.name.trim() === '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No se puede crear grupos sin nombre!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
      clearInputs()
      return
    }

    const { data: existingGroups } = await getData('groups', undefined)

    if (existingGroups.some((group) => group.name === newGroup.name.trim())) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'El grupo ya existe!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
      clearInputs()
      return
    }

    const { data } = await postData('groups', newGroup)

    if (data?.result === true) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Grupo creado con éxito!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error al crear el grupo!',
        showConfirmButton: false,
        timer: 2500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    }

    setModals({
      ...modals,
      group: false
    })
    clearInputs()
  }

  function handleOnChange (e) {
    // if (e.target.name === 'range') {
    //   const range = parseInt(e.target.value)
    //   if (e.target.value !== '') {
    //     $(`#${e.target.id}`).removeClass('error')
    //     $(`#${e.target.id}Error`).text('')
    //   }
    //   setInputs({
    //     ...inputs,
    //     [e.target.name]: range
    //   })
    // } else {
    if (e.target.value !== '') {
      $(`#${e.target.id}`).removeClass('error')
      $(`#${e.target.id}Error`).text('')
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
    // }
  }

  function groupHandleOnChange (e) {
    if (e.target.name === 'visible') {
      setNewGroup({
        ...newGroup,
        [e.target.name]: $('#visible').is(':checked')
      })
    } else {
      setNewGroup({
        ...newGroup,
        [e.target.name]: e.target.value
      })
    }
  }

  function editHandleOnChange (e) {
    // if (e.target.name === 'range') {
    //   const range = parseInt(e.target.value)
    //   if (e.target.value !== '') {
    //     $(`#${e.target.id}`).removeClass('error')
    //     $(`#${e.target.id}Error`).text('')
    //   }
    //   setEdits({
    //     ...edits,
    //     [e.target.name]: range
    //   })
    // } else {
    if (e.target.value !== '') {
      $(`#${e.target.id}`).removeClass('error')
      $(`#${e.target.id}Error`).text('')
    }
    setEdits({
      ...edits,
      [e.target.name]: e.target.value
    })
    // }
  }

  function handleOnSubmit (e) {
    e.preventDefault()
  }

  function blanquearForm () {
    setInputs({
      user: '',
      name: '',
      last: '',
      password: '',
      phone: '',
      email: '',
      range: 1,
      geo_id: 1,
      roles: []
    })
    setModals({
      ...modals,
      create: true
    })
  }

  function openEditUsers (data) {
    setEdits(data)
    !emailEdits ?? setEmailEdits(data.email)
    setModals({
      ...modals,
      edit: true
    })
  }

  function showCreateGroup () {
    setModals({
      ...modals,
      group: true
    })
    setNewGroup({
      ...newGroup,
      users: selectedRows.map((item) => item.id)
    })
  }

  function closeCreateGroupModal () {
    setModals({
      ...modals,
      group: false
    })
    clearInputs()
  }

  const handleChange = (data) => {
    setSelectedRows(data)
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Usuarios" />
        <section className={stylesUsers.containerToggle}>
          {role?.usersPage.newGroupUser && <Button
            className={stylesUsers.btnDark}
            onClick={() => showCreateGroup()}
            variant="dark"
          >
            <i className="fas fa-users" style={{ marginRight: '0.5rem' }}></i>
            Nuevo grupo
          </Button>}
          {role?.usersPage.newUserUser && <Button
            className={stylesUsers.btnDark}
            onClick={() => blanquearForm()}
            variant="dark"
            style={{ marginLeft: '1rem' }}
          >
            <i className="fas fa-user" style={{ marginRight: '0.5rem' }}></i>
            Nuevo usuario
          </Button>}
        </section>

        <Modal
          show={modals.group}
          size="md"
          onHide={closeCreateGroupModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Crear grupo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleOnSubmit}>
              <div className="component-users" style={{ display: 'flex' }}>
                <label className={stylesUsers.labelUsers}>
                  <p
                    style={{
                      fontWeight: '500',
                      marginRight: '1rem'
                    }}
                  >
                    Nombre:
                  </p>
                </label>
                <input
                  placeholder="Escriba el nombre..."
                  value={newGroup.name}
                  name="name"
                  onChange={groupHandleOnChange}
                  type="text"
                  className={styles.focus}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeCreateGroupModal} variant="danger">
              Cerrar
            </Button>
            <Button onClick={() => createGroup()} variant="primary">
              Crear
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal para crear usuarios */}
        <ModalUsers
          show={modals.create}
          modals={modals}
          setModals={setModals}
          title="Agregar usuario"
          onSubmit={handleOnSubmit}
          inputs={inputs}
          onChange={handleOnChange}
          setRolesUser={setRolesUser}
          onClick={addUser}
          buttonLabel="Crear"
          type="add"
        />

        {/* Modal para editar usuarios */}
        <ModalUsers
          show={modals.edit}
          modals={modals}
          setModals={setModals}
          title="Editar usuario"
          onSubmit={handleOnSubmit}
          inputs={edits}
          setRolesUser={setRolesUser}
          onChange={editHandleOnChange}
          onClick={editUser}
          buttonLabel="Actualizar"
          type="edit"
          clearInputs={clearInputs}
        />

        <section>
          <div>
            <DataTableDemo
              data={data}
              table="users"
              editDataUser={openEditUsers}
              exportsPdf={role?.usersPage.exportPdfUser}
              exportCsv={role?.usersPage.exportCsvUser}
              actionDelete={role?.usersPage.removeUsersUser}
              editUsers={role?.usersPage.editUsersUser}
              rowSelecteds={role?.usersPage.selectRowsDataTableUser}
              actionDashboard={false}
              rowStates={false}
              onSelecteds={handleChange}
              reload={getUsers}
              searchs={['user', 'name', 'last', 'email', 'phone']}
              columns={[
                {
                  field: 'user',
                  header: 'Usuario'
                },
                {
                  field: 'name',
                  header: 'Nombre'
                },
                {
                  field: 'last',
                  header: 'Apellido'
                },
                {
                  field: 'phone',
                  header: 'Telefono'
                },
                {
                  field: 'email',
                  header: 'Email'
                }
              ]}
            />
          </div>
        </section>
      </div>
    </div>
  )
}

import React from 'react'
import Info from '../Dashboards/info'
import VerticalBar from '../generals/charts/verticalBar'
import Loading from '../generals/loading'
import PropTypes from 'prop-types'

export default function Wanted (querys, { top3LastSearched, top10UrlsVerticalBar }) {
  // últimas 3 urls buscadas
  const top3 = querys?.data?.last

  // Top 10 de las urls más buscadas

  const top10 = querys?.data?.most

  const labelsTop10 = top10?.map(({ url }) => {
    return url
  })

  const dataTop10 = top10?.map(({ count }) => {
    return count
  })

  if (!querys) {
    return <Loading color="primary" />
  } else if (querys?.data?.length === 0) {
    return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {top3LastSearched && <div style={{ width: '90%', height: '55%' }}>
                    <Info
                        icon="pi pi-bookmark"
                        title="Sin data a mostrar"
                        value="0 visitas"
                    />
                </div>}
                {top10UrlsVerticalBar && <div
                    style={{
                      width: '90%',
                      height: '30rem',
                      marginTop: '2rem'
                    }}
                >
                    <VerticalBar
                        labels={labelsTop10}
                        title="Conexiones"
                        data={dataTop10}
                        backgroundColor={'#3F9BFF'}
                    />
                </div>}
            </div>
    )
  } else {
    return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                {top3LastSearched && <div style={{ width: '90%', height: '55%' }}>
                    {querys && querys.data?.last.length === 0 &&
                        <Info
                            icon="pi pi-bookmark"
                            title="Sin data a mostrar"
                            value="0 visitas"
                        />}
                            {top3 && top3.length !== 0
                              ? top3.map((element, index) => (
                                    <Info
                                        key={index}
                                        title={element.url}
                                        icon="pi pi-bookmark"
                                    />
                              ))
                              : null
                            }
                </div>}
                {querys.data?.most && top10 && top10UrlsVerticalBar &&
                    <div
                        style={{
                          width: '90%',
                          height: '30rem',
                          marginTop: '2rem'
                        }}
                    >
                        <VerticalBar
                            labels={labelsTop10}
                            title="Conexiones"
                            data={dataTop10}
                            backgroundColor={'#3F9BFF'}
                        />
                    </div>}
            </div>
    )
  }
}

Wanted.propTypes = {
  querys: PropTypes.object,
  top3LastSearched: PropTypes.bool,
  top10UrlsVerticalBar: PropTypes.bool
}

export function usersJson () {
  const items = {
    newGroupUser: {
      name: 'newGroupUser',
      label: 'crear un grupo'
    },
    newUserUser: {
      name: 'newUserUser',
      label: 'crear un usuario'
    },
    exportPdfUser: {
      name: 'exportPdfUser',
      label: 'exportar usuarios en formato pdf'
    },
    exportCsvUser: {
      name: 'exportCsvUser',
      label: 'exportar usuarios en formato csv'
    },
    removeUsersUser: {
      name: 'removeUsersUser',
      label: 'eliminar usuarios'
    },
    editUsersUser: {
      name: 'editUsersUser',
      label: 'editar usuarios'
    },
    selectRowsDataTableUser: {
      name: 'selectRowsDataTableUser',
      label: 'seleccionar filas de la tabla de datos'
    }
    // ,
    // columnsDataTable: []

  }

  return (items)
}

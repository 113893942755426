import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    userId: parseInt(sessionStorage.getItem('userId')),
    range: parseInt(sessionStorage.getItem('range')),
    token: sessionStorage.getItem('token'),
    roles: sessionStorage.getItem('roles') ? JSON.parse(sessionStorage.getItem('roles')) : null
  },
  reducers: {
    setUsers: (state, action) => {
      state.userId = action.payload.userId
      state.range = action.payload.range
      state.token = action.payload.token
      state.roles = action.payload.roles
    }
  }
}
)

export const { setUsers } = userSlice.actions

export default userSlice.reducer

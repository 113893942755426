import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line react/prop-types
export default function Button ({ title, id, click, style, icon, className }) {
  return (
        <>
            <button
                key={title}
                id={id}
                type="button"
                onClick={click}
                style={style}
                className={className}
            >
                {icon} {title}
            </button>
        </>
  )
}

Button.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  icon: PropTypes.string,
  click: PropTypes.func,
  style: PropTypes.string,
  className: PropTypes.string
}

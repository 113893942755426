import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { NavLink } from 'react-router-dom'
import { config } from '../../config'
import iconMap from './iconMap'
import styles from '../../css/devices/LocationHistory.module.css'
import stylesDashDevices from '../../css/Dashboards/DashDevices.module.css'
import PropTypes from 'prop-types'
import { formatDate } from './charts/utils/DatesFormats'

export default function Mapa ({ info, type, funcSokLocation, realTime }) {
  const [data, setData] = useState(null)
  const [findDevice, setFindDevice] = useState(false)

  let date = new Date()
  date = date.toLocaleString()

  useEffect(() => {
    if (realTime) {
      funcSokLocation(setData, setFindDevice)
    } else {
      if (info) {
        if (type === 'last') {
          setData([info])
        } else {
          setData(info)
        }
      }
      setFindDevice(false)
    }
  }, [realTime, info])

  if (data) {
    return (
      <>
      {findDevice && <div className={`${stylesDashDevices.findDevice}`}>
        <i className="pi pi-clock" style={{ marginRight: '0.5rem' }}></i>
        <p>{date}</p>
        <div className={`${stylesDashDevices.now}`}>
          <p>Ahora</p>
        </div>
      </div>}
      <MapContainer
      className="divMaps"
      center={type === 'last' ? data[0]?.lat && data[0]?.lon ? [data[0]?.lat, data[0]?.lon] : config.map.center : config.map.center}
      zoom={config.map.zoom_specific}
      scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        {type !== 'last'
          ? (
              data?.map((element) =>
                element?.coordinates[0] && element?.coordinates[1] !== null
                  ? (
              <Marker
                key={element?.device.id}
                position={[element?.coordinates[0], element?.coordinates[1]]}
                icon={iconMap}
              >
                <Popup>
                  <NavLink
                    to={`/mdm/device/${element?.device.id}`}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}
                  >
                    {' '}
                    Ver Dispositivo
                  </NavLink>
                </Popup>
              </Marker>
                    )
                  : null
              )
            )
          : data?.place
            ? (
          <Marker key={data[0]?.id} position={[data[0]?.lat, data[0]?.lon]} icon={iconMap}>
            <Popup>
              <div className={styles.popup}>
                <span>
                  Ubicación:
                  {data[0]?.place}
                </span>
              </div>
            </Popup>
          </Marker>
              )
            : (
          <Marker key={data[0]?.id} position={[data[0]?.lat, data[0]?.lon]} icon={iconMap}>
            <Popup>
              <div className={styles.popup}>
                <span>Ubicacion: {data[0]?.place}</span>
                <span>Fecha:{formatDate(data[0]?.date)} </span>
                <span>Tipo de ubicación: {data[0]?.type} </span>
              </div>
            </Popup>
          </Marker>
              )}
      </MapContainer>
      </>)
  } else {
    return (
      <MapContainer
        className="divMaps"
        center={config.map.center}
        zoom={config.map.zoom_general}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    )
  }
}

Mapa.propTypes = {
  funcSokLocation: PropTypes.func,
  type: PropTypes.string,
  info: PropTypes.object,
  realTime: PropTypes.bool/* ,
  setFindDevice: PropTypes.func */
}

import { getData, postData } from '../../actions/servicesHttp'

// función para comparar dos objetos
export function compareObj (obj1, obj2) {
  const obj1Keys = Object.keys(obj1).sort()
  const obj2Keys = Object.keys(obj2).sort()

  if (obj1Keys.length !== obj2Keys.length) {
    return false
  }
  for (let i = 0; i < obj1Keys.length; i++) {
    if (validateDate(obj1[obj1Keys[i]])) {
      if (obj1[obj1Keys[i]] !== obj2[obj2Keys[i]]) {
        return false
      }
    }
  }
  return true
};

// funcion para validar si es un objeto Date
export function validateDate (date) {
  const value = Number.isInteger(date) ? null : date
  const validation = isNaN(Date.parse(value))
  return validation
}

// funcion para modificar las listas y las palabras claves del Proxy
export const modifyProxy = async (type) => {
  const { data, status } = await getData(`safeweb/${type}`)
  if (status === 200 && data.length !== 0) {
    let array
    if (type === 'keyWords') {
      array = data.map(({ keyword }) => keyword)
    } else {
      array = data.map(({ url }) => url)
    }

    const body = {
      [type]: {
        [type === 'whiteList' || type === 'blackList' ? 'urls' : 'keywords']: array
      }
    }

    const response = await postData(`ProxySafeWeb?endpoint=${type}`, JSON.stringify(body))
    console.log(response)
  }
}

// funcion para modificar las preferencias del Proxy
export const modifyPreferencesProxy = async (id) => {
  const { data, status } = await getData('safeweb/preferences', id)
  if (status === 200 && data.length !== 0) {
    const body = {
      preferencesSafeWeb: {
        blackList: data.blackList,
        whiteList: data.whiteList,
        keyWords: data.keyWords
      }
    }

    const response = await postData('ProxySafeWeb?endpoint=preferences', JSON.stringify(body))
    console.log(response)
  }
}

export function preferencesReportsJson () {
  const items = {
    reports: {
      name: 'reports',
      label: 'activar reportes y alerta de sobrecarga'
    }
  }

  return (items)
}

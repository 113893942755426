import React, { useState, useEffect, useRef } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import States from '../../../components/generals/states.js'
import store from '../../../reducer/store'
import CheckErrorLog from '../../modals/modalsRxartSecure/components/checkErrorLogs.js'
import ActionDashboard from './actionDashboard'
import NavLinkButton from './navlinkButton..js'
import ActionDelete from './actionDelete'
import ActionOptionsMenuGeofence from './actionOptionsMenuGeofence.js'
import ModifyListSafe from './modifyListSafe.js'
import Swal from 'sweetalert2'
import { exportPdf } from './utilsDataTableLazy'
import PropTypes from 'prop-types'
import '../../../css/generals/dataTable.css'

export default function DataTableDemo ({
  searchs,
  table,
  type,
  onSelecteds,
  selected,
  editDataUser,
  actionBtn,
  titleBtn,
  reload,
  title,
  header,
  showMenu,
  getFence,
  exportCsv,
  exportsPdf,
  rowSelecteds,
  rowStates,
  actionDashboard,
  actionDelete,
  optionsMenu,
  modifyLists,
  viewBtnAction,
  columns,
  data,
  editUsers,
  link,
  informationOptionMenu,
  editOptionMenu,
  columnStatus
}) {
  const { user } = store.getState()
  const dt = useRef(null)
  const [customers, setCustomers] = useState(data)
  const [selectedCustomers, setSelectedCustomers] = useState(null)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [searchs[0]]: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
    }
  })

  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setCustomers(data)
    setLoading(false)
    if (table !== 'roles') { setSelectedCustomers(null) } else if (selected?.length > 0) { setSelectedCustomers(selected) }
  }, [data, table])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    const _filters = { ...filters }
    _filters.global.value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const selecteds = (data) => {
    setSelectedCustomers(data)
    if (table !== 'keyWords') onSelecteds(data, table)
  }

  const states = (rowData) => {
    const typeColumn = columnStatus.find((col) => col.field === 'type')
    const statusLockColumn = columnStatus.find((col) => col.field === 'status_lock')
    return (
      <States
        alert={rowData.stat}
        title={statusLockColumn !== undefined
          ? rowData.status_lock === true
            ? 'Bloqueado'
            : rowData.status_lock === false || rowData.status_lock === null
              ? 'Desbloqueado'
              : 'Desconocido'
          : 'Desconocido'
        }
        type={typeColumn !== undefined ? rowData.type : undefined}
      ></States>
    )
  }

  const editUser = (rowData) => {
    return (
      <Button
        icon="fa-solid fa-pen-to-square"
        className="p-button-rounded p-button-success btn-white"
        aria-label="Search"
        onClick={() => editDataUser(rowData)}
      />
    )
  }

  const btn = (rowData) => {
    return (
        <Button
          className="p-button-secondary"
          onClick={() => actionBtn(rowData)}
          disabled={table === 'cases' && rowData.trackingId === null}
        >
          {table === 'cases' && rowData.trackingId === null ? 'Sin registros' : titleBtn}
        </Button>
    )
  }

  const navLink = (rowData) => {
    return (
      <NavLinkButton
        title={table === 'cases' ? 'Ver Imagenes' : null}
        url={`gallery/${rowData.id}`}
      />
    )
  }

  const dashboard = (rowData) => {
    return (
      <ActionDashboard
        type={table === 'devices' ? 'device' : 'group'}
        id={rowData.id}
      ></ActionDashboard>
    )
  }

  const deleted = (rowData) => {
    return (
      <ActionDelete
        id={rowData.id}
        table={table}
        reload={reload}
      ></ActionDelete>
    )
  }

  const modifyListsSW = (rowData) => {
    return (
      <ModifyListSafe data={rowData} reload={reload}></ModifyListSafe>
    )
  }

  const optionsMenuGeofence = (rowData) => {
    return (
      <ActionOptionsMenuGeofence
        id={rowData.id}
        table={table}
        onShowModal={showMenu}
        onGetFence={getFence}
        informationOptionMenu={informationOptionMenu}
        editOptionMenu={editOptionMenu}
      ></ActionOptionsMenuGeofence>
    )
  }

  const exportCSV = (selectionOnly) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons
      .fire({
        title: 'Descargar tabla en formato CSV?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, descargar',
        cancelButtonText: 'No, cerrar',
        reverseButtons: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          dt.current.exportCSV({ selectionOnly })
        }
      })
  }

  const renderHeader = () => {
    return (
      <div
        className={
          header === 'column' ? 'headerTableColumn' : 'headerTable'
        }
      >
        <h3 className="m-0">{title}</h3>
        <div className="containerHeader">
          <div className="buttonExtract">
            {exportCsv === false || user.range === 3
              ? null
              : (
              <Button
                type="button"
                icon="pi pi-file"
                onClick={() => exportCSV(false)}
                className="mr-2"
                data-pr-tooltip="CSV"
              />
                )}
            {exportsPdf &&
              <Button
                type="button"
                icon="pi pi-file-pdf"
                onClick={() => exportPdf(undefined, columns, table, data)}
                className="p-button-success mr-2"
                data-pr-tooltip="PDF"
              />
            }
          </div>
          <div className="searchContainer">
            <span className="p-input-icon-left">
              <i className="pi pi-search search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Buscar..."
              />
            </span>
          </div>
        </div>
      </div>
    )
  }
  const headerDataTable = renderHeader()

  const renderCheckLog = () => <CheckErrorLog />
  const checkDataTableLogs = renderCheckLog()

  return (
    <div className="datatable-doc-demo">
      {table === 'errorLogs' && checkDataTableLogs}
      <div id={ type === 'apps' ? 'tableApps' : '' }>
        <DataTable
          size="small"
          ref={dt}
          value={customers}
          paginator
          className="p-datatable-customers"
          header={table === 'statusHistoryRS' ? null : table === 'ticketsIssuedRS' ? null : headerDataTable}
          rows={5}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          rowHover
          selection={selectedCustomers}
          onSelectionChange={(e) => selecteds(e.value)}
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          globalFilterFields={searchs}
          emptyMessage="No existen datos para mostrar."
          currentPageReportTemplate="Mostrando {last} de {totalRecords}"
        >
          {rowSelecteds === false || user.range === 3
            ? null
            : (
            <Column
              selectionMode="multiple"
              selectionAriaLabel="name"
              headerStyle={{ width: '3rem' }}
              style={{ height: '5.5rem', textAlign: 'center' }}
              headerClassName="selectionChexbox"
            />
              )}

          {rowStates === false
            ? null
            : (
            <Column
              sortable
              header="Estado"
              headerStyle={{
                width: '5rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'visible',
                justifyContent: 'center'
              }}
              style={{ minWidth: '10rem', height: '5.5rem' }}
              body={states}
            />
              )}

          {columns &&
            columns.map((column) => {
              return (
                <Column
                  key={column.field}
                  field={column.field}
                  header={column.header}
                  headerStyle={{
                    width: '5rem',
                    textAlign: 'center',
                    justifyContent: 'center'
                  }}
                  bodyStyle={{
                    textAlign: 'center',
                    justifyContent: 'center'
                  }}
                  sortable
                  style={{
                    minWidth: '10rem',
                    height: '5.5rem'
                  }}
                />
              )
            })}

          {actionDashboard === false
            ? null
            : (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={dashboard}
            />
              )}
          {viewBtnAction === true
            ? (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={btn}
            />
              )
            : null}
          {link === true
            ? (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={navLink}
            />
              )
            : null}
          {editUsers === false
            ? null
            : (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={editUser}
            />
              )}
          {modifyLists === true
            ? (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={modifyListsSW}
            />
              )
            : null}

          {table === 'geofences' && optionsMenu === true
            ? (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={optionsMenuGeofence}
            />
              )
            : null}
          {actionDelete === false ||
          (table !== 'blackList' &&
            table !== 'whiteList' &&
            table !== 'suspect' &&
            user.range !== 1)
            ? null
            : (
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={deleted}
            />
              )}
        </DataTable>
      </div>
    </div>
  )
}

DataTableDemo.propTypes = {
  searchs: PropTypes.array,
  table: PropTypes.string,
  type: PropTypes.string,
  onSelecteds: PropTypes.func,
  editDataUser: PropTypes.func,
  actionBtn: PropTypes.func,
  titleBtn: PropTypes.string,
  reload: PropTypes.func,
  title: PropTypes.string,
  header: PropTypes.string,
  showMenu: PropTypes.func,
  getFence: PropTypes.func,
  exportCsv: PropTypes.bool,
  exportsPdf: PropTypes.bool,
  rowSelecteds: PropTypes.bool,
  rowStates: PropTypes.bool,
  actionDashboard: PropTypes.bool,
  actionDelete: PropTypes.bool,
  optionsMenu: PropTypes.bool,
  modifyLists: PropTypes.bool,
  viewBtnAction: PropTypes.bool,
  link: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  selected: PropTypes.array,
  editUsers: PropTypes.bool,
  informationOptionMenu: PropTypes.bool,
  editOptionMenu: PropTypes.bool,
  columnStatus: PropTypes.array

}

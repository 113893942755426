export function safeWebHomeJson () {
  const items = {
    blackListLine: {
      name: 'blackListLine',
      label: 'gráfico de urls en la lista negra'
    },
    whiteListLine: {
      name: 'whiteListLine',
      label: 'gráfico de urls en la lista blanca'
    },
    querysLine: {
      name: 'querysLine',
      label: 'gráfico de urls'
    },
    top3LastSearched: {
      name: 'top3LastSearched',
      label: 'top de las últimas 3 urls buscadas'
    },
    top10UrlsVerticalBar: {
      name: 'top10UrlsVerticalBar',
      label: 'gráfico de barras de las 10 urls más buscadas'
    }
  }

  return (items)
}

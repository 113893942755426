import React from 'react'
import { Avatar } from 'primereact/avatar'
import styles from '../../css/Dashboards/Info.module.css'
import PropTypes from 'prop-types'

export default function Info (props) {
  return (
        <div
            className={`${styles.containerInfo} ${props?.className}`}
        >
            <div className={styles.headerInfo}>
                <Avatar
                    icon={props.icon}
                    className="mr-2"
                    size="large"
                    style={{ backgroundColor: '#E4E4E4', color: 'black' }}
                    shape="circle"
                />
            </div>
            <div className={styles.bodyInfo}>
                <h5>{props.title}</h5>
                <p>{props.value}</p>
            </div>
        </div>
  )
}

Info.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string
}

import axios from 'axios'
import { config } from '../config'
import store from '../reducer/store'
import { setUsers } from '../reducer/slices/User'
import { redirectIndex } from '../components/generals/redirect'
import { errorHandling } from '../components/generals/errorHandling'

// AUTENTICACION
function getCredetentials () {
  const { user } = store.getState()
  const auth = {
    headers: {
      Authorization: JSON.stringify({
        token: user.token,
        userId: user.userId,
        roles: user.roles
      }),
      'Content-Type': 'application/json'
    }
  }

  return auth
}

// GET
export const getData = async (ruta, id) => {
  let response
  let petition
  let resError
  const credentials = ruta === 'reports' ? null : getCredetentials()

  if (id !== undefined) {
    petition = await axios
      .get(
        `${config.backend.host}:${config.backend.portHttp}/${ruta}/${id}`,
        credentials
      )
      .catch((response) => {
        resError = errorHandling(response, 'get')
      })
  } else {
    petition = await axios
      .get(
        `${config.backend.host}:${config.backend.portHttp}/${ruta}`,
        credentials
      )
      .catch((response) => {
        resError = errorHandling(response, 'get')
      })
  }

  if (!resError) response = { data: petition?.data, status: petition?.status }

  return resError !== undefined ? resError : response
}

// POST
export const postData = async (table, payload) => {
  let response
  let resError
  let data
  if (table === 'actions') {
    data = {
      actions: payload
    }
  } else {
    data = payload
  }
  const petition = await axios
    .post(
      `${config.backend.host}:${config.backend.portHttp}/${table}`,
      data,
      getCredetentials()
    )
    .catch((response) => {
      resError = errorHandling(response, 'post')
    })

  if (!resError) response = { data: petition.data, status: petition.status }

  return resError !== undefined ? resError : response
}

// PUT
export const putData = async (table, payload) => {
  return await axios
    .put(
      `${config.backend.host}:${config.backend.portHttp}/${table}`,
      payload,
      getCredetentials()
    )
    .catch(({ response }) => {
      if (response.data.redirect) {
        return redirectIndex()
      } else {
        return response
      }
    })
}

// DELETE
export const deleteData = async (table, id) => {
  return await axios
    .delete(
      `${config.backend.host}:${config.backend.portHttp}/${table}/${id}`,
      getCredetentials()
    )
    .catch(({ response }) => {
      if (response.data.redirect) {
        return redirectIndex()
      }
    })
}

// ------------------ LOGIN ------------------

export const verifyUser = async (payload) => {
  let resError

  const data = await axios
    .put(`${config.backend.host}:${config.backend.portHttp}/login`, payload)
    .catch((response) => {
      resError = errorHandling(response, 'login')
    })

  if (data !== undefined) {
    if (data?.data.auth) {
      const role = data.data.roles ? data.data.roles : config.usersWithoutRole
      const sesion = {
        userId: data.data.id,
        range: data.data.range,
        token: data.data.token,
        roles: role
      }
      sessionStorage.setItem('userId', sesion.userId)
      sessionStorage.setItem('range', sesion.range)
      sessionStorage.setItem('token', sesion.token)
      sessionStorage.setItem('roles', JSON.stringify(role))

      store.dispatch(setUsers(sesion))
    }
  }

  return resError !== undefined ? resError : data
}

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DataTableDemo from '../../components/generals/datatables/datatable'
import ModalGeneric from './modal'
import '../../css/generals/paper.css'
import styles from '../../css/generals/Checkbox.module.css'
import stylesModal from '../../css/modals/Modal.module.css'
import Loading from '../generals/loading'
import { Button } from 'primereact/button'
import Swal from 'sweetalert2'
import { getData, postData } from '../../actions/servicesHttp.js'
import { popupSimple } from '../../components/generals/popups'
import $ from 'jquery'
import PropTypes from 'prop-types'
import { formatDate, secondsToString } from '../generals/charts/utils/DatesFormats.js'
import HorizontalBar from '../generals/charts/horizontalBar.js'

export default function ModalApps ({ type, identity, show, onHide, dashboardDevicePage, so, socketSendActions, realTime }) {
  // console.log(apps)
  const { id } = useParams()

  // ? urls de la API
  const [applicationsUrl] = useState('device/applications')

  //* estados de componentes
  const [applications, setApplications] = useState()
  const [system, setSystem] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [files, setFiles] = useState(null)

  //* llamador de datos de API
  const getRequest = async (url, data, set) => {
    const array = ['No Permitidas', 'Sistema', 'Educativas', 'No Educativas', 'Productividad', 'Internet', 'Ocio', 'Otras']
    const response = await getData(url, data)
    // console.log(response, url)
    if (response.status === 200) {
      if (response.data) {
        if (response.data.length === 0) set([])
        else {
          // console.log(response)
          response.data?.apps.forEach((app) => {
            if (app.groupApp !== null && app.groupApp >= 0 && app.groupApp <= 7) {
              app.category = array[app.groupApp]
            } else {
              app.category = 'Sin asignar'
            }

            if (app.dateUseTime === undefined) {
              app.dateUseTime = '-'
            } else {
              app.dateUseTime = formatDate(app.dateUseTime)
            }

            if (app.useTime === undefined) {
              app.useTime = '-'
            } else {
              app.useTime = secondsToString(app.useTime)
            }
          })

          const data = response.data
          let apps
          if (system) {
            apps = response.data?.apps.filter((app) => app.system === true)
          } else {
            apps = response.data?.apps.filter((app) => app.system !== true)
          }
          data.apps = apps
          set(data)
        }
      }
    } else {
      set({ error: true, code: response.code })
    }
  }

  useEffect(() => {
    if (show) getRequest(applicationsUrl, id, setApplications)
  }, [applicationsUrl, id, show, system])

  useEffect(() => {
    setSelectedRows([])
  }, [] /* [props] */)

  function onChange (e) {
    setSystem(e.target.checked)
  };

  function closeModal () {
    onHide()
    setSelectedRows([])
    setFiles(null)
  };

  const handleChange = (data) => {
    setSelectedRows(data)
  }

  async function uninstallApps (data) {
    const arrayApps = []

    if (data.length !== 0) {
      data.forEach(element => {
        const json = {
          action: 'uninstall',
          data: {
            uninstall: element.uninstall
          },
          date: new Date(Date.now()),
          devicesId: element.devicesApplication?.deviceId !== undefined ? [element.devicesApplication.deviceId] : [],
          groupsId: []
        }
        arrayApps.push(json)
      })

      if (realTime) {
        socketSendActions(arrayApps)
        popupSimple('success', 'Acción realizada con éxito!')
      } else {
        const response = await postData('actions', arrayApps)
        if ((response.data[0].result === true) && (Array.isArray(response.data[0].sendings)) && (response.data[0].sendings.length === 0)) {
          popupSimple('success', 'Solicitud enviada con éxito!')
        } else if ((response.data[0].result === true) && (Array.isArray(response.data[0].sendings)) && (response.data[0].sendings.length > 0) && (response.data[0].sendings.includes(identity))) {
          popupSimple('success', 'Acción realizada con éxito!')
        } else {
          popupSimple('error', 'Error. No se pudo realizar la acción!')
        }
      }
      closeModal()
    } else {
      popupSimple('info', 'Debe seleccionar las aplicaciones a desinstalar!')
    }
  };

  function insertApps () {
    $('#contentInstallApps').toggleClass('visible')
    $('#i-btn').toggleClass('btnIRight')
    $('#i-btn').toggleClass('btnILeft')
  };

  function inputFileHandling () {
    const input = document.querySelector('#fileToInstall')

    input.addEventListener('change', e => {
      const span = document.querySelector('#fancy_file_name_document')

      if (input?.files?.length === 0) {
        span.innerHTML = 'Ningún archivo selccionado'
      } else if (input?.files?.length > 0) {
        span.innerHTML = input.files[0].name
        setFiles(input.files)
      }
    })
  }

  const uploadApps = async () => {
    const f = new FormData()

    if (files === null) {
      popupSimple('info', 'Debe seleccionar la aplicación a instalar!')
    } else {
      for (let i = 0; i < files.length; i++) {
        f.append('files', files[i])
      }

      let timerInterval

      Swal.fire({
        title: '¡Enviando solicitud!',
        html: '',
        timer: 30000,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(500)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

      const response = await postData('upload/install', f)
      if (response.data.result !== false) {
        let url = response.data.url
        url = url.trim()
        const splitUrl = url.split('/')
        installApps(url, splitUrl)
        closeModal()
      } else {
        popupSimple('error', 'El proceso no pudo ser completado!')
      }
    }
  }

  const installApps = async (url, fileName) => {
    const json = {
      action: 'install',
      data: {
        fileName: fileName[fileName.length - 2] + '.' + fileName[fileName.length - 1],
        url,
        type: 'apk'
      },
      date: new Date(Date.now()),
      devicesId: type === 'devices' ? [parseInt(id)] : [],
      groupsId: type === 'groups' ? [parseInt(id)] : []
    }

    if (realTime) {
      socketSendActions([json])
      popupSimple('success', 'Acción realizada con éxito!')
    } else {
      const { data } = await postData('actions', [json])
      if ((data[0].result === true) && (Array.isArray(data[0].sendings)) && (data[0].sendings.length === 0)) {
        popupSimple('success', 'Solicitud enviada con éxito!')
      } else if ((data[0].result === true) && (Array.isArray(data[0].sendings)) && (data[0].sendings.length > 0) && (data[0].sendings.includes(identity))) {
        popupSimple('success', 'Acción realizada con éxito!')
      } else {
        popupSimple('error', 'Error. No se pudo realizar la acción!')
      }
    }
  }

  return (
        <>
            <ModalGeneric
                // {...props}
                show={show}
                onHide={onHide}
                size="xl"
                action="modalApp"/* "modalApps" */
                id="modalApp"/* "modalApps" */
                onClose={closeModal}
                title="Aplicaciones"
                btnError="Cerrar"
                actionError={closeModal}
                viewBtnSuccess={false}
            >
              {applications && applications?.useTime.length !== 0 && <div className={`${stylesModal.horizontalBarContainer}`}>
                <p className={`${stylesModal.subtitle}`}>Uso de Aplicaciones</p>
                < HorizontalBar
                  title='Tiempo de uso en minutos (min)'
                  dataChart={applications.useTime?.map((time) => time / 60)}
                  labels={applications.labels}
                />
              </div>}

              <p className={`${stylesModal.subtitle}`}>Aplicaciones instaladas</p>
                <div className={`buttonsApps ${stylesModal.headerDataTable}`}>
                {type !== 'groups' && dashboardDevicePage?.actionInstallApplications
                  ? (
                    <div>
                        <Button
                            type="button"
                            className={`p-button-success mr-2 uncheck ${styles.btn}`}
                            onClick={() => insertApps()}
                        >
                            <i className="pi pi-plus btnILeft" style={{ marginRight: '0.5rem' }} id="i-btn"></i>
                            Enviar
                        </Button>

                        <Button
                        type="button"
                        onClick={() => uninstallApps(selectedRows)}
                        className={`p-button-success mr-2 uncheck ${styles.btn}`}
                        >
                            <i className="pi pi-trash" style={{ marginRight: '0.5rem' }}></i>
                            Desinstalar
                        </Button>

                    </div>
                    )
                  : (<></>)}
                    {type !== 'groups'
                      ? (<div>
                        <label className={styles.sliderLabel}>Sistema</label>
                        <label className={styles.switch}>
                            <input
                                value={system}
                                checked={system}
                                onChange={onChange}
                                type="checkbox"
                                className="focus"
                            />
                            <span
                                className={`${styles.slider} ${styles.round} ${styles.inputsLabels}`}
                            />
                        </label>
                    </div>)
                      : (<></>)}
                </div>
                <div
                    className = {
                        type === 'groups'
                          ? `${styles.contentInputFile} ${styles.contentInputFileGroups}`
                          : styles.contentInputFile
                    }
                    id="contentInstallApps"
                >
                    <label>Seleccionar la aplicación a instalar:</label>
                    <div className={styles.contentFileAndButton}>
                        <input type="file" accept={so?.includes('Android') ? '.apk' : '.exe, .sfx, .msi'} id="fileToInstall" name="files" className={stylesModal.fancy_file}/>
                        <label htmlFor="fileToInstall" className={stylesModal.fancy_file_label}>
                            <span className={stylesModal.fancy_file_button} onClick={() => inputFileHandling()}>
                                <i className="pi pi-download"></i></span>
                            <span className={stylesModal.fancy_file_name}>
                                <span id="fancy_file_name_document">Ningún archivo seleccionado</span>
                            </span>
                        </label>
                        <Button
                            type="button"
                            onClick={() => uploadApps()}
                            className={`p-button-success mr-2 uncheck ${styles.btn}`}
                        >
                            Instalar
                        </Button>
                    </div>
                </div>
                {type !== 'groups'
                  ? applications !== undefined
                    ? (
                    <DataTableDemo
                        data={applications.apps}
                        actionDashboard={false}
                        actionDelete={false}
                        editUsers={false}
                        rowStates={false}
                        rowSelecteds={true}
                        exportCsv={true}
                        exportsPdf={true}
                        searchs={['app']}
                        onSelecteds={handleChange}
                        columns={[
                          {
                            field: 'app',
                            header: 'Nombre'
                          },
                          {
                            field: 'version',
                            header: 'Version'
                          },
                          {
                            field: 'category',
                            header: 'Categoria'
                          },
                          {
                            field: 'useTime',
                            header: 'Tiempo de Uso (hs:min:seg)'
                          },
                          {
                            field: 'dateUseTime',
                            header: 'Fecha de Uso'
                          }
                        ]}
                    />
                      )
                    : (
                    <Loading color="primary" />
                      )
                  : (<></>)}

            </ModalGeneric>
        </>
  )
}

ModalApps.propTypes = {
  type: PropTypes.string,
  identity: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  dashboardDevicePage: PropTypes.object,
  so: PropTypes.string,
  socketSendActions: PropTypes.func,
  realTime: PropTypes.bool
}

import React, { useEffect, useState/* , useRef */ } from 'react'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import ModalBody from 'react-bootstrap/esm/ModalBody'
import ModalFooter from 'react-bootstrap/esm/ModalFooter'
import ModalTitle from 'react-bootstrap/esm/ModalTitle'
// import { Emmiter, socketIO } from './.././../actions/SoketIoService/serverWss'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
// import { getData, postData } from '../../../actions/servicesHttp'

import styles from '../../css/modals/Modal.module.css'
import styleschecks from '../../css/generals/Checkbox.module.css'

export default function ModalCommands ({ identity, onHide }) {
  const [commandInput, setCommandInput] = useState('')
  const [commandOutput, setCommandOutput] = useState('')
  // const sok = useRef(socketIO()).current
  // const [realTime, setRealTime] = useState(sok.connected)

  // useEffect(() => {
  //   if (!realTime) {
  //     Emmiter(sok, 'connected', { identity, open: true })
  //     sok.on('responseConnect', () => {
  //       console.log('se conecto!!!!')
  //       sok.off('responseConnect')
  //       setRealTime(sok.connected)
  //     })
  //   }
  // }, [sok.connected, realTime])

  // function sendCommand (e) {
  //   console.log('entro', commandInput, sok.connected, sok.active)
  //   if (realTime) { Emmiter(sok, 'message', { command: commandInput, identity }) } else {
  //     console.log('pepe')
  //   }
  // }

  useEffect(() => {
    console.log(commandInput)
  }, [commandInput])
  useEffect(() => {
    setCommandOutput(commandOutput + 'pepe ')
  }, [commandInput])
  return (
      <>
        <ModalHeader>
          <ModalTitle>Ejecutar comandos en el equipo</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {/* <h5>Websocket: {realTime ? 'conectado' : 'desconectado'}</h5> */}
          <div className='container' style={{ margin: '1rem 0' }}>
            <label>Comando:</label>
            <textarea
              defaultValue={''}
              placeholder='comando...'
              onChange={({ target }) => setCommandInput(target.value)}
              className={`${styles.inputModal} ${styles.inputUsers} ${styleschecks.focus}`}
              style={{
                height: '7rem',
                resize: 'none'
              }}
            ></textarea>
          </div>
          <div className='container'>
            <label>Respuesta:</label>
            <textarea
              placeholder='Esperando Respuesta...'
              className={`${styles.inputModal} ${styles.inputUsers} ${styleschecks.focus}`}
              style={{
                height: '7rem',
                resize: 'none'
              }}
            >
              {commandOutput}
            </textarea>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button className='btn btn-primary' /* onClick={sendCommand} */>Enviar</Button>
            <Button className='btn btn-danger' onClick={onHide}>Cerrar</Button>
        </ModalFooter>
      </>
  )
}

ModalCommands.propTypes = {
  onHide: PropTypes.func,
  identity: PropTypes.string
}

import { differenceTime } from '../../../../components/generals/charts/utils/DatesFormats'

import { formatDays, labels } from '../../../Home/Assets/formatUseTimeApps'

export default function formatConnections (connections) {
  // console.log(connections)

  let labelsArray = []
  const datesArray = []
  const blackgroundColorData = []
  let firstDate

  const numberOfDays = 7

  for (let i = 0; i < numberOfDays; i++) {
    let day = formatDays(i)
    day = labels(day)
    labelsArray.push(day)
    datesArray.push(0)
    blackgroundColorData.push('#3F9BFF')
  }

  labelsArray = labelsArray.reverse()

  if (connections?.length > 0) {
    connections.forEach(element => {
      let day = new Date(element.date).toISOString().split('T')[0]
      day = labels(day)
      const isEqual = (element) => element === day
      const index = labelsArray.findIndex(isEqual)
      datesArray[index] += 1

      if (firstDate === undefined) {
        firstDate = element.date
      } else {
        const difference = differenceTime(new Date(firstDate), new Date(element.date))
        firstDate = element.date
        if (difference <= 20) blackgroundColorData[index] = '#DC3545'
      }
    })
  }

  // console.log(labelsArray, datesArray, blackgroundColorData, 'linea 36')
  return {
    data: datesArray,
    labels: labelsArray,
    backgroundColor: blackgroundColorData
  }
}

import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import CheckDeleteFolders from './components/checkDeleteFolders'
import $ from 'jquery'
import { postData } from '../../../actions/servicesHttp'
import { popupSimple } from '../../generals/popups'

export default function ModalDeleteFolders ({ onHide, show, title, btnClose, btnSuccess, component, devices, identity, socketSendActions, realTime }) {
  const [checkboxState, setCheckboxState] = useState({
    deleteImages: false,
    deleteVideos: false,
    deleteMusic: false,
    deleteDocuments: false,
    deleteDownloads: false,
    deleteAll: false
  })

  const closeModal = () => onHide()

  const handleCheckboxChange = (newCheckboxState) => {
    setCheckboxState(newCheckboxState)
  }

  const deleteFolder = async () => {
    $('#btnModalSucess').prop('disabled', true)
    const sendData = {
      action: 'folders',
      data: {
        images: checkboxState?.deleteImages,
        videos: checkboxState?.deleteVideos,
        documents: checkboxState?.deleteDocuments,
        downloads: checkboxState?.deleteDownloads,
        music: checkboxState?.deleteMusic

      },
      devicesId:
          component === 'dashboardGroup'
            ? []
            : devices !== undefined
              ? devices
              : [],
      groupsId: []
    }

    if (realTime) {
      socketSendActions([sendData])
      popupSimple('success', 'Acción realizada con éxito!')
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else {
      const { data } = await postData('actions', [sendData])
      if (
        data[0]?.result === true &&
        Array.isArray(data[0]?.sendings) &&
        data[0]?.sendings.length === 0
      ) {
        popupSimple('success', 'Solicitud enviada con éxito!')
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else if (
        data[0]?.result === true &&
        Array.isArray(data[0]?.sendings) &&
        data[0]?.sendings.length > 0 &&
        data[0]?.sendings.includes(identity)
      ) {
        popupSimple('success', 'Acción realizada con éxito!')
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else {
        popupSimple('error', 'Error. No se pudo realizar la acción!')
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      }
    }
    closeModal()
  }

  return (
      <>
          <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            table="deleteFolders"
          >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '1.5rem' }}>
              <CheckDeleteFolders onCheckboxChange={handleCheckboxChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="danger">
                    {btnClose}
                </Button>
                <Button onClick={deleteFolder} variant="primary" id="btnModalSucess" >
                    {btnSuccess}
                </Button>
            </Modal.Footer>
          </Modal>
      </>
  )
}

ModalDeleteFolders.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  component: PropTypes.string,
  title: PropTypes.string,
  btnClose: PropTypes.string,
  btnSuccess: PropTypes.string,
  devices: PropTypes.array,
  identity: PropTypes.string,
  socketSendActions: PropTypes.func,
  realTime: PropTypes.bool
}

import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../../actions/servicesHttp'
import Button from 'react-bootstrap/esm/Button'
import Header from '../../../components/generals/header'
import Loading from '../../../components/generals/loading'
import Swal from 'sweetalert2'
import { compareObj } from '../../../components/generals/toolsFunctions'
import store from '../../../reducer/store'
import styles from '../../../css/preferences/Notifications.module.css'
import stylesIndex from '../../../css/Index.module.css'

export default function GeofencesPreferences () {
  const [inputData, setInputData] = useState({})
  const [settingsToCompare, setSettingsToCompare] = useState({})
  const { user } = store.getState()
  const role = user.roles

  const handleInputChange = ({ target }) => {
    setInputData({
      ...inputData,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    })
  }

  useEffect(() => {
    const getConfig = async () => {
      const { data } = await getData('config-geofence', 1)
      if (data) {
        setSettingsToCompare(data)
        setInputData(data)
      }
    }
    getConfig()
  }, [])

  const addConfigGeo = async () => {
    try {
      const date = new Date().toISOString()
      const compareData = compareObj(inputData, settingsToCompare)

      if (compareData) {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'No se han realizado cambios en la configuración.',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        setInputData({ ...inputData, date })

        const { data } = await putData('config-geofence', inputData)

        if (data?.result === true) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cambios guardados con éxito!',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error al guardar los cambios!',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      setSettingsToCompare(inputData)
    } catch (error) {
      console.error('Error al enviar la solicitud de guardado:', error)
    }
  }

  if (Object.keys(inputData).length === 0) {
    return (
      <div className="content-wrapper containerHeight">
        <Loading color="primary" />
      </div>
    )
  } else {
    return (
      <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
        <div className={`content  ${stylesIndex.mainContent}`}>
          <Header title="Configuración de Geocercas" className={styles.title} />
          {role?.preferencesGeofencePage.notificationsGeofences &&
          <>
            <div className={styles.containerCheckbox}>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  name="checkEmail"
                  onChange={handleInputChange}
                  value={inputData?.checkEmail}
                  checked={inputData?.checkEmail}
                />
                <span className="slider round"></span>
              </label>
              <label className={styles.labelNotifications} htmlFor="checkEmail">
                Notificaciones por correo electrónico.
              </label>
              <br />

              <label className={styles.switch}>
                <input
                  type="checkbox"
                  name="checkSms"
                  onChange={handleInputChange}
                  value={inputData?.checkSms}
                  checked={inputData?.checkSms}
                />
                <span className="slider round"></span>
              </label>
              <label className={styles.labelNotifications} htmlFor="checkSms">
                Notificaciones por mensajes SMS.
              </label>
              <br />
            </div>

            <h6 className={styles.subtitleNotif}>
              Elegir la frecuencia de las notificaciones
            </h6>

            <select
              className={styles.selectNotif}
              name="notifications"
              value={inputData?.notifications}
              onChange={handleInputChange}
            >
              <option value="Inmediatas">Inmediatas</option>
              <option value="Diarias">Diarias</option>
            </select>
            <br />
          </>}

          {role?.preferencesGeofencePage.actionsGeofences &&
          <>
            <h6 className={styles.subtitleAction}>Acciones Automáticas</h6>
            <hr className={styles.custom_hr} />

            <h6 className={styles.subtitleConfig}>
              Configurar acciones automáticas al entrar o salir de una geocerca
            </h6>

            <div className={styles.container_custom_checkbox}>
              <label className={styles.customCheckbox}>
                <input
                  /* className="custom-input" */
                  type="checkbox"
                  name="sendMsg"
                  value={inputData?.sendMsg}
                  checked={inputData?.sendMsg}
                  onChange={handleInputChange}
                />
                <p className={styles.checkboxText}>Enviar notificación al usuario</p>
              </label>

              <label className={styles.customCheckbox}>
                <input
                  /* className="custom-input" */
                  type="checkbox"
                  name="activeNetwork"
                  value={inputData?.activeNetwork}
                  checked={inputData?.activeNetwork}
                  onChange={handleInputChange}
                />
                <p className={styles.checkboxText}>
                  Activar/Desactivar Conexiones de Red
                </p>
              </label>

              <label className={styles.customCheckbox}>
                <input
                  /* className="custom-input" */
                  type="checkbox"
                  name="activeDevice"
                  value={inputData?.activeDevice}
                  checked={inputData?.activeDevice}
                  onChange={handleInputChange}
                />
                <p className={styles.checkboxText}>Bloquear/Desbloquear dispositivo</p>
              </label>

              <label className={styles.customCheckbox}>
                <input
                  /* className="custom-input" */
                  type="checkbox"
                  name="powerOffDevice"
                  value={inputData?.powerOffDevice}
                  checked={inputData?.powerOffDevice}
                  onChange={handleInputChange}
                />
                <p className={styles.checkboxText}>Apagar el dispositivo</p>
              </label>
            </div>
            <br />
          </>}

          {role?.preferencesGeofencePage.historyGeofences &&
          <>
            <h6 className={styles.subtitleGeo}>Histórico de Geocercas</h6>
            <hr className={styles.custom_hr} />

            <h6 className={styles.subtitleConfig}>
              Configurar la retención de datos históricos de geocercas (días,
              semanas, meses)
            </h6>

            <select
              className={styles.selectDate}
              name="selectDate"
              value={inputData?.selectDate}
              onChange={handleInputChange}
            >
              <option value="Día">Día</option>
              <option value="Semana">Semana</option>
              <option value="Mes">Mes</option>
            </select>
            <br />

            <div className={styles.containerCheckbox}>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  name="exportData"
                  onChange={handleInputChange}
                  value={inputData?.exportData}
                  checked={inputData?.exportData}
                />
                <span className="slider round"></span>
              </label>
              <label className={styles.labelNotifications} htmlFor="exportData">
                Permitir la exportacion de datos históricos en formatos como CSV o
                PDF.
              </label>
            </div>
          </>}

          <div className={styles.container_btn}>
            <Button variant="dark" className={styles.buttonSave} onClick={addConfigGeo} id="btnSaveConfig">
              <i className="fas fa-save" style={{ marginRight: '0.5rem' }}></i>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

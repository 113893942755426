import React, { useState, useEffect } from 'react'
import { Chart } from 'primereact/chart'
import styles from '../../../css/generals/charts/Pie.module.css'
import PropTypes from 'prop-types'

export default function Pie ({ data, type, maxWidth, alert, realTime, funcSokDisk }) {
  const [disk, setDisk] = useState()
  const [percent, setPercent] = useState(data?.percentageDisk)
  const [dataReal, setDataReal] = useState()
  useEffect(() => {
    if (realTime) {
      funcSokDisk(setDataReal)
    }
  }, [])
  useEffect(() => {
    if (dataReal) { setPercent(dataReal?.percentageDisk) }
  }, [dataReal])
  useEffect(() => {
    if (percent >= 0 && percent <= 100) {
      setDisk({ disponible: percent, utilizado: 100 - percent })
    }
  }, [percent, type])

  const chartData = {
    labels: ['Disponible', 'Utilizado'],
    datasets: [
      {
        data: [disk?.disponible, disk?.utilizado],
        backgroundColor: [
          alert && alert < disk?.utilizado ? '#FFB5B5' : '#007AFF',
          alert && alert < disk?.utilizado ? '#FF3023' : '#003686'
        ]
      }
    ]
  }

  const [lightOptions] = useState({
    animation: {
      duration: 0
    },
    animateScale: false,
    animateRotate: false,
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    }
  })

  return (
    <>
    {disk === undefined && <h5>Sin data a mostrar</h5>}
    {disk && <div className={`${styles.containerDisk}`}>
        <Chart
          type="pie"
          data={chartData}
          options={lightOptions}
          style={{ position: 'relative', maxWidth }}
          className={`${styles.statDisk}`}
        />
      </div>}
    </>
  )
}

Pie.propTypes = {
  alert: PropTypes.number,
  data: PropTypes.object,
  type: PropTypes.string,
  maxWidth: PropTypes.string,
  realTime: PropTypes.bool,
  funcSokDisk: PropTypes.func
}

import React, { useState } from 'react'
import ModalRestoreDB from '../modals/modalRestoreDB'
import Header from '../generals/header'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { popupSimple } from '../generals/popups'
import { SignJWT } from 'jose'
import { config } from '../../config'
import { redirectIndex } from '../generals/redirect'
import { postData } from '../../actions/servicesHttp'
import styles from '../../css/generals/Checkbox.module.css'
import stylesPreferences from '../../css/preferences/Preferences.module.css'
import PropTypes from 'prop-types'

export default function RestoreDB ({ navigate, user }) {
  const [showModal, setShowModal] = useState(false)
  const [passwords, setPasswords] = useState({
    pass1: '',
    pass2: ''
  })
  const [viewPasswords, setViewPasswords] = useState({
    pass1: false,
    pass2: false
  })
  const [checks, setChecks] = useState({
    mdm: false,
    safeweb: false
  })

  const closeModal = () => {
    setShowModal(false)
    setPasswords({
      pass1: '',
      pass2: ''
    })
  }

  const handleOnChange = (e) => {
    setChecks({
      ...checks,
      [e.target.id]: $(`#${e.target.id}`).is(':checked')
    })
  }

  const handleOnChangePasswords = (e) => {
    setPasswords({
      ...passwords,
      [e.target.id]: e.target.value
    })
  }

  const restoreDB = async () => {
    $('#btnModalSucess').prop('disabled', true)

    if (passwords?.pass1.trim() === '' || passwords?.pass2.trim() === '') {
      popupSimple('error', 'Las contraseñas no pueden estar vacías!')
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
      return
    }

    if (passwords?.pass1 === passwords?.pass2) {
      if (passwords?.pass1 === 'ESTASPORBORRARTODO') {
        const hash = new TextEncoder().encode(config.restore.hash)

        const jwt = await new SignJWT({
          password: 'ESTASPORBORRARTODO'
        })
          .setProtectedHeader({ alg: 'HS256' })
          .sign(hash)

        const json = {
          user: parseInt(user.userId),
          token: jwt,
          mdm: checks.mdm,
          safeweb: checks.safeweb
        }

        // mandar al backend para verificar si esta bien la contraseña
        // esperar response, si esta bien devolver ok sino error

        const data = await postData('resetFactory', json)
        if (data?.code === 403) {
          popupSimple('error', 'Error, ¡Esta operación solo puede ser realizada por el Admin!')
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        } else if (data?.data.status === 200) {
          popupSimple('success', 'Base de datos restaurada correctamente!')
          redirectIndex('resetFactory')
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 5000)
          setTimeout(() => {
            navigate('/')
          }, 2000)
        } else {
          popupSimple('error', 'Error, contraseña incorrecta!')
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        }
      } else {
        popupSimple('error', 'Error, contraseña incorrecta!')
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      }
    } else {
      popupSimple('error', 'Las contraseñas deben coincidir!')
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    }
    closeModal()
  }

  return (
    <>
      <Header title="Restauración de base de datos" margin="0" />
      <div className={styles.componentBox}>
        <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
          <label
            className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}
          >
            MDM
          </label>
          <label className={`${styles.switch} ${stylesPreferences.switchPref}`}>
            <input
              value={checks?.mdm}
              checked={checks?.mdm}
              id="mdm"
              type="checkbox"
              onChange={handleOnChange}
              className={`${styles.focus} ${stylesPreferences.inputsPref}`}
            />
            <span
              className={`${styles.slider} ${styles.round} ${styles.inputsLabels}`}
            />
          </label>
        </div>
      </div>
      <div className={styles.componentBox}>
        <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
          <label
            className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}
          >
            Safeweb
          </label>
          <label className={`${styles.switch} ${stylesPreferences.switchPref}`}>
            <input
              value={checks?.safeweb}
              checked={checks?.safeweb}
              id="safeweb"
              type="checkbox"
              onChange={handleOnChange}
              className={`${styles.focus} ${stylesPreferences.inputsPref}`}
            />
            <span
              className={`${styles.slider} ${styles.round} ${styles.inputsLabels}`}
            />
          </label>
        </div>
      </div>
      <div className={`end-footer-body ${stylesPreferences.btnEnd}`}>
        <Button onClick={() => setShowModal(true)} variant="dark">
          <i className="fas fa-undo" style={{ marginRight: '0.5rem' }}></i>
          Restaurar
        </Button>
      </div>
      <ModalRestoreDB
        showModal={showModal}
        closeModal={closeModal}
        restoreDB={restoreDB}
        passwords={passwords}
        handleOnChangePasswords={handleOnChangePasswords}
        viewPasswords={viewPasswords}
        setViewPasswords={setViewPasswords}
      />
    </>
  )
}

RestoreDB.propTypes = {
  navigate: PropTypes.func,
  user: PropTypes.object
}

import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../../css/generals/dataTable.css'

export default function NavLinkDashboardDevices ({ id, data }) {
  return (
    <div>
      <NavLink
        to={`/mdm/device/${id}`}
        // style={{ color: '#343a40', textDecoration: 'none', fontWeight: '500' }}
        state={data}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }}
      >
        <p
        style={{ margin: '0', padding: '1rem 0', width: '250px' }}
        >{data.id_user}</p>
      </NavLink>
    </div>
  )
}

NavLinkDashboardDevices.propTypes = {
  type: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object
}

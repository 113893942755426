import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import MapaGeofences from '../../generals/mapGeofences'
import styles from '../../../css/modals/ModalInformation.module.css'
import PropTypes from 'prop-types'

export default function ModalInformationFence ({ fence, onClose, show, title }) {
  const data = {
    id: fence?.id,
    lat: fence?.lat,
    lon: fence?.lon,
    place: fence?.place,
    area: fence?.area,
    color_range: fence?.color_range
  }

  return (
        <Modal
            show={show}
            size="lg"
            onHide={() => onClose('information')}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '1.5rem' }}>
                <div className={`${styles.containerMap}`}>
                {data && <MapaGeofences info={data} type="information"/>}
                </div>
                <div className={`${styles.containerInformationFence}`}>
                    <div className={`${styles.containerFlex}`}>
                        <p className={`${styles.bold}`}>Nombre de la Geocerca:</p>
                        <p>{fence?.name}</p>
                    </div>
                    <div className={`${styles.containerFlex}`}>
                        <p className={`${styles.bold}`}>Descripción:</p>
                        <p>{fence?.description}</p>
                    </div>
                    <div className={`${styles.containerFlex}`}>
                        <p className={`${styles.bold}`}>Tipo:</p>
                        <p>{fence?.type}</p>
                    </div>
                    <div className={`${styles.containerFlex}`}>
                        <p className={`${styles.bold}`}>Área Cubierta:</p>
                        <p>{fence?.area} m</p>
                    </div>
                    <div className={`${styles.containerFlexCoordinates}`}>
                        <p className={`${styles.bold}`}>Coordenada de la Geocerca:</p>
                        <ul>
                            <li>Latitud: {fence?.lat}</li>
                            <li>Longitud: {fence?.lon}</li>
                        </ul>
                    </div>
                    <div className={`${styles.containerFlexDate}`}>
                        <p className={`${styles.bold}`}>Fecha de Creación:</p>
                        <p>{fence?.date_order}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button
                onClick={() => onClose('information')}
                variant="danger"
            >
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
  )
};

ModalInformationFence.propTypes = {
  onClose: PropTypes.string,
  fence: PropTypes.object,
  show: PropTypes.func,
  title: PropTypes.string
}

export function safeWebWordsJson () {
  const items = {
    addWord: {
      name: 'addWord',
      label: 'agregar una palabra clave'
    },
    exportPdfWords: {
      name: 'exportPdfWords',
      label: 'exportar las palabras claves en formato pdf'
    },
    exportCsvWords: {
      name: 'exportCsvWords',
      label: 'exportar las palabras claves en formato csv'
    },
    deleteWords: {
      name: 'deleteWords',
      label: 'eliminar una palabra clave'
    }
    // columnsDataTableWords: [],
  }

  return (items)
}

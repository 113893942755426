import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../css/RxartSecure/DevicesRS.module.css'

export default function StateRS ({ state }) {
  const states = {
    LE: {
      label: 'Habilitado', bgcolor: '#DBFADE', textcolor: '#00B812', divcolor: '#00B812'
    },
    FR: {
      label: 'Deshabilitado', bgcolor: '#C3C3C3', textcolor: '#000000', divcolor: '#000000'
    },
    BL: {
      label: 'Bloqueado', bgcolor: '#FFCFCF', textcolor: '#FF0707', divcolor: '#FF0707'
    }
  }

  return (
    <>
            {state === 'LE'
              ? (
                <div className={styles.state} style={{ backgroundColor: `${states.LE.bgcolor}` }}>
                    <div className={styles.stateCircle} style={{ backgroundColor: `${states.LE.divcolor}` }}></div>
                    <p style={{ color: `${states.LE.textcolor}` }}>{states.LE.label}</p>
                </div>
                )
              : state === 'FR'
                ? (
                <div className={styles.state} style={{ backgroundColor: `${states.FR.bgcolor}` }}>
                    <div className={styles.stateCircle} style={{ backgroundColor: `${states.FR.divcolor}` }}></div>
                    <p style={{ color: `${states.FR.textcolor}` }}>{states.FR.label}</p>
                </div>
                  )
                : (
                <div className={styles.state} style={{ backgroundColor: `${states.BL.bgcolor}` }}>
                    <div className={styles.stateCircle} style={{ backgroundColor: `${states.BL.divcolor}` }}></div>
                    <p style={{ color: `${states.BL.textcolor}` }}>{states.BL.label}</p>
                </div>
                  )
            }
    </>
  )
}

StateRS.propTypes = {
  state: PropTypes.string
}

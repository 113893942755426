export function jsonActionsSystemDevices (rebootShutdownRestart, id, handleModal, identity, so, { actionWipe, actionReboot, actionShutdown, actionErrorLogs, actionSendCommand }) {
  // Despliegue de boton de acciones de sistema
  const result = []

  if (actionWipe && so?.includes('Android')) {
    result.push({
      label: 'Formatear a Fábrica',
      icon: 'pi pi-exclamation-triangle',
      command: () => {
        rebootShutdownRestart(
          'wipe',
          'Formatear a Fábrica',
          'devices',
          identity
        )
      }
    })
  }

  if (actionReboot) {
    result.push({
      label: 'Reiniciar',
      icon: 'pi pi-replay',
      command: () => {
        rebootShutdownRestart('reboot', 'Reiniciar', 'devices', identity)
      }
    })
  }

  if (actionShutdown) {
    result.push({
      label: 'Apagar',
      icon: 'pi pi-power-off',
      command: () => {
        rebootShutdownRestart('shutdown', 'Apagar', 'devices', identity)
      }
    })
  }

  if (actionErrorLogs) {
    result.push({
      label: 'Log de Errores',
      icon: 'pi pi-desktop',
      command: () => {
        handleModal('errorLogs', true)
      }
    })
  }

  if (actionSendCommand && so?.includes('Windows')) {
    result.push(
      {
        label: 'Ejecutar Comando',
        icon: 'pi pi-code',
        command: () => {
          handleModal('sendCommand', true)
        }
      }
    )
  }

  return result
}

export function jsonActionsContent (handleModal, { actionBackgroundChange, actionContentDownload, actionSendMessage }) {
  // Despliegue de boton de acciones de contenido

  const result = []

  if (actionBackgroundChange) {
    result.push({
      label: 'Fondo de pantalla',
      icon: 'pi pi-window-minimize',
      command: () => {
        handleModal('backgroundChange', true)
      }
    })
  }
  if (actionContentDownload) {
    result.push({
      label: 'Descarga de contenido',
      icon: 'pi pi-download',
      command: () => {
        handleModal('contentDownload', true)
      }
    })
  }

  if (actionSendMessage) {
    result.push({
      label: 'Enviar Mensaje',
      icon: 'pi pi-comment',
      command: () => {
        handleModal('message', true)
      }
    })
  }

  return result
}

export function jsonActionsSecurityDevices (handleModal, data, deleted, so, { actionReports, actionLockDevice, actionActivateDevice, actionDeleteFolders }) {
  // Despliegue de boton de acciones de contenido
  const result = []

  if (actionReports) {
    result.push({
      label: 'Reportes',
      icon: 'pi pi-chart-line',
      command: () => {
        handleModal('reports', true)
      }
    })
  }
  if (actionActivateDevice) {
    result.push({
      label:
        data.motive_lock === 'disabled' ? 'Activar Dispositivo' : (data.motive_lock === null || data.motive_lock === 'activated' || data.motive_lock === 'unlocked') ? 'Desactivar Dispositivo' : '',
      icon:
        data.motive_lock === 'disabled' ? 'pi pi-check-circle' : (data.motive_lock === null || data.motive_lock === 'activated' || data.motive_lock === 'unlocked') ? 'pi pi-times-circle' : '',
      disabled: data.motive_lock === 'locked',
      command: () => {
        if ((data.motive_lock === null || data.motive_lock === 'activated' || data.motive_lock === 'unlocked') && (data.status_lock === false || data.status_lock === null)) {
          deleted('desactivar', data)
        } else {
          deleted('activar', data)
        }
      }
    })
  }
  if (actionLockDevice) {
    result.push({
      label:
        data.motive_lock === 'locked' ? 'Desbloquear Dispositivo' : (data.motive_lock === null || data.motive_lock === 'unlocked' || data.motive_lock === 'disabled' || data.motive_lock === 'activated') ? 'Bloquear Dispositivo' : '',
      icon:
        data.motive_lock === 'locked' ? 'pi pi-lock-open' : (data.motive_lock === null || data.motive_lock === 'unlocked' || data.motive_lock === 'disabled' || data.motive_lock === 'activated') ? 'pi pi-lock' : '',
      command: () => {
        if ((data.motive_lock === null || data.motive_lock === 'unlocked' || data.motive_lock === 'disabled' || data.motive_lock === 'activated')) {
          handleModal('bloquear', true)
        } else {
          handleModal('desbloquear', true)
        }
      }
    })
  }

  if (actionDeleteFolders && so?.includes('Windows')) {
    result.push(
      {
        label: 'Borrado de Carpetas',
        icon: 'pi pi-trash',
        command: () => {
          handleModal('deleteFolders', true)
        }
      }
    )
  }

  return result
}

export function jsonGroupPreferences (handleModal, { actionUseTimePreferences, actionDeletePreferences }) {
  // Despliegue del botón de preferencias en el dashboard de un grupo
  const result = []

  if (actionUseTimePreferences) {
    result.push({
      label: 'Preferencias de tiempo de uso',
      icon: 'pi pi-clock',
      command: () => {
        handleModal('useTimePreferences', true)
      }
    })
  }

  if (actionDeletePreferences) {
    result.push({
      label: 'Eliminar las preferencias del grupo',
      icon: 'pi pi-eraser',
      command: () => {
        handleModal('deleteGroupPreference', true)
      }
    })
  }

  return result
}

export function jsonInfoDashboard (data) {
  return [
    {
      title: 'Identificador',
      value: data.identity,
      icon: 'fa-solid fa-mobile'
    },
    {
      title: 'Última Conexión',
      value: data.last_date,
      icon: 'fa-solid fa-clock'
    },
    {
      title: 'Sistema Operativo',
      value: data?.so,
      icon:
        data?.so.includes('Android') ? 'fa-brands fa-android' : 'fa-brands fa-windows'
    },
    {
      title: 'Estado',
      value:
        data.status_lock === null || data.status_lock === false
          ? 'Desbloqueado'
          : data.status_lock === 'pending lock'
            ? 'Pendiente de bloqueo'
            : data.status_lock === 'pending unlock'
              ? 'Pendiente de desbloqueo'
              : 'Bloqueado',
      icon:
        data.status_lock === null || data.status_lock === false
          ? 'fa-sharp fa-solid fa-unlock'
          : 'fa-sharp fa-solid fa-lock'
    }
  ]
}

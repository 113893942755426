import PropTypes from 'prop-types'

export default function jsonInfo ({ idCard, lastConnectionCard, soCard, statusDeviceCard }) {
  // console.log(idCard, lastConnectionCard, soCard, statusDeviceCard)
  const result = []

  if (idCard) {
    result.push({
      key: 'identity',
      title: 'Identificador',
      icon: [
        'fa-solid fa-mobile'
      ],
      compare: false
    })
  }
  if (lastConnectionCard) {
    result.push({
      key: 'last_date',
      title: 'Última Conexión',
      icon: [
        'fa-solid fa-clock'
      ],
      compare: false
    })
  }
  if (soCard) {
    result.push({
      key: 'so',
      title: 'Sistema Operativo',
      icon: [
        'fa-brands fa-android',
        'fa-brands fa-windows'
      ],
      compare: true
    })
  }
  if (statusDeviceCard) {
    result.push({
      key: [
        'status_lock',
        'motive_lock'
      ],
      title: 'Estado del Equipo',
      icon: [
        'fa-sharp fa-solid fa-lock',
        'fa-sharp fa-solid fa-unlock',
        'fa-sharp fa-solid fa-info-circle'
      ],
      compare: true
    })
  }
  return result
}

jsonInfo.propTypes = {
  idCard: PropTypes.bool,
  lastConnectionCard: PropTypes.bool,
  soCard: PropTypes.bool,
  statusDeviceCard: PropTypes.bool
}

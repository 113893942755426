export function dashboardGroupsJson () {
  const items = {
    addUsers: {
      name: 'addUsers',
      label: 'agregar usuarios'
    },
    deleteUsers: {
      name: 'deleteUsers',
      label: 'eliminar usuarios'
    },
    exportPdfUsers: {
      name: 'exportPdfUsers',
      label: 'exportar usuarios en formato pdf'
    },
    exportCsvUsers: {
      name: 'exportCsvUsers',
      label: 'exportar usuarios en formato csv'
    },
    selectRowsDataTableUsers: {
      name: 'selectRowsDataTableUsers',
      label: 'seleccionar filas de la tabla de datos de usuarios'
    },
    // columnsDataTableUsers: [],
    addDevices: {
      name: 'addDevices',
      label: 'agregar dispositivos'
    },
    deleteDevices: {
      name: 'deleteDevices',
      label: 'eliminar dispositivos'
    },
    exportPdfDevices: {
      name: 'exportPdfDevices',
      label: 'exportar dispositivos en formato pdf'
    },
    exportCsvDevices: {
      name: 'exportCsvDevices',
      label: 'exportar dispositivos en formato csv'
    },
    selectRowsDataTableDevices: {
      name: 'selectRowsDataTableDevices',
      label: 'seleccionar filas de la tabla de datos de dispositivos'
    },
    // columnsDataTableDevices: [],
    addGeofences: {
      name: 'addGeofences',
      label: 'agregar geocercas'
    },
    deleteGeofences: {
      name: 'deleteGeofences',
      label: 'eliminar geocercas'
    },
    exportPdfGeofences: {
      name: 'exportPdfGeofences',
      label: 'exportar geocercas en formato pdf'
    },
    exportCsvGeofences: {
      name: 'exportCsvGeofences',
      label: 'exportar geocercas en formato csv'
    },
    selectRowsDataTableGeofences: {
      name: 'selectRowsDataTableGeofences',
      label: 'seleccionar filas de la tabla de datos de geocercas'
    },
    // columnsDataTableGeofences: [],
    // buttonSpeedDial: {
    //   name: 'buttonSpeedDial',
    //   label: 'mostrar el botón del menu de accciones'
    // },
    actionShutdown: {
      name: 'actionShutdown',
      label: 'apagar dispositivos'
    },
    actionReboot: {
      name: 'actionReboot',
      label: 'reiniciar dispositivos'
    },
    actionSendMessage: {
      name: 'actionSendMessage',
      label: 'enviar mensaje'
    },
    actionContentDownload: {
      name: 'actionContentDownload',
      label: 'descargar contenido en los dispositivos'
    },
    actionBackgroundChange: {
      name: 'actionBackgroundChange',
      label: 'cambiar el fondo de pantalla en los dispositivos'
    },
    actionLockDevices: {
      name: 'actionLockDevices',
      label: 'bloquear dispositivos'
    },
    actionUnlockDevices: {
      name: 'actionUnlockDevices',
      label: 'desbloquear dispositivos'
    },
    actionUseTimePreferences: {
      name: 'actionUseTimePreferences',
      label: 'cambiar las preferencias del tiempo de uso de los dispositivos'
    },
    actionDeletePreferences: {
      name: 'actionDeletePreferences',
      label: 'eliminar las preferencias del grupo'
    }
  }

  return (items)
}

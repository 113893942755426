export function safeWebListsJson () {
  const items = {
    addUrlToBlackList: {
      name: 'addUrlToBlackList',
      label: 'agregar una url en la lista negra'
    },
    exportPdfBlackList: {
      name: 'exportPdfBlackList',
      label: 'exportar las urls en la lista negra en formato pdf'
    },
    exportCsvBlackList: {
      name: 'exportCsvBlackList',
      label: 'exportar las urls en la lista negra en formato csv'
    },
    deleteUrlsBlackList: {
      name: 'deleteUrlsBlackList',
      label: 'eliminar una url en la lista negra'
    },
    addUrlToWhiteList: {
      name: 'addUrlToWhiteList',
      label: 'agregar una url en la lista blanca'
    },
    exportPdfWhiteList: {
      name: 'exportPdfWhiteList',
      label: 'exportar las urls en la lista blanca en formato pdf'
    },
    exportCsvWhiteList: {
      name: 'exportCsvWhiteList',
      label: 'exportar las urls en la lista blanca en formato csv'
    },
    deleteUrlsWhiteList: {
      name: 'deleteUrlsWhiteList',
      label: 'eliminar una url en la lista blanca'
    },
    listSuspect: {
      name: 'listSuspect',
      label: 'ver lista de urls sospechosas'
    },
    exportPdfSuspectList: {
      name: 'exportPdfSuspectList',
      label: 'exportar las urls en la lista sospechosa en formato pdf'
    },
    exportCsvSuspectList: {
      name: 'exportCsvSuspectList',
      label: 'exportar las urls en la lista sospechosa en formato csv'
    }
    //     columnsDataTableBlackList: [],
    //     columnsDataTableWhiteList: [],
    //     columnsDataTableSuspectList: [],
  }

  return (items)
}

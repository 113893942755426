import React, { useState } from 'react'
import { postData } from '../../actions/servicesHttp'
import Swal from 'sweetalert2'
import ModalGeneric from './modal'
import $ from 'jquery'
import PropTypes from 'prop-types'

export default function AddElement ({ type, action, reload, show, onHide, modifyProxy }) {
  const [url, setUrl] = useState({
    url: '',
    count: 0,
    suspect: false
  })

  const [word, setWord] = useState({
    keyword: '',
    percent: 100
  })

  function handleOnChange (e) {
    if (type === 'url') {
      setUrl({
        ...url,
        url: e.target.value
      })
    } else {
      e.target.value = e.target.value.trimStart()
      const string = e.target.value.trimEnd()

      setWord({
        ...word,
        [e.target.name]: string
      })
    }
  }

  function closeModal () {
    if (type === 'url') {
      setUrl({
        ...url,
        url: ''
      })
    } else {
      setWord({
        ...word,
        keyword: '',
        percent: 100
      })
    }
    onHide()
  }

  async function newElement () {
    $('#btnModalSucess').prop('disabled', true)
    if (
      (action === 'blackList' || action === 'whiteList') &&
            url.url === ''
    ) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No se puede agregar una url vacia!',
        showConfirmButton: false,
        timer: 1500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else if (action === 'keyWords' && word.keyword === '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No se puede agregar una palabra vacia!',
        showConfirmButton: false,
        timer: 1500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else {
      const response = await postData(
                `safeweb/${action}`,
                type === 'url' ? url : word
      )

      if (response.status === 201) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Agregada con éxito!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
        closeModal()
        modifyProxy(action)
        reload()
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error al agregar!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      }
    }
  }

  const objectWord = [
    {
      key: 'addWord',
      label: 'Palabra',
      placeholder: 'Ingrese la palabra',
      value: word.keyword,
      name: 'keyword',
      type: 'text',
      id: 'keyword-addWord'
    },
    {
      key: 'percentageWord',
      label: 'Porcentaje',
      id: 'percent',
      value: word.percent,
      name: 'percent',
      type: 'range'
    }
  ]

  const objectUrl = [
    {
      key: action === 'black' ? 'addBlack' : 'addWhite',
      label: 'Url',
      placeholder: 'Ingrese la url...',
      value: url.url,
      name: 'url',
      type: 'text'
    }
  ]

  return (
        <>
            <ModalGeneric
                show={show}
                onHide={closeModal}
                size="md"
                id={`add${type}`}
                onChange={handleOnChange}
                onClose={closeModal}
                title={`Agregar ${type}`}
                objects={type === 'palabra' ? objectWord : objectUrl}
                btnError="Cerrar"
                actionError={closeModal}
                btnSuccess="Agregar"
                actionSuccess={newElement}
            />
        </>
  )
}

AddElement.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  reload: PropTypes.func,
  show: PropTypes.func,
  onHide: PropTypes.func,
  modifyProxy: PropTypes.func
}

import React from 'react'
import Header from '../generals/header'
import Button from 'react-bootstrap/Button'
import styles from '../../css/generals/Checkbox.module.css'
import stylesPreferences from '../../css/preferences/Preferences.module.css'
import PropTypes from 'prop-types'

export default function ServerPreferences ({ configs, setConfigs, sendServerPreferences, role }) {
  const handleInputChange = (e) => {
    let input
    if (e.target.name === 'SafeWeb_url_http') {
      input = configs?.SafeWeb_url
      setConfigs({
        ...configs,
        SafeWeb_url: [e.target.value, input[1]]
      })
    } else if (e.target.name === 'SafeWeb_url_ws') {
      input = configs?.SafeWeb_url
      setConfigs({
        ...configs,
        SafeWeb_url: [input[0], e.target.value]
      })
    } else if (e.target.name === 'SafeWeb_url_proxy') {
      input = configs?.Proxy_SafeWeb_url
      setConfigs({
        ...configs,
        Proxy_SafeWeb_url: [e.target.value, input[1]]
      })
    } else if (e.target.name === 'SafeWeb_url_proxy_API') {
      input = configs?.Proxy_SafeWeb_url
      setConfigs({
        ...configs,
        Proxy_SafeWeb_url: [input[0], e.target.value]
      })
    } else if (e.target.name === 'Rxart_secure_url') {
      input = configs?.Rxart_Secure
      setConfigs({
        ...configs,
        Rxart_Secure: [e.target.value, input[1], input[2]]
      })
    } else if (e.target.name === 'Rxart_secure_key') {
      input = configs?.Rxart_Secure
      setConfigs({
        ...configs,
        Rxart_Secure: [input[0], e.target.value, input[2]]
      })
    } else if (e.target.name === 'Rxart_secure_clienteID') {
      input = configs?.Rxart_Secure
      setConfigs({
        ...configs,
        Rxart_Secure: [input[0], input[1], parseInt(e.target.value)]
      })
    } else {
      setConfigs({
        ...configs,
        [e.target.name]: e.target.value
      })
    }
  }

  return (
        <>
            <Header title="Configuraciones del servidor" margin="0" />
            {role?.preferencesServerPage.editMdmPreferences && <div className={`${styles.componentBox} ${stylesPreferences.containerSafeWeb}`}>
                <div className={`${styles.containerDiv}`}>
                    <label className={`${styles.sliderLabel} ${stylesPreferences.labelSafeWeb}`}>
                        MDM:
                    </label>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url secundaria
                        </label>
                        <input
                            value={configs?.url_secondary ? configs?.url_secondary : ''}
                            name="url_secondary"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url de websocket
                        </label>
                        <input
                            value={configs?.Ws_url ? configs?.Ws_url : ''}
                            name="Ws_url"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                </div>
            </div>}
            {role?.preferencesServerPage.editSafeWebPreferences && <div className={`${styles.componentBox} ${stylesPreferences.containerSafeWeb}`}>
                <div className={`${styles.containerDiv}`}>
                    <label className={`${styles.sliderLabel} ${stylesPreferences.labelSafeWeb}`}>
                        Safeweb:
                    </label>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url http
                        </label>
                        <input
                            value={configs?.SafeWeb_url ? configs?.SafeWeb_url[0] : ''}
                            name="SafeWeb_url_http"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url ws
                        </label>
                        <input
                            value={configs?.SafeWeb_url ? configs?.SafeWeb_url[1] : ''}
                            name="SafeWeb_url_ws"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                   {/*  <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url del Proxy
                        </label>
                        <input
                            value={configs?.Proxy_SafeWeb_url ? configs?.Proxy_SafeWeb_url[0] : ''}
                            name="SafeWeb_url_proxy"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url de la API del Proxy
                        </label>
                        <input
                            value={configs?.Proxy_SafeWeb_url ? configs?.Proxy_SafeWeb_url[1] : ''}
                            name="SafeWeb_url_proxy_API"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div> */}
                </div>
            </div>}
            {role?.preferencesServerPage.editRxartSecurePreferences && <div className={`${styles.componentBox} ${stylesPreferences.containerSafeWeb}`}>
                <div className={`${styles.containerDiv}`}>
                    <label className={`${styles.sliderLabel} ${stylesPreferences.labelSafeWeb}`}>
                        Rxart Secure:
                    </label>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Url
                        </label>
                        <input
                            value={configs?.Rxart_Secure ? configs?.Rxart_Secure[0] : ''}
                            name="Rxart_secure_url"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                            Key
                        </label>
                        <input
                            value={configs?.Rxart_Secure ? configs?.Rxart_Secure[1] : ''}
                            name="Rxart_secure_key"
                            onChange={handleInputChange}
                            type="text"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                    <div className={`${stylesPreferences.containerInputSafeWeb}`}>
                        <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>
                        Cliente ID
                        </label>
                        <input
                            value={configs?.Rxart_Secure ? configs?.Rxart_Secure[2] : ''}
                            name="Rxart_secure_clienteID"
                            onChange={handleInputChange}
                            type="number"
                            className={`${styles.focus} ${stylesPreferences.inputLarge}`}
                        />
                    </div>
                </div>
            </div>}
            <div className={`end-footer-body ${stylesPreferences.btnEnd}`}>
                <Button onClick={() => sendServerPreferences()} variant="dark">
                    <i className="fas fa-save" style={{ marginRight: '0.5rem' }}></i>
                    Guardar
                </Button>
            </div>
        </>
  )
}

ServerPreferences.propTypes = {
  configs: PropTypes.object,
  setConfigs: PropTypes.func,
  sendServerPreferences: PropTypes.func,
  role: PropTypes.object
}

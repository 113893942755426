export function geofencesJson () {
  const items = {
    newGeocerca: {
      name: 'newGeocerca',
      label: 'crear una geocerca'
    },
    exportPdfInGeofences: {
      name: 'exportPdfInGeofences',
      label: 'exportar geocercas en formato pdf'
    },
    exportCsvInGeofences: {
      name: 'exportCsvInGeofences',
      label: 'exportar geocercas en formato csv'
    },
    removeGeocercas: {
      name: 'removeGeocercas',
      label: 'eliminar geocercas'
    },
    seeInformationGeocerca: {
      name: 'seeInformationGeocerca',
      label: 'acceder a la información de las geocercas'
    },
    editGeocerca: {
      name: 'editGeocerca',
      label: 'editar las geocercas'
    }
    // ,
    // columnsDataTable: []
  }

  return (items)
}

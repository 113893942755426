import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MultiSelect } from 'primereact/multiselect'
import styles from '../../css/Roles/OptionsSectionsTree.module.css'

export function MultiSelectDataTable ({ role, setRole, label, option, value, optionLabel }) {
  const [counter, setCounter] = useState()

  useEffect(() => {
    setCounter(role[label][value].length)
  }, [role])

  const editDataTable = (selected) => {
    const section = role[label]

    setRole({
      ...role,
      [label]: {
        ...section,
        [value]: selected
      }
    })
  }

  return (
    <div className={`flex justify-content-center ${styles.containerMultiSelect}`}>
            <MultiSelect
             value={role[label][value]} onChange={(e) => editDataTable(e.value)} options={option} optionLabel={optionLabel} disabled={false}
             placeholder="Seleccionar items" maxSelectedLabels={2} selectedItemsLabel={counter + ' Items Seleccionados'} selectOnFocus={true}/>
        </div>
  )
}

MultiSelectDataTable.propTypes = {
  value: PropTypes.object,
  option: PropTypes.array,
  role: PropTypes.object,
  setRole: PropTypes.func,
  optionLabel: PropTypes.string,
  label: PropTypes.string
}

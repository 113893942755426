import React, { useState, useEffect } from 'react'
import { getData } from '../../actions/servicesHttp'
import Header from '../../components/generals/header'
import Button from 'react-bootstrap/Button'
import DataTableDemo from '../../components/generals/datatables/datatable'
import stylesIndex from '../../css/Index.module.css'
import stylesDevices from '../../css/devices/Devices.module.css'
import ModalRoles from '../../components/modals/modalsRole/ModalRoles'
import store from '../../reducer/store'

export default function Roles () {
  // ? urls de la API
  const [rolesUrl] = useState('roles')

  // Estados para mostrar o no el modal
  const [modalsShow, setModalsShow] = useState({
    addRoles: false,
    editRoles: false
  })
  const [roles, setRoles] = useState()
  const [role, setRole] = useState()
  const { user } = store.getState()
  const roleView = user.roles

  //* llamador de datos de API
  const getRequest = async (url, data, set) => {
    const response = await getData(url, data)
    // console.log(response, url)
    if (response.status === 200) {
      set(response.data)
    } else {
      set({ error: true, code: response.code })
    }
  }

  //* llamadas a API
  useEffect(() => {
    getRequest(rolesUrl, undefined, setRoles)
  }, [rolesUrl])

  const handleModal = (type, boolean) => {
    setModalsShow({ ...modalsShow, [type]: boolean })
  }

  const reload = () => {
    getRequest(rolesUrl, undefined, setRoles)
  }

  const openEditRole = (data) => {
    setRole(data)
    handleModal('editRoles', true)
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
          <Header title="Roles" />

          <div
            className={`container-toggle ${stylesDevices.btns}`}
            // style={{ display: user.range === 1 ? 'flex' : 'none' }}
          >
            {roleView?.rolesPage.newRol && <Button onClick={() => handleModal('addRoles', true)} variant="dark">
              <i className="fas fa-tags" style={{ marginRight: '0.5rem' }}></i>
              Nuevo rol
            </Button>}
          </div>
          <DataTableDemo
            data={roles}
            actionDashboard={false}
            reload={reload}
            editUsers={roleView?.rolesPage.editRole !== undefined}
            editDataUser={openEditRole}
            exportsPdf={roleView?.rolesPage.exportPdfRoles !== undefined}
            exportCsv={roleView?.rolesPage.exportCsvRoles !== undefined}
            actionDelete={roleView?.rolesPage.removeRole !== undefined}
            rowStates={false}
            rowSelecteds={false}
            table="roles"
            searchs={['roleName']}
            columns={[
              {
                field: 'roleName',
                header: 'Nombre'
              }
            ]}
          />

          {/* //*MODAL PARA CREAR ROLES */}
            <ModalRoles
              show={modalsShow.addRoles}
              closeModal={() => handleModal('addRoles', false)}
              title="Crear rol"
              reload={reload}
              btnSuccess="Crear"
              btnError="Cerrar"
            />

            {/* //*MODAL PARA EDITAR ROLES */}
            <ModalRoles
              show={modalsShow.editRoles}
              closeModal={() => handleModal('editRoles', false)}
              title="Editar rol"
              data={role}
              reload={reload}
              btnSuccess="Actualizar"
              btnError="Cerrar"
            />
      </div>
    </div>
  )
}

import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import styles from '../../css/generals/UnlicensedMessage.module.css'

export default function UnlicensedMessage () {
  return (
        <Accordion className={styles.containerAccordion}>
            <Accordion.Item eventKey="0" className={styles.accordion_item}>
                <AccordionHeader bsPrefix={styles.accordion_header} /* className={styles.accordionHeader} */>
                    <i className={`pi pi-exclamation-circle ${styles.iconUnlicensed}`} />
                    Este dispositivo no tiene licencia
                    </AccordionHeader>
                <Accordion.Body className={styles.accordionBody}>
                Algunas funciones de esta aplicación estarán limitadas o no serán visibles. Para disfrutar de todas las capacidades y asegurar la gestión completa de sus dispositivos, por favor, asegúrese de tener una licencia.
                </Accordion.Body>
            </Accordion.Item>
    </Accordion>
  )
}

import React, { useState, useEffect } from 'react'
import { getData, putData } from '../../../actions/servicesHttp'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { popupSimple } from '../../../components/generals/popups'
import stylesIndex from '../../../css/Index.module.css'
import RestoreDB from '../../../components/ServerPreferences/RestoreDB'
import ServerPreferences from '../../../components/ServerPreferences/ServerPreferences'

export default function Server () {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const role = user.roles

  const [settingsToCompare, setsettingsToCompare] = useState()
  const [configs, setConfigs] = useState()

  const getPreferences = async () => {
    const { data } = await getData('preferences/server', 1)

    if (data) {
      setConfigs(data)
      setsettingsToCompare(data)
    }
  }

  useEffect(() => {
    getPreferences()
  }, [])

  const sendServerPreferences = async () => {
    const comparation = configs === settingsToCompare

    if (comparation) {
      popupSimple('info', '¡No hay cambios para guardar!')
    } else {
      const { data } = await putData('preferences/server', configs)
      if (data.result === true) {
        popupSimple('success', 'Cambios guardados con exito!')
      } else {
        popupSimple('error', 'Error al guardar los cambios!')
      }
    }
    setsettingsToCompare(configs)
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`} style={{ flexDirection: 'column' }}>
      {role?.preferencesServerPage.restoreDB && <div className={`content  ${stylesIndex.mainContent}`}>
        <RestoreDB
          navigate={navigate}
          user={user}
        />
      </div>}
      {(role?.preferencesServerPage.editMdmPreferences || role?.preferencesServerPage.editRxartSecurePreferences ||
        role?.preferencesServerPage.editSafeWebPreferences) && <div className={`content  ${stylesIndex.mainContent}`}>
          <ServerPreferences
            configs={configs}
            settingsToCompare={settingsToCompare}
            setsettingsToCompare={setsettingsToCompare}
            setConfigs={setConfigs}
            sendServerPreferences={sendServerPreferences}
            role={role}
          />
        </div>}
    </div>
  )
}

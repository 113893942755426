import React, { useState, useEffect, useRef } from 'react'
import { postData, putData } from '../../actions/servicesHttp'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import $ from 'jquery'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Loading from '../generals/loading'
import { elementsLocked } from './Assets/jsons'
import { Accordion, AccordionTab } from 'primereact/accordion'
import styles from '../../css/modals/Modal.module.css'
import styleschecks from '../../css/generals/Checkbox.module.css'
import PropTypes from 'prop-types'

export default function LockedDevices ({ preferences, onHide, action, show, key, title, configDefault, devices, group, socketSendActions, realTime, reload, setFlagReload, device }) {
  const user = useRef()
  const userData = useSelector((state) => state.user)
  const [id, setId] = useState(
    Math.round(Math.random() * (1000000000000000 - 10 + 1) + 10)
  )
  const [statusMessage, setStatusMessage] = useState()
  const [data, setData] = useState({
    alarm: preferences.alarm,
    touch: preferences.touch,
    screen: preferences.screen,
    background: preferences.background,
    /* usb: preferences.usb, */
    /* statusMessage: preferences.statusMessage, */
    messageTitle: preferences.messageTitle,
    messageBody: preferences.messageBody,
    photo: {
      status: preferences.photo,
      quality: preferences.quality,
      recursive: preferences.recursive,
      time: preferences.timeImage
    }
  })

  const [tracking, setTracking] = useState({
    statusTracking: preferences.statusTracking,
    tracking: preferences.timeTracking,
    report: preferences.timeRequest
  })

  useEffect(() => {
    user.current = userData.userId
  }, [userData])

  useEffect(() => {
    if (data) {
      if (data?.messageTitle && data?.messageBody) {
        setStatusMessage(true)
      }
    }
  }, [data])

  useEffect(() => {
    if (statusMessage === false) {
      setData({
        ...data,
        messageTitle: '',
        messageBody: ''
      })
    }
  }, [statusMessage])

  function handleOnChange (e) {
    if (e.target.type === 'checkbox') {
      const value = $(`#${e.target.id}`).is(':checked')
      if (e.target.id === 'status' || e.target.id === 'recursive') {
        setData({
          ...data,
          photo: {
            ...data.photo,
            [e.target.id]: value
          }
        })
      } else if (e.target.id === 'statusTracking') {
        if (!value) {
          setData({
            ...data,
            photo: {
              ...data.photo,
              status: value
            }
          })
        }
        setTracking({
          ...tracking,
          [e.target.id]: value
        })
      } else {
        setData({
          ...data,
          [e.target.id]: value
        })
      }
    } else {
      if (e.target.id === 'quality' || e.target.id === 'time') {
        setData({
          ...data,
          photo: {
            ...data.photo,
            [e.target.id]: e.target.value
          }
        })
      } else if (e.target.id === 'report' || e.target.id === 'tracking') {
        setTracking({
          ...tracking,
          [e.target.id]: e.target.value
        })
      } else {
        setData({
          ...data,
          [e.target.id]: e.target.value
        })
      }
    }
  }

  const handleInputChangeMessage = (e) => {
    const value = $(`#${e.target.id}`).is(':checked')
    setStatusMessage(value)
  }

  function closeModal () {
    onHide()
    setData({
      alarm: preferences.alarm,
      touch: preferences.touch,
      screen: preferences.screen,
      background: preferences.background,
      /*   usb: preferences.usb,
      statusMessage: preferences.statusMessage, */
      messageTitle: preferences.messageTitle,
      messageBody: preferences.messageBody,
      photo: {
        status: preferences.photo,
        quality: preferences.quality,
        recursive: preferences.recursive,
        time: preferences.timeImage
      }
    })
    setTracking({
      statusTracking: preferences.statusTracking,
      tracking: preferences.timeTracking,
      report: preferences.timeRequest
    })
    setId(Math.round(Math.random() * (1000000000000000 - 10 + 1) + 10))
  }

  async function sendAction () {
    $('#btnModalSucess').prop('disabled', true)
    const devicesData = devices
    const flag = !!(action === 'bloquear' && device?.motive_lock === 'disabled' && device?.status_lock === true)
    const groupData = group

    if (action === 'bloquear' && statusMessage && data?.messageTitle === '') {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: '¡Debe completar el título del mensaje de notificación!',
        showConfirmButton: false,
        timer: 1500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else if (action === 'bloquear' && statusMessage && data?.messageBody === '') {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: '¡Debe completar el mensaje de notificación!',
        showConfirmButton: false,
        timer: 1500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else {
      const dataPreferences = data
      if (action === 'desbloquear') {
        dataPreferences.messageBody = ''
        dataPreferences.messageTitle = ''
      }
      const json = {
        action: 'lock',
        data: {
          statusLock: action === 'bloquear',
          order_id: id,
          preferences: dataPreferences
        },
        devicesId: group?.length > 0 ? [] : devices,
        groupsId: group !== undefined ? group : [],
        userId: userData.userId
      }

      const jsonTracking = {
        action: 'tracking',
        data: {
          statusTracking: tracking.statusTracking,
          report: tracking.report,
          tracking: tracking.tracking,
          order_id: id
        },
        devicesId: group?.length > 0 ? [] : devices,
        groupsId: group !== undefined ? group : [],
        userId: userData.userId
      }

      if (groupData?.length > 0) {
        if (action === 'bloquear') {
          devicesData.forEach((dev) => {
            dev.motive_lock = 'locked'
          })
        } else if (action === 'desbloquear') {
          devicesData.forEach((dev) => {
            dev.motive_lock = null
          })
        }
      }

      if (action === 'bloquear') {
        devicesData.motive_lock = 'locked'
      } else if (action === 'desbloquear') {
        devicesData.motive_lock = null
      }

      let response
      if (action === 'desbloquear') {
        jsonTracking.data.statusTracking = false
        if (realTime) {
          socketSendActions([json, jsonTracking])
        } else {
          response = await postData('actions', [json, jsonTracking])
        }
      } else {
        if (tracking.statusTracking) {
          if (realTime) {
            socketSendActions([json, jsonTracking])
          } else {
            response = await postData('actions', [json, jsonTracking])
          }
        } else {
          if (realTime) {
            socketSendActions([json])
          } else {
            response = await postData('actions', [json])
          }
        }
      }
      closeModal()

      if (response?.data[0].result === true || realTime) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Acción realizada con éxito!',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)

        if (flag) {
          await putData('device', {
            id: groupData?.length > 0 ? devicesData.map((dev) => dev.id) : devicesData[0],
            motive_lock: devicesData.motive_lock,
            status_lock: false
          })
        } else {
          await putData('device', {
            id: groupData?.length > 0 ? devicesData.map((dev) => dev.id) : devicesData[0],
            motive_lock: devicesData.motive_lock
          })
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error. No se pudo realizar la acción!',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      }
      if (reload !== undefined) {
        reload()
        setFlagReload(true)
      }
    }
  }

  const elements = elementsLocked(data, tracking, statusMessage)
  const screenElements = elements.slice(0, 3)
  const othersElements = elements.slice(3, 7)
  const trackingElements = elements.slice(7, 14)

  if (preferences === undefined || preferences === null) {
    <Loading color="primary" />
  } else {
    return (
      <>
        <Modal
          show={show}
          onHide={closeModal}
          size={action === 'desbloquear' ? 'lg' : 'xl'}
          id="deviceLocked"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                textAlign: 'center'
              }}
            >
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={styles.modalBody}
            style={{
              display:
                configDefault === true || action === 'desbloquear'
                  ? 'none'
                  : 'block'
            }}
          >
            <div className="card">
              <Accordion activeIndex={[0]}>
                <AccordionTab header="Opciones de Pantalla">
                  {screenElements &&
                    screenElements.map((el) => (
                      <div
                        key={el.key}
                        className={styleschecks.containerDiv}
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <label className={styleschecks.sliderLabel}>
                          {el.label}
                        </label>
                        <label className={styleschecks.switch}>
                          <input
                            key={key}
                            id={el.id}
                            value={el.value}
                            checked={el.value}
                            name={el.name}
                            onChange={(e) => handleOnChange(e)}
                            type="checkbox"
                            className="focus"
                          />
                          <span
                            className={`${styleschecks.slider} ${styleschecks.round} ${styleschecks.inputsLabels}`}
                          />
                        </label>
                      </div>
                    ))}
                </AccordionTab>
                <AccordionTab header="Opciones de Posicionamiento y Seguimiento">
                  {trackingElements &&
                    trackingElements.map((el) =>
                      el.type === 'checkbox'
                        ? (
                        <div
                          key={el.key}
                          className={styleschecks.containerDiv}
                          style={{
                            justifyContent: 'space-between',
                            display: el.display === false ? 'none' : 'flex'
                          }}
                        >
                          <label className={styleschecks.sliderLabel}>
                            {el.label}
                          </label>
                          <label className={styleschecks.switch}>
                            <input
                              key={key}
                              id={el.id}
                              value={el.value}
                              checked={el.value}
                              name={el.name}
                              onChange={(e) => handleOnChange(e)}
                              type="checkbox"
                              className="focus"
                            />
                            <span
                              className={`${styleschecks.slider} ${styleschecks.round} ${styleschecks.inputsLabels}`}
                            />
                          </label>
                        </div>
                          )
                        : el.type === 'number'
                          ? (
                        <div
                          className={styleschecks.containerDiv}
                          id={`div${el.id}`}
                          style={{
                            justifyContent: 'space-between',
                            margin: '0px 0px 15px 0px',
                            display: el.display === false ? 'none' : 'flex'
                          }}
                        >
                          <div>
                            <label className={styleschecks.sliderLabel}>
                              {el.label}
                            </label>
                            <small className="text-muted">{el.small}</small>
                          </div>
                          <div
                            style={{
                              width: 'fit-content',
                              justifyContent: 'flex-end',
                              display: 'flex'
                            }}
                          >
                            <input
                              key={el.key}
                              placeholder={el.placeholder}
                              id={el.id}
                              name={el.name}
                              value={el.value}
                              onChange={(e) => handleOnChange(e)}
                              type={el.type}
                              style={{ width: '4rem', textAlign: 'center' }}
                              className={styleschecks.focus}
                            />
                          </div>
                        </div>
                            )
                          : el.type === 'options'
                            ? (
                        <div
                          className={styleschecks.containerDiv}
                          style={{
                            display: el.display === false ? 'none' : 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <label className={styleschecks.sliderLabel}>
                            {el.label}
                          </label>
                          <select
                            id={el.id}
                            onChange={(e) => handleOnChange(e)}
                            /* className="form-control" */
                            style={{ width: '280px' }}
                            name="resolution_images"
                            defaultValue={'default'}
                            className={styleschecks.focus}
                            value={el.value}
                          >
                            <option value="default" disabled>
                              {el.default}
                            </option>
                            {el.options &&
                              el.options.map((option) => (
                                <option value={option.value} key={option.title}>
                                  {option.title}
                                </option>
                              ))}
                          </select>
                        </div>
                              )
                            : null
                    )}
                </AccordionTab>
                <AccordionTab header="Otras Opciones">
                  {othersElements &&
                    othersElements.map((el) =>
                      el.type === 'checkbox'
                        ? (
                        <div
                          key={el.key}
                          className={styleschecks.containerDiv}
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex'
                          }}
                        >
                          <label className={styleschecks.sliderLabel}>
                            {el.label}
                          </label>
                          <label className={styleschecks.switch}>
                            <input
                              key={key}
                              id={el.id}
                              value={el.value}
                              checked={el.value}
                              name={el.name}
                              onChange={el.key === 'keyStatusMessage' ? (e) => handleInputChangeMessage(e) : (e) => handleOnChange(e)}
                              type="checkbox"
                              className="focus"
                            />
                            <span
                              className={`${styleschecks.slider} ${styleschecks.round} ${styleschecks.inputsLabels}`}
                            />
                          </label>
                        </div>
                          )
                        : el.type === 'area'
                          ? (
                        <div
                          className={styleschecks.containerDiv}
                          style={{
                            display: el.display === false ? 'none' : 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div style={{ width: 'auto' }}>
                            <label className={styleschecks.sliderLabel}>
                              {el.label}
                            </label>
                          </div>
                          <div style={{ width: '60%' }}>
                            <textarea
                              key={el.key}
                              style={{ height: '7rem', resize: 'none' }}
                              placeholder={el.placeholder}
                              id={el.id}
                              name={el.name}
                              value={el.value}
                              onChange={(e) => handleOnChange(e)}
                              type={el.type}
                              className={`${styles.inputModal} ${styles.inputUsers} ${styleschecks.focus}`}
                            />
                          </div>
                        </div>
                            )
                          : el.type === 'text'
                            ? (
                        <div
                          className={styleschecks.containerDiv}
                          id={`div${el.id}`}
                          style={{
                            justifyContent: 'space-between',
                            margin: '0px 0px 15px 0px',
                            display: el.display === false ? 'none' : 'flex'
                          }}
                        >
                          <div>
                            <label className={styleschecks.sliderLabel}>
                              {el.label}
                            </label>
                          </div>
                          <div
                            style={{
                              width: '60%',
                              justifyContent: 'flex-end',
                              display: 'flex'
                            }}
                          >
                            <input
                              key={el.key}
                              placeholder={el.placeholder}
                              id={el.id}
                              name={el.name}
                              value={el.value}
                              onChange={(e) => handleOnChange(e)}
                              type={el.type}
                              style={{ width: '100%' }}
                              className={styleschecks.focus}
                            />
                          </div>
                        </div>
                              )
                            : null
                    )}
                </AccordionTab>
              </Accordion>
            </div>
          </Modal.Body>
          <Modal.Body
            className={styles.modalBody}
            style={{
              display:
                configDefault === true && action === 'bloquear'
                  ? 'block'
                  : 'none'
            }}
          >
            <p style={{ textAlign: 'center' }}> Los parámetros de bloqueo serán los predeterminados por defecto.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => closeModal()} variant="danger">
              Cerrar
            </Button>
            <Button
              onClick={() => sendAction()}
              variant="primary"
              id="btnModalSucess"
            >
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

LockedDevices.propTypes = {
  preferences: PropTypes.object,
  onHide: PropTypes.func,
  action: PropTypes.string,
  show: PropTypes.bool,
  key: PropTypes.string,
  title: PropTypes.string,
  configDefault: PropTypes.bool,
  devices: PropTypes.array,
  group: PropTypes.array,
  socketSendActions: PropTypes.func,
  realTime: PropTypes.bool,
  reload: PropTypes.func,
  setFlagReload: PropTypes.func,
  device: PropTypes.object
}

import React from 'react'
import PropTypes from 'prop-types'
import { ProgressSpinner } from 'primereact/progressspinner'
import BoxChart from '../../generals/boxCharts'
import Pie from '../../generals/charts/pie'
import KnobChart from '../../generals/charts/knob'
import VerticalBar from '../../generals/charts/verticalBar'
import stylesCardsInfo from '../../../css/generals/CardsInfo.module.css'
import BatteryChart from '../../generals/charts/battery'

export default function CardsStats ({ stat, preferences, connections, labelConn, role }) {
  return (
        <div className={stylesCardsInfo.cardsContainer}>
            {role?.dashboardDevicePage.diskChartDashboardDevice && <BoxChart
              title="Disco"
              icon="fa-solid fa-floppy-disk"
              className={`${stylesCardsInfo.boxChart}`}>
              { stat === undefined
                ? (
                <ProgressSpinner />
                  )
                : stat?.error || stat?.percentageDisk === null
                  ? (
                <h5>Sin data a mostrar</h5>
                    )
                  : (
                <div className={stylesCardsInfo.containerBoxChart}>
                  <label className={stylesCardsInfo.sliderLabel}>
                    Cantidad de alertas:
                    {stat.countOverDisk === null || stat.countOverDisk === 0 || stat.countOverDisk === undefined
                      ? ' 0'
                      : ` ${stat.countOverDisk}`}
                  </label>
                  <Pie
                    type={'individual'}
                    data={stat}
                  />
                </div>)}
            </BoxChart>}
            {role?.dashboardDevicePage.ramChartDashboardDevice && <BoxChart
              title="Ram"
              icon="fa-solid fa-memory"
              className={`${stylesCardsInfo.boxChart}`}
            >
              {stat === undefined
                ? (
                <ProgressSpinner />
                  )
                : preferences === undefined
                  ? (
                <ProgressSpinner />
                    )
                  : stat?.error || preferences?.error || stat.percentageRam === null
                    ? (
                <h5>Sin data a mostrar</h5>
                      )
                    : (
                <div className={stylesCardsInfo.containerBoxChart}>
                  <label className={stylesCardsInfo.sliderLabel}>
                    Cantidad de alertas:
                    {stat.countOverRam === 0 || stat.countOverRam === null || stat.countOverRam === undefined
                      ? 0
                      : stat.countOverRam}
                  </label>
                  <KnobChart
                    data={stat}
                    alert={preferences.preferencesReport.percentRam}
                    type="individual"
                    size={230}
                  />
                </div>)}
            </BoxChart>}
            {role?.dashboardDevicePage.battery && <BoxChart
              title="Bateria"
              icon="fas fa-battery-full"
              className={`${stylesCardsInfo.boxChart}`}
            >
              { stat === undefined
                ? (
                <ProgressSpinner />
                  )
                : stat?.error || stat?.Battery === undefined || isNaN(parseInt(stat?.Battery?.lifePercent))
                  ? (
                <h5>Sin data a mostrar</h5>
                    )
                  : (
                    <div className={stylesCardsInfo.batteryContainer}>
                    {/* se armo un componente de bateria para tenerlo separado */}
                    <BatteryChart
                      role={role}
                      statBattery={stat?.Battery}
                    />
                  </div>)}
            </BoxChart>}
            {role?.dashboardDevicePage.connectionsVerticalBar && <BoxChart
              title="Conexiones"
              icon="fa-solid fa-mobile"
              className={`${stylesCardsInfo.boxChart}`}
            >
              {connections === undefined
                ? (
                <ProgressSpinner />
                  )
                : connections?.error || connections.length === 0
                  ? (
                <h5>Sin data a mostrar</h5>
                    )
                  : labelConn
                    ? (
                <VerticalBar
                  data={labelConn.data}
                  labels={labelConn.labels}
                  backgroundColor={labelConn.backgroundColor}
                  title={'Conexiones'}
                />)
                    : (<></>)}
            </BoxChart>}
        </div>
  )
}

CardsStats.propTypes = {
  stat: PropTypes.object,
  preferences: PropTypes.object,
  connections: PropTypes.array,
  labelConn: PropTypes.object,
  role: PropTypes.object
}

import deviceDatatableJson from './deviceDatatableJson'

export function devicesJson () {
  const items = {
    columDataTableDevices: {
      name: 'columDataTableDevices',
      label: 'editar las columnas de la tabla de dispositivos',
      value: 'columDataTableDevices',
      option: deviceDatatableJson()
    },
    newGroupInDevices: {
      name: 'newGroupInDevices',
      label: 'crear un grupo'
    },
    actionSendMessageInDevices: {
      name: 'actionSendMessageInDevices',
      label: 'enviar mensaje'
    },
    exportPdfInDevices: {
      name: 'exportPdfInDevices',
      label: 'exportar dispositivos en formato pdf'
    },
    exportCsvInDevices: {
      name: 'exportCsvInDevices',
      label: 'exportar dispositivos en formato csv'
    },
    selectRowsDataTableInDevices: {
      name: 'selectRowsDataTableInDevices',
      label: 'seleccionar filas de la tabla de datos'
    },

    removeDevicesInDevices: {
      name: 'removeDevicesInDevices',
      label: 'eliminar dispositivos'
    },
    seeDashboardInDevices: {
      name: 'seeDashboardInDevices',
      label: 'acceder al dashboard de los dispositivos'
    }
  }

  return (items)
}

import Swal from 'sweetalert2'

export const redirectIndex = (type) => {
  if (type !== 'resetFactory') {
    if (type === 'authenticationProblems') {
      Swal.fire({
        icon: 'error',
        title: 'La Sesión ha Caducado',
        text: '¡Inicie sesión nuevamente!',
        confirmButtonColor: '#d33'
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
        confirmButtonColor: '#d33'
      })
    }
  }

  setTimeout(() => {
    window.location.replace('/redirect')
    // window.location.href = '/'
  }, 1500)

  sessionStorage.clear()

  return null
}

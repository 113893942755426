import React from 'react'
import { Chart } from 'primereact/chart'
import styles from '../../../css/generals/charts/VerticalBar.module.css'
import PropTypes from 'prop-types'

export default function VerticalBar ({ labels, title, backgroundColor, data, className }) {
  const useState = () => {
    const basicData = {
      labels,
      datasets: [
        {
          label: title,
          backgroundColor,
          data
        }
      ]
    }
    return {
      basicData
    }
  }

  const { basicData } = useState()

  const getLightTheme = () => {
    const basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: '#3F9BFF'
          }
        }
      },
      scales: {
        x: {
          type: 'category',
          ticks: {
            color: '#495057',
            font: {
              size: 10
            }
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }

    return {
      basicOptions
    }
  }

  const { basicOptions } = getLightTheme()
  // console.log({ basicData, basicOptions });

  return (
    <>
      <div className={`${styles.containerVerticalBar} ${className}`}>
        <Chart
          type="bar"
          data={basicData}
          options={basicOptions}
          className="linebar"
          height="100%"
          width="95%"
        />
      </div>
    </>
  )
}

VerticalBar.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  backgroundColor: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.string
}

import React from 'react'
import Swal from 'sweetalert2'
import { Button } from 'primereact/button'
import { deleteData, postData } from '../../../actions/servicesHttp'
import PropTypes from 'prop-types'
import '../../../css/generals/sweetAlert.css'
import styles from '../../../css/Safeweb/SafeWeb.module.css'

export default function ModifyListSafe ({ data, reload }) {
  // console.log(props);
  async function moveList (type) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons
      .fire({
        title: 'Esta seguro que desea moverlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si!',
        cancelButtonText: 'No, cerrar!',
        reverseButtons: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteData('safeweb/blackList', data.id)
          const json = {
            url: data.url,
            count: data.count,
            suspect: false
          }
          const responsePost = await postData(`safeweb/${type}`, json)

          if (responsePost.status === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Modificacion exitosa!',
              showConfirmButton: false,
              timer: 1500
            })
            reload()
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Modificacion fallida!',
              showConfirmButton: false,
              timer: 1500
            })
          }

          reload()
        }
      })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Button
        className={`${styles.btnHover}`}
        style={{ marginRight: '1rem' }}
        aria-label="Cancel"
        onClick={() => moveList('whiteList')}
      >
        Lista Blanca
      </Button>
      <Button
        className={`${styles.btnHover}`}
        aria-label="Cancel"
        onClick={() => moveList('blackList')}
      >
        Lista Negra
      </Button>
    </div>
  )
}

ModifyListSafe.propTypes = {
  data: PropTypes.object,
  reload: PropTypes.func
}

import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import styles from '../../../css/generals/charts/Doughnut.module.css'
import PropTypes from 'prop-types'

export default function Doughnuts ({ data, maxWidth }) {
  const [devices, setDevices] = useState({
    connected: null,
    desconnected: null
  })

  const getConnections = (connections) => {
    if (connections) {
      setDevices({
        connected: connections.connected,
        desconnected: connections.total - connections.connected
      })
    }
  }

  useEffect(() => {
    getConnections(data)
  }, [data])

  const chartData = {
    labels: ['No conectados', 'Conectados'],
    datasets: [
      {
        data: [devices.desconnected, devices.connected],
        backgroundColor: ['#003686', '#3F9BFF'],
        hoverBackgroundColor: ['#003686', '#3F9BFF']
      }
    ]
  }

  const lightOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    }
  }

  return (
    <>
      <div className={`${styles.containerConn}`}>
        <Chart
          type="doughnut"
          data={chartData}
          options={lightOptions}
          style={{
            position: 'relative',
            maxWidth
          }}
          className={`${styles.statConn}`}
        />
      </div>
    </>
  )
}

Doughnuts.propTypes = {
  maxWidth: PropTypes.string,
  data: PropTypes.array
}

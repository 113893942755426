import React, { useState, useEffect } from 'react'
import { Chart } from 'primereact/chart'
import styles from '../../../css/generals/charts/VerticalBar.module.css'
import PropTypes from 'prop-types'

export default function HorizontalBar ({ labels, title, dataChart }) {
  const [chartData, setChartData] = useState({})
  const [chartOptions, setChartOptions] = useState({})

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          fontColor: '#4b5563'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#6b7280',
          font: {
            weight: 500,
            size: 10
          }
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: '#212529',
          font: {
            size: 12,
            weight: 600,
            textWrap: 'wrap'
          }
        },
        grid: {
          color: '#dfe7ef',
          drawBorder: false
        }
      }
    }
  }

  useEffect(() => {
    if (dataChart) {
      const data = {
        labels,
        datasets: [
          {
            label: title,
            backgroundColor: '#3b82f6',
            borderColor: '#3b82f6',
            data: dataChart
          }
        ]
      }

      setChartData(data)
      setChartOptions(options)
    }
  }, [dataChart])

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      className={`${styles.containerHorizontalBar}`}
      height='90%'
    />
  )
}

HorizontalBar.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  dataChart: PropTypes.array
}

import React from 'react'
import Swal from 'sweetalert2'
import { Button } from 'primereact/button'
import { deleteData } from '../../../actions/servicesHttp'
import { modifyProxy } from '../toolsFunctions'
import PropTypes from 'prop-types'
import '../../../css/generals/sweetAlert.css'

export default function ActionDelete ({ table, id, reload }) {
  async function deleted () {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons
      .fire({
        title: 'Esta seguro que desea eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, borrar!',
        cancelButtonText: 'No, cerrar!',
        reverseButtons: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let trash
          if (
            table === 'keyWords' ||
            table === 'blackList' ||
            table === 'whiteList'
          ) {
            trash = await deleteData(
              `safeweb/${table}`,
              id
            )
          } else {
            trash = await deleteData(table, id)
          }

          if (trash.data.result) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Eliminación éxitosa!',
              showConfirmButton: false,
              timer: 1500
            })
            reload(true)
            modifyProxy(table)
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Eliminación fallida!',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
      })
  }

  return (<Button icon="pi pi-trash" className="p-button-rounded p-button-danger btn-white" aria-label="Cancel" onClick={() => deleted()} />)
}

ActionDelete.propTypes = {
  table: PropTypes.string,
  id: PropTypes.number,
  reload: PropTypes.func
}

import React, { useState, useEffect } from 'react'
import { getData } from '../../actions/servicesHttp'
import { modifyProxy } from '../../components/generals/toolsFunctions'
import DataTableDemo from '../../components/generals/datatables/datatable'
import Header from '../../components/generals/header'
import Button from 'react-bootstrap/Button'
import AddElement from '../../components/modals/addElementSafeweb'
import store from '../../reducer/store'
import Loading from '../../components/generals/loading'
import styles from '../../css/Safeweb/Lists.module.css'
import stylesIndex from '../../css/Index.module.css'

export default function Listas () {
  const { user } = store.getState()
  const [lists, setLists] = useState({})

  const [modal, setModal] = useState({
    action: false,
    show: false
  })

  const role = user.roles

  const getLists = async () => {
    const black = await getData('safeweb/blackList')
    const white = await getData('safeweb/whiteList')

    setLists({
      black: black.data,
      white: white.data
    })
  }

  function showModal (list) {
    setModal({
      action: list,
      show: true
    })
  }

  useEffect(() => {
    getLists()
  }, [])

  if (
    /* lists === {} || */
    lists.black === undefined ||
    lists.white === undefined
  ) {
    return (
      <div className="containerHeight content-wrapper">
        <Loading color="primary" />
      </div>
    )
  } else {
    return (
      <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
        <div className={`content  ${stylesIndex.mainContent}`}>
          <div style={{ marginBottom: '3rem' }}>
            <Header title="Listas" />
            <div className={styles.tableHead}>
              <h4>Lista Negra</h4>
              {role?.safeWebListsPage.addUrlToBlackList &&
                <Button
                  onClick={() => showModal('blackList')}
                  variant="dark"
                  className={styles.listButton}
                >
                  <i className="fas fa-link" style={{ marginRight: '0.5rem' }}></i>
                  Nueva Url
                </Button>}
            </div>

            <DataTableDemo
              reload={getLists}
              data={lists.black.filter(
                (list) => list.suspect === false
              )}
              table="blackList"
              rowSelecteds={false}
              rowStates={false}
              editUsers={false}
              exportsPdf={role?.safeWebListsPage.exportPdfBlackList}
              exportCsv={role?.safeWebListsPage.exportCsvBlackList}
              actionDelete={role?.safeWebListsPage.deleteUrlsBlackList}
              actionDashboard={false}
              searchs={['url', 'visits']}
              columns={[
                {
                  field: 'url',
                  header: 'Url'
                },
                {
                  field: 'count',
                  header: 'Visitas'
                }
              ]}
            />
          </div>
          <div>
            <div className={styles.tableHead}>
              <h4>Lista Blanca</h4>

              {role?.safeWebListsPage.addUrlToWhiteList &&
                <Button
                  onClick={() => showModal('whiteList')}
                  variant="dark"
                  className={styles.listButton}
                >
                  <i className="fas fa-link" style={{ marginRight: '0.5rem' }}></i>
                  Nueva Url
                </Button>}
            </div>
            <DataTableDemo
              reload={getLists}
              data={lists.white}
              table="whiteList"
              rowSelecteds={false}
              rowStates={false}
              editUsers={false}
              exportsPdf={role?.safeWebListsPage.exportPdfWhiteList}
              exportCsv={role?.safeWebListsPage.exportCsvWhiteList}
              actionDelete={role?.safeWebListsPage.deleteUrlsWhiteList}
              actionDashboard={false}
              searchs={['url', 'visits']}
              columns={[
                {
                  field: 'url',
                  header: 'Url'
                },
                {
                  field: 'count',
                  header: 'Visitas'
                }
              ]}
            />
          </div>

          {role?.safeWebListsPage.listSuspect && <div style={{ marginTop: '2rem' }}>
            <div className={styles.tableHead}>
              <h4>Lista de Sospechosas</h4>
            </div>
            <DataTableDemo
              reload={getLists}
              data={lists.black.filter(
                (list) => list.suspect === true
              )}
              table="suspect"
              rowSelecteds={false}
              rowStates={false}
              editUsers={false}
              exportsPdf={role?.safeWebListsPage.exportPdfSuspectList}
              exportCsv={role?.safeWebListsPage.exportCsvSuspectList}
              actionDelete={false}
              modifyLists={true}
              actionDashboard={false}
              searchs={['url', 'visits']}
              columns={[
                {
                  field: 'url',
                  header: 'Url'
                },
                {
                  field: 'count',
                  header: 'Visitas'
                }
              ]}
            />
          </div>}

          {/* MODAL AGREGAR URL */}
          <AddElement
            show={modal.show}
            onHide={() =>
              setModal({
                action: '',
                show: false
              })
            }
            reload={getLists}
            action={modal.action}
            type="url"
            modifyProxy={modifyProxy}
          />
        </div>
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../css/RxartSecure/ParametersRS.module.css'

export default function ParametersRS ({ parameters, setParameters }) {
  const text = 'En uso normal estos parámetros se utilizarán automáticamente al generar tickets de continuidad:'

  const items = [
    {
      label: 'Fecha límite',
      text: 'Número de días que el dispositivo se puede utilizar libremente si no vuelve a comunicarse con el servidor',
      inputName: 'deadLine'
    },
    {
      label: 'Tolerancia',
      text: 'Número de días que el dispositivo aún se puede usar (con una advertencia en cada inicio) después de la fecha de vencimiento antes de que se bloquee por completo',
      inputName: 'tolerance'
    },
    {
      label: 'Máximo de arranques',
      text: '',
      inputName: 'maximumStarts'
    }
  ]

  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value)
    const value = parseInt(e.target.value)

    setParameters({
      ...parameters,
      [e.target.name]: value
    })
  }

  return (
        <div id="parametersRS" className={styles.parametersContainer}>
            <p className={`${styles.mainText} ${styles.justified}`}>{text}</p>
            { items.map((element) => {
              const input = element.inputName
              return (
                <div key={element.inputName} className={styles.inputContainer}>
                    <label className={styles.labelRS}>{element.label}</label>
                    <p className={styles.justified}>{element.text}</p>
                    <input type="number" name={element.inputName} value={input === 'deadLine' ? parameters.deadLine : input === 'tolerance' ? parameters.tolerance : parameters.maximumStarts} /* min={1} */ onChange={handleInputChange} className={styles.inputRS}/>
                </div>
              )
            })
            }
        </div>
  )
}

ParametersRS.propTypes = {
  parameters: PropTypes.object,
  setParameters: PropTypes.func
}

import React from 'react'
import ModalCommands from '../ModalCommand'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

export default function ModalSendCommands ({ show, onHide, identity }) {
  const closeModal = () => {
    onHide()
  }
  return (
    <>
      <Modal
        show={show}
        size="md"
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="sendCommand">
        <ModalCommands onHide={ closeModal }identity={identity}/>
      </Modal>
    </>
  )
}

ModalSendCommands.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  identity: PropTypes.string
}

import React, { useState, useEffect } from 'react'
import { Knob } from 'primereact/knob'
import PropTypes from 'prop-types'
import style from '../../../css/generals/charts/Knob.module.css'
import '../../../css/globals.css'

export default function KnobChart ({ data, type, size = 100, alert, realTime, funcSokRam }) {
  const [ram, setRam] = useState()

  const [percent, setPercent] = useState(data?.percentageRam)
  const [dataReal, setDataReal] = useState()
  useEffect(() => {
    if (realTime) {
      funcSokRam(setDataReal)
    }
  }, [])
  useEffect(() => {
    if (dataReal) { setPercent(dataReal?.percentageRam) }
  }, [dataReal])

  useEffect(() => {
    if (percent >= 0 && percent <= 100) {
      setRam(100 - percent)
    }
  }, [percent, type])

  return (
    <>
      {ram === undefined && <h5>Sin data a mostrar</h5>}
      {ram && <Knob
        className={type === 'individual' ? style.spacePie : ''}
        readOnly
        value={ram}
        valueTemplate={ram + '%'}
        size={size}
        valueColor={alert && alert < ram ? '#FF3023' : '#003686'}
        rangeColor={alert && alert < ram ? '#FFB5B5' : '#007AFF'}
        textColor={'black'}
        onChange={(e) => setRam(e.value)}
      />}
    </>
  )
}

KnobChart.propTypes = {
  alert: PropTypes.number,
  data: PropTypes.object,
  type: PropTypes.string,
  size: PropTypes.number,
  funcSokRam: PropTypes.func,
  realTime: PropTypes.bool
}

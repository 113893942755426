import React, { useState, useEffect } from 'react'
import { postData } from '../../../actions/servicesHttp'
import Button from 'react-bootstrap/Button'
import Header from '../../../components/generals/header'
import Loading from '../../../components/generals/loading'
import styles from '../../../css/generals/Checkbox.module.css'
import stylesPreferences from '../../../css/preferences/Preferences.module.css'
import stylesIndex from '../../../css/Index.module.css'
import { compareObj } from '../../../components/generals/toolsFunctions'
import Swal from 'sweetalert2'
import store from '../../../reducer/store'

export default function RSPreferences () {
  // ? urls de la API cuco
  const [rxartSecureUrl] = useState('RxartSecure?endpoint=query_client_data')
  const [rxartSecureChangePreferencesUrl] = useState('RxartSecure?endpoint=change_client_data')

  const [RSPreferences, setRSPreferences] = useState()
  const [preferencesToCompare, setPreferencesToCompare] = useState({})
  const { user } = store.getState()
  const role = user.roles

  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value)

    if (e.target.name === 'initimeout' || e.target.name === 'lastdate' || e.target.name === 'tolerance' || e.target.name === 'maxboots' || e.target.name === 'finterval') {
      setRSPreferences({
        ...RSPreferences,
        [e.target.name]: parseInt(e.target.value)
      })
    } else {
      setRSPreferences({
        ...RSPreferences,
        [e.target.name]: e.target.value
      })
    }
  }

  const postRequestRS = async (url, body, set, setCompare, type) => {
    const response = await postData(url, body)
    // console.log(response)
    if (response.status === 200) {
      // console.log(response.data)
      set(response.data?.client_data)
      setCompare(response.data?.client_data)
      if (type === 'POST') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Cambios guardados exitosamente!',
          showConfirmButton: false,
          timer: 1500
        })
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error!!!',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  useEffect(() => {
    const body = JSON.stringify({})
    postRequestRS(rxartSecureUrl, body, setRSPreferences, setPreferencesToCompare, 'GET')
  }, [rxartSecureUrl])

  const saveRSPreferences = (preferences, preferencesToCompare) => {
    console.log(preferences)

    const comparation = compareObj(preferences, preferencesToCompare)

    if (comparation) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: '¡No hay cambios para guardar!',
        showConfirmButton: false,
        timer: 1500
      })
    }

    const body = JSON.stringify({
      newIniTimeOut: preferences.initimeout,
      newLastDate: preferences.lastdate,
      newTolerence: preferences.tolerance,
      newMaxBoots: preferences.maxboots,
      newFInterval: preferences.finterval,
      newMsgIT: preferences.msgIT,
      newMsgBW: preferences.msgBW,
      newMsgBT: preferences.msgBT
    })

    postRequestRS(rxartSecureChangePreferencesUrl, body, setRSPreferences, setPreferencesToCompare, 'POST')
  }

  if (RSPreferences === undefined) {
    return (
      <div className="content-wrapper containerHeight">
        <Loading color="primary" />
      </div>
    )
  } else {
    return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Configuración Rxart Secure" margin="0" />
        <div>
            {role?.preferencesRxartSecurePage.parameters &&
            <>
              <h2 className={`${styles.subtitle}`}>Parámetros:</h2>
              <div className={`${styles.componentBox} ${stylesPreferences.componentBoxRxartSecure}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Días hasta el primer tiempo de espera:</label>
                      <input value={RSPreferences?.initimeout ? RSPreferences?.initimeout : ''} name="initimeout" onChange={handleInputChange} type="number" min={1} className={`${styles.focus} ${stylesPreferences.inputsPref}`}/>
                  </div>
                  <p className={stylesPreferences.textRxartSecure}>Días de espera después de la instalación. Tan pronto como se reciba el primer ticket normal, el Fecha límite entra en vigor</p>
              </div>
              <div className={`${styles.componentBox} ${stylesPreferences.componentBoxRxartSecure}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Fecha límite:</label>
                      <input value={RSPreferences?.lastdate ? RSPreferences?.lastdate : ''} name="lastdate" type="number" onChange={handleInputChange} min={1} /* max={} */ className={`${styles.focus} ${stylesPreferences.inputsPref}`}/>
                  </div>
                  <p className={stylesPreferences.textRxartSecure}>Número de días que el dispositivo se puede utilizar libremente si no vuelve a comunicarse con el servidor. Cuenta desde el momento de la adquisición del siguiente ticket</p>
              </div>
              <div className={`${styles.componentBox} ${stylesPreferences.componentBoxRxartSecure}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Tolerancia:</label>
                      <input value={RSPreferences?.tolerance ? RSPreferences?.tolerance : 0} onChange={handleInputChange} name="tolerance" type="number" /* min={} max={} */ className={`${styles.focus} ${stylesPreferences.inputsPref}`}/>
                  </div>
                  <p className={stylesPreferences.textRxartSecure}>Número de días que el dispositivo aún se puede usar (con una advertencia en cada inicio) después de la fecha de vencimiento, antes de que se bloquee por completo.</p>
              </div>
              <div className={`${styles.componentBox} ${stylesPreferences.componentBoxRxartSecure}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Máximo de arranques:</label>
                      <input value={RSPreferences?.maxboots ? RSPreferences?.maxboots : ''} onChange={handleInputChange} name="maxboots" type="number" min={1} /* max={} */ className={`${styles.focus} ${stylesPreferences.inputsPref}`}/>
                  </div>
                  <p className={stylesPreferences.textRxartSecure}>Además del control de límite de tiempo, existe este límite en el número de arranques que se pueden realizar antes del bloqueo, sin embargo no hay comunicación con el servidor.</p>
              </div>
              <div className={`${styles.componentBox} ${stylesPreferences.componentBoxRxartSecure}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.align}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Intervalo de búsqueda:</label>
                      <input value={RSPreferences?.finterval ? RSPreferences?.finterval : ''} onChange={handleInputChange} name="finterval" type="number" min={1} className={`${styles.focus} ${stylesPreferences.inputsPref}`}/>
                  </div>
                  <p className={stylesPreferences.textRxartSecure}>Días de intervalo entre cada ticket solicitado por el dispositivo</p>
              </div>
            </>}
            {role?.preferencesRxartSecurePage.parameters &&
            <>
              <h2 className={`${styles.subtitle} ${styles.margin}`}>Mensajes:</h2>
              <div className={`${styles.componentBox} ${stylesPreferences.boxMessage}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.alignMessage}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Mensaje de instalación/desinstalación:</label>
                      <textarea
                          name="msgIT"
                          minLength={11}
                          maxLength={216}
                          value={RSPreferences?.msgIT ? RSPreferences?.msgIT : ''}
                          onChange={handleInputChange}
                          className={`${styles.focus} ${stylesPreferences.message}`}
                      />
                  </div>
              </div>
              <div className={`${styles.componentBox} ${stylesPreferences.boxMessage}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.alignMessage}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Mensaje de advertencia:</label>
                      <textarea
                          name="msgBW"
                          minLength={11}
                          maxLength={216}
                          value={RSPreferences?.msgBW ? RSPreferences?.msgBW : ''}
                          onChange={handleInputChange}
                          className={`${styles.focus} ${stylesPreferences.message}`}
                      />
                  </div>
              </div>
              <div className={`${styles.componentBox} ${stylesPreferences.boxMessage}`}>
                  <div className={`${styles.containerDiv} ${stylesPreferences.alignMessage}`}>
                      <label className={`${styles.sliderLabel} ${stylesPreferences.labelPref}`}>Mensaje de bloqueo:</label>
                      <textarea
                          name="msgBT"
                          minLength={11}
                          maxLength={216}
                          value={RSPreferences?.msgBT ? RSPreferences?.msgBT : ''}
                          onChange={handleInputChange}
                          className={`${styles.focus} ${stylesPreferences.message}`}
                      />
                  </div>
              </div>
            </>}
        </div>
        <div className={`end-footer-body ${stylesPreferences.btnEnd}`}>
            <Button onClick={() => saveRSPreferences(RSPreferences, preferencesToCompare)} variant="dark">
                <i className="fas fa-save" style={{ marginRight: '0.5rem' }}></i>
                Guardar
            </Button>
        </div>
      </div>
    </div>
    )
  }
}

import React from 'react'
import { Avatar } from 'primereact/avatar'
import '../../css/generals/boxCharts.css'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
export default function BoxChart ({ width, height, margin, padding, heightHeader, icon, title, heightBody, children, className }) {
  return (
    <div
      className={`containerBox ${className}`}
      style={{
        width,
        height,
        margin,
        padding
      }}
    >
      <div className="headerBox" style={{ height: heightHeader }}>
        <div className="content-avatar-title">
          <Avatar
            icon={icon}
            className="mr-2"
            size="large"
            style={{ backgroundColor: '#E4E4E4', color: 'black' }}
            shape="circle"
          />
          <h5>{title}</h5>
        </div>
      </div>
      <div className="bodyBox" style={{ height: heightBody }}>
        {children}
      </div>
    </div>
  )
}

BoxChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  heightHeader: PropTypes.string,
  heightBody: PropTypes.string,
  className: PropTypes.string
}

import React, { useState, useEffect } from 'react'
import MapaGeofences from '../generals/mapGeofences'
import { config } from '../../config'
import styles from '../../css/geofences/FormFence.module.css'
import PropTypes from 'prop-types'

export default function FormAddFence ({ setFenceData, type, fenceData }) {
  const [results, setResults] = useState([])
  const [unitArea, setUnitArea] = useState('m')
  const [geofenceData, setGeofenceData] = useState({
    name: type === 'edit' ? fenceData.name : null,
    description: type === 'edit' ? fenceData.description : null,
    lat: type === 'edit' ? fenceData.lat : config.map.center[0],
    lon: type === 'edit' ? fenceData.lon : config.map.center[1],
    place: type === 'edit' ? fenceData.place : null,
    area: type === 'edit' ? fenceData.area : 1,
    color_range: type === 'edit' ? fenceData.color_range : '#3388ff'
  })
  const [loading, setLoading] = useState('')
  const searchInputChanges = []

  useEffect(() => {
    setFenceData(geofenceData)
    // eslint-disable-next-line
  }, [geofenceData]);

  const handleInputChange = (e) => {
    setGeofenceData({
      ...geofenceData,
      [e.target.name]: e.target.value
    })
  }

  const getRequest = async (query) => {
    if (query.length !== 0) {
      try {
        const response = await fetch(
          `https://us1.locationiq.com/v1/search?key=${config.map.api_key}&format=json&q=${query}`
        )
        if (response.status < 300) {
          const data = await response.json()
          setResults(data)
        }
      } catch (error) {
        console.error('Error en la solicitud de búsqueda:', error)
      }
    }
  }

  useEffect(() => {
    getRequest(loading)
  }, [loading])

  const handleSearch = async (e) => {
    const query = e.target.value?.trim()

    setGeofenceData({
      ...geofenceData,
      place: e.target.value.trim()
    })

    if (searchInputChanges.length > 0) {
      searchInputChanges.forEach((inputChange) => {
        clearTimeout(inputChange)
      })
    }
    searchInputChanges.push(
      setTimeout(() => {
        setLoading(query)
      }, 1000)
    )
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
  }

  const handleSelectPlace = (result) => {
    const placeInput = type === 'add' ? document.querySelector('#place') : document.querySelector('#editPlace')
    placeInput.value = result.display_name
    setGeofenceData({
      ...geofenceData,
      place: result.display_name,
      lat: result.lat,
      lon: result.lon
    })

    setResults([])
  }

  const handleAreaChange = (e) => {
    setGeofenceData({
      ...geofenceData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className={styles.container_map}>
      <form onSubmit={handleOnSubmit} autoComplete="off">
        <div style={{ height: '26rem' }}>
          <MapaGeofences
            info={geofenceData}
            type={type}
            setGeofenceData={setGeofenceData}
            unitArea={unitArea}
          />
        </div>
        <label htmlFor="name" className={styles.label_block}>
          Nombre de la Geocerca
        </label>
        <input
          className={styles.input_width}
          type="text"
          name="name"
          value={geofenceData.name}
          onChange={handleInputChange}
          placeholder="Nombre de la geocerca"
        />
        <br />

        <label htmlFor={type === 'add' ? 'place' : 'editPlace'} className={styles.label_block}>
          Ubicación
        </label>
        <input
          className={styles.input_width}
          type="text"
          name="place"
          onChange={handleSearch}
          placeholder="Buscar ubicación"
          value={type === 'edit' ? geofenceData.place : null}
          id={type === 'add' ? 'place' : 'editPlace'}
        />
        <p id={type === 'add' ? 'placeError' : 'editPlaceError'} className={styles.errorMessage}></p>

        <ul className={styles.places_list}>
          {results.map((result, index) => (
            <li className={styles.li} key={index} onClick={() => handleSelectPlace(result)}>
              {result.display_name}
            </li>
          ))}
        </ul>
        <br />

        <div className={styles.container_color}>
          <label htmlFor="color_range" className={styles.label_block}>Color</label>
          <input
            type="color"
            name="color_range"
            value={geofenceData.color_range}
            onChange={handleAreaChange}
          />
        </div>

        <div className={styles.container_inputs}>
          <label htmlFor="area" className={styles.label_block}>
            Rango
          </label>
          <div className={styles.container_flex}>
            <input
              className={styles.input_range}
              type="range"
              min="1"
              max="1000"
              name="area"
              value={geofenceData.area}
              onChange={handleAreaChange}
            />

            <div className={styles.container_range} style={{ display: 'flex' }}>
              <input
                type="number"
                name="area"
                value={geofenceData.area}
                onChange={handleAreaChange}
                min="1"
                max="1000"
                pattern="[0-9]*"
              />

              <select name="unit_area" value={unitArea} onChange={(e) => setUnitArea(e.target.value)}>
                <option name="m" value="m">m</option>
                <option name="km" value="km">km</option>
              </select>
            </div>
          </div>
        </div>

        <label htmlFor="description" className={styles.label_block}>
          <i className="fa fa-info-circle" aria-hidden="true" style={{ marginRight: '0.5rem' }}></i>
          Descripción
        </label>
        <textarea
          className={styles.input_width}
          name="description"
          placeholder="Descripción de la geocerca..."
          rows="4"
          onChange={handleInputChange}
          value={geofenceData.description}
          id={type === 'add' ? 'description' : 'editDescription'}
        />
        <p id={type === 'add' ? 'descriptionError' : 'editDescriptionError'} className={styles.errorMessage}></p>
      </form>
    </div>
  )
};

FormAddFence.propTypes = {
  setFenceData: PropTypes.func,
  fenceData: PropTypes.object,
  type: PropTypes.string
}

import React, { useEffect, useState } from 'react'
import stylesCardsInfo from '../../../css/generals/CardsInfo.module.css'
import PropTypes from 'prop-types'

export default function BatteryChart ({ statBattery, funcSokBattery, role, realTime }) {
  const [battery, setBattery] = useState()
  const [labelPercentageBattery, setLabelPercentageBattery] = useState('')
  const [dataBattery, setDataBattery] = useState()

  useEffect(() => {
    if (realTime) {
      funcSokBattery(setDataBattery)
    }
  }, [])

  //* primer efecto de la bateria
  useEffect(() => {
    if (realTime && statBattery && dataBattery === undefined) {
      let battery = statBattery?.lifePercent
      battery = parseInt(battery)
      // console.log(battery)
      if (!isNaN(battery) && battery >= 0 && battery <= 100) showBatteryLevel(statBattery, setBattery, setLabelPercentageBattery, role?.dashboardDevicePage.battery)
    }
  }, [])

  const showBatteryLevel = (data, set, setLabel, display) => {
    if (display) {
      const charge = document.getElementById('charge')
      const battery = document.getElementById('battery')
      const plug = document.getElementById('icon-plug')
      const bolt = document.getElementById('icon-bolt')
      set(data)

      setLabel(data?.lifePercent + '%')
      const percentage = data?.lifePercent
      const chargeStatus = data?.chargeStatus
      if (percentage >= 0 && percentage <= 15) {
        battery?.classList.remove(`${stylesCardsInfo.blue}`)
        battery?.classList.remove(`${stylesCardsInfo.darkBlue}`)
        battery?.classList.add(`${stylesCardsInfo.red}`)
        plug.style.display = 'block'
      } else if (percentage > 15 && percentage <= 50) {
        battery?.classList.remove(`${stylesCardsInfo.red}`)
        battery?.classList.remove(`${stylesCardsInfo.darkBlue}`)
        battery?.classList.add(`${stylesCardsInfo.blue}`)
        plug.style.display = 'none'
      } else if (percentage > 50 && percentage <= 100) {
        battery?.classList.remove(`${stylesCardsInfo.red}`)
        battery?.classList.remove(`${stylesCardsInfo.blue}`)
        battery?.classList.add(`${stylesCardsInfo.darkBlue}`)
        plug.style.display = 'none'
      }

      if (chargeStatus.includes('Charging')) {
        charge?.classList.add(`${stylesCardsInfo.active}`)
        plug.style.display = 'none'
        if (percentage >= 0 && percentage <= 15)bolt?.classList.add(`${stylesCardsInfo.red}`)
        else if (percentage > 15 && percentage <= 50)bolt?.classList.add(`${stylesCardsInfo.blue}`)
        else bolt?.classList.add(`${stylesCardsInfo.darkBlue}`)
        bolt.style.display = 'block'
      } else {
        charge?.classList.remove(`${stylesCardsInfo.active}`)
        bolt.style.display = 'none'
      }
      charge.style.width = `${percentage}%`
    }
  }

  //* efecto de la bateria RealTime
  useEffect(() => {
    if (dataBattery !== undefined) {
      let battery = dataBattery?.lifePercent
      battery = parseInt(battery)
      if (!isNaN(battery) && battery >= 0 && battery <= 100) showBatteryLevel(dataBattery, setBattery, setLabelPercentageBattery, role?.dashboardDevicePage.battery)
    }
  }, [dataBattery])

  //* efecto de la bateria
  useEffect(() => {
    if (statBattery !== undefined) {
      let battery = statBattery?.lifePercent
      battery = parseInt(battery)
      // console.log(battery)
      if (!isNaN(battery) && battery >= 0 && battery <= 100) showBatteryLevel(statBattery, setBattery, setLabelPercentageBattery, role?.dashboardDevicePage.battery)
    }
  }, [statBattery])
  return (
    <>
      {battery === undefined && <h5 style={{ position: 'relative', top: '4rem', left: '2rem' }}>Sin data a mostrar</h5>}
      <div className={stylesCardsInfo.battery} id='battery'>
        <div className={`${stylesCardsInfo.charge}`} id='charge'></div>
      </div>
      <div className={stylesCardsInfo.chargeLevel}>
        <i className={`fas fa-plug ${stylesCardsInfo.plug}`} id='icon-plug'></i>
        <i className={`pi pi-bolt ${stylesCardsInfo.bolt}`} id='icon-bolt'></i>
        <p>{`${labelPercentageBattery}`}</p>
      </div>
    </>
  )
}
BatteryChart.propTypes = {
  role: PropTypes.object,
  statBattery: PropTypes.object,
  funcSokBattery: PropTypes.func,
  realTime: PropTypes.bool
}

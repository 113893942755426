import React from 'react'
import styles from '../../../../css/RxartSecure/MessagesRS.module.css'
import PropTypes from 'prop-types'

export default function MessagesRS ({ messages, setMessages }) {
  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value)

    setMessages({
      ...messages,
      [e.target.name]: e.target.value
    })
  }

  return (
            <div id="messagesRS" className={styles.messagesContainer}>
              <div className={styles.inputContainer}>
                  <label>Mensaje de instalación/desinstalación:<span className={styles.spanMessage}>(nivel: {`${messages.permission}`})</span></label>
                  <textarea className={styles.textareaRS} name="installUninstallMessage" minLength={1} value={messages.installUninstallMessage} onChange={handleInputChange}/>
              </div>
              <div className={styles.inputContainer}>
                  <label>Mensaje de advertencia:<span className={styles.spanMessage}>(nivel: {`${messages.permission}`})</span></label>
                  <textarea className={styles.textareaRS} name="warningMessage" minLength={1} value={messages.warningMessage} onChange={handleInputChange}/>
              </div>
              <div className={styles.inputContainer}>
                  <label>Mensaje de bloqueo:<span className={styles.spanMessage}>(nivel: {`${messages.permission}`})</span></label>
                  <textarea className={styles.textareaRS} name="lockMessage" minLength={1} value={messages.lockMessage} onChange={handleInputChange}/>
              </div>
            </div>
  )
}

MessagesRS.propTypes = {
  messages: PropTypes.object,
  setMessages: PropTypes.func
}

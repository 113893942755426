export function preferencesRxartSecureJson () {
  const items = {
    parameters: { // Parámetros
      name: 'parameters',
      label: 'configuración de los parámetros'
    },
    messages: { // Mensajes
      name: 'messages',
      label: 'configuración de los mensajes'
    }
  }

  return (items)
}

export function preferencesSettingsJson () {
  const items = {
    times: { // tiempo de conexión y tiempo de persistencia
      name: 'times',
      label: 'editar tiempos de conexión y de peristencia'
    },
    locations: { // otros tipos de ubicaciones, ubicación de google, tiempo de ubicaciones
      name: 'locations',
      label: 'editar configuraciones de ubicaciones'
    },
    reports: {
      name: 'reports',
      label: 'recibir los reportes de aplicaciones y estadísticas'
    }
  }

  return (items)
}

/* eslint-disable react/style-prop-object */
import React from 'react'
import styles from '../../css/index/CardFunction.module.css'
import PropTypes from 'prop-types'

export default function CardFunction ({ icon, text }) {
  return (
        <div className={styles.contentCard}>
            <img src={icon} alt="logo" />
            <p>{text}</p>
        </div>
  )
}

CardFunction.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string
}

// import Swal from "sweetalert2";
import { redirectIndex } from '../../components/generals/redirect'

export function errorHandling (response, petition) {
  const object = {
    error: true,
    message: response.message,
    code: response.response.status
  }

  if (response.response.data !== undefined) {
    if (response.response.data.redirect) {
      if (object.code === 403) {
        return redirectIndex('authenticationProblems')
      } else {
        return redirectIndex()
      }
    }
  }

  return object
}

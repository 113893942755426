import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import '../../css/generals/paper.css'
import styles from '../../css/modals/Modal.module.css'
import Swal from 'sweetalert2'
import { postData } from '../../actions/servicesHttp'
import { popupSimple } from '../../components/generals/popups'
import PropTypes from 'prop-types'

export default function ModalBackgroundChange ({ btnError, btnSuccess, title, show, onHide, type, identity, devices, socketSendActions, realTime }) {
  const { id } = useParams()
  const [files, setFiles] = useState(null)

  function closeModal () {
    onHide()
    setFiles(null)
  }

  function inputFileHandling () {
    const input = document.querySelector('#inputFileBC')

    input.addEventListener('change', e => {
      const span = document.querySelector('#fancy_file_name_document')

      if (input?.files?.length === 0) {
        span.innerHTML = 'Ningún archivo seleccionado'
      } else if (input?.files?.length > 0) {
        span.innerHTML = input.files[0].name
        setFiles(input.files)
      }
    })
  }

  const uploadImgs = async () => {
    const f = new FormData()

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        f.append('files', files[i])
      }

      let timerInterval

      Swal.fire({
        title: '¡Enviando solicitud!',
        html: '',
        timer: 30000,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(500)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

      const response = await postData('upload/wallpaper', f)

      if (response.data.length !== false) {
        let url = response.data.url
        url = url.trim()
        const splitUrl = url.split('/')
        installBackground(url, splitUrl)
        setFiles(null)
        closeModal()
      } else {
        popupSimple('error', 'El proceso no pudo ser completado!')
      }
    } else {
      popupSimple('info', 'No se seleccionó ningún archivo!')
    }
  }

  const installBackground = async (url, fileName) => {
    const idsDevices = []
    devices?.forEach(devices => { idsDevices.push(devices.id) })

    const json = {
      action: 'install',
      data: {
        fileName: fileName[fileName.length - 2] + '.' + fileName[fileName.length - 1],
        url,
        type: 'wallpaper'
      },
      date: new Date(Date.now()),
      devicesId: type === 'devices' ? [parseInt(id)] : [],
      groupsId: type === 'groups' ? [parseInt(id)] : []
    }

    if (realTime) {
      socketSendActions([json])
      popupSimple('success', 'Acción realizada con éxito!')
    } else {
      const { data } = await postData('actions', [json])
      if (type === 'devices') {
        if ((data[0].result === true) && (Array.isArray(data[0].sendings)) && (data[0].sendings.length === 0)) {
          popupSimple('success', 'Solicitud enviada con éxito!')
        } else if ((data[0].result === true) && (Array.isArray(data[0].sendings)) && (data[0].sendings.length > 0) && (data[0].sendings.includes(identity))) {
          popupSimple('success', 'Acción realizada con éxito!')
        } else {
          popupSimple('error', 'Error. No se pudo realizar la acción!')
        };
      } else {
        if ((data[0].result === true)) {
          popupSimple('success', 'Solicitud enviada con éxito!')
        } else {
          popupSimple('error', 'Error. No se pudo realizar la acción!')
        }
      }
    }
  }

  return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                key={'backgroundChange'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title" className={styles.modalTitle}>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.modalBackgroundChangeBody}>
                        <label>Seleccionar la imagen de fondo de pantalla:</label>
                        <input type="file" accept="image/*" id="inputFileBC" name="files" className={styles.fancy_file}/>
                        <label htmlFor="inputFileBC" className={styles.fancy_file_label}>
                            <span className={styles.fancy_file_button} onClick={() => inputFileHandling()}>
                                <i className="pi pi-download"></i></span>
                            <span className={styles.fancy_file_name}>
                                <span id="fancy_file_name_document">Ningún archivo seleccionado</span>
                            </span>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => closeModal()} variant="danger">
                        {btnError}
                    </Button>
                    <Button onClick={() => uploadImgs()} variant="primary" id="btnModalSucess" >
                        {btnSuccess}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  )
}

ModalBackgroundChange.propTypes = {
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.string,
  identity: PropTypes.string,
  devices: PropTypes.array,
  socketSendActions: PropTypes.func,
  realTime: PropTypes.bool
}

import React from 'react'
import stylesOptions from '../../../../css/Roles/OptionsSectionsTree.module.css'
import PropTypes from 'prop-types'
import { selectedAll, checkBoxs } from '../utils/utils'

export default function DynamicCheckBox ({ checks, setChecks, component, role, setRole, label, setCheckSelectAll, edit }) {
  const onChangeCheck = (value, array, label, setCheckBoxSelectAll) => {
    const labelsChecks = checkBoxs()
    const numberOfLabels = labelsChecks.length
    let arrayChecks = array
    if (value === true && !array?.includes(label)) {
      arrayChecks = array
      arrayChecks.push(label)
      setChecks(arrayChecks)
    }

    if (value === false && array?.includes(label)) {
      arrayChecks = array
      arrayChecks = arrayChecks.filter(element => element !== label)
      setChecks(arrayChecks)
    }

    const selectAllValidation = arrayChecks?.length === numberOfLabels

    if (selectAllValidation) {
      setCheckBoxSelectAll(true)
    } else {
      setCheckBoxSelectAll(false)
    }
  }

  return (
    <input type='checkbox' name={`checkBox_${label}`} id={`checkBox_${label}`} value={`checkBox_${label}`} className={stylesOptions.checkBox} checked={checks?.includes(label)} onClick={(e) => {
      selectedAll(e.target.checked, role, setRole, component, label, edit)
      onChangeCheck(e.target.checked, checks, label, setCheckSelectAll)
    }} />
  )
}

DynamicCheckBox.propTypes = {
  checks: PropTypes.array,
  setChecks: PropTypes.func,
  component: PropTypes.string,
  role: PropTypes.object,
  setRole: PropTypes.func,
  inputs: PropTypes.array,
  label: PropTypes.string,
  setCheckSelectAll: PropTypes.func,
  edit: PropTypes.bool
}

import React from 'react'
import Button from '../../generals/button'
import DataTableDemo from '../../generals/datatables/datatable'
import styles from '../../../css/Dashboards/BtnAndTable.module.css'
import store from '../../../reducer/store'
import DataTableLazyDevices from '../../generals/datatables/dataTableLazyDevices'
import PropTypes from 'prop-types'

export default function ButtonsAndTables ({ table, title, add, remove, type, rowStates, editUsers, actionDelete, actionDashboard, onSelecteds, search, columns, tableData, addBtn, removeBtn, exportPdf, exportCsv, rowSelecteds, columnStatus }) {
  const { user } = store.getState()

  return (
    <div>
      <div
        className={styles.contenButtons}
        style={{
          display: user.range === 1 ? 'flex' : 'none'
        }}
      >
        {addBtn && <Button
          title="Agregar"
          className="btn btn-primary"
          icon={<i className="fa-solid fa-plus"></i>}
          click={add}
        />}
        {removeBtn && <Button
          title="Eliminar"
          className={`btn btn-danger ${
            tableData && tableData.length === 0 ? styles.disabled : ''
          }`}
          icon={<i className="fas fa-trash"></i>}
          click={remove}
        />}
      </div>
      <div className={styles.dataTable} style={{ marginBottom: '1rem' }}>
        {type === 'dataTableDemo'
          ? (
          <DataTableDemo
            data={tableData}
            table={table}
            actionDashboard={actionDashboard}
            actionDelete={actionDelete}
            searchs={search}
            rowStates={rowStates}
            editUsers={editUsers}
            onSelecteds={onSelecteds}
            title={title}
            columns={columns}
            exportPdf={exportPdf}
            exportCsv={exportCsv}
            rowSelecteds={rowSelecteds}
            columnStatus={columnStatus}
          />
            )
          : (
          <DataTableLazyDevices
            table={table}
            onSelecteds={onSelecteds}
            devicesGroup={tableData}
          />
            )}
      </div>
    </div>
  )
}

ButtonsAndTables.propTypes = {
  table: PropTypes.string,
  title: PropTypes.string,
  add: PropTypes.func,
  remove: PropTypes.func,
  type: PropTypes.string,
  rowStates: PropTypes.bool,
  editUsers: PropTypes.bool,
  actionDelete: PropTypes.bool,
  actionDashboard: PropTypes.bool,
  onSelecteds: PropTypes.func,
  search: PropTypes.array,
  columns: PropTypes.array,
  tableData: PropTypes.array,
  addBtn: PropTypes.bool,
  removeBtn: PropTypes.bool,
  exportPdf: PropTypes.bool,
  exportCsv: PropTypes.bool,
  rowSelecteds: PropTypes.bool,
  columnStatus: PropTypes.array
}

import React from 'react'
import PropTypes from 'prop-types'
import BoxChart from '../../generals/boxCharts'
import Pie from '../../generals/charts/pie'
import KnobChart from '../../generals/charts/knob'
import VerticalBar from '../../generals/charts/verticalBar'
import stylesCardsInfo from '../../../css/generals/CardsInfo.module.css'
import BatteryChart from '../../generals/charts/battery'

export default function CardsStatsRealTime ({ stat, funcSokDisk, funcSokRam, funcSokBattery, connections, labelConn, role }) {
  return (
        <div className={stylesCardsInfo.cardsContainer}>
          {role?.dashboardDevicePage.diskChartDashboardDevice && <BoxChart
            title="Disco"
            icon="fa-solid fa-floppy-disk"
            className={`${stylesCardsInfo.boxChart}`}>
            <div className={stylesCardsInfo.containerBoxChart}>
              <Pie
                type={'individual'}
                //* se agrega validacion para tiempo real para que no se rompar en otros lugares
                realTime={true}
                funcSokDisk={funcSokDisk}
                data={stat}
              />
            </div>
          </BoxChart>}
          {role?.dashboardDevicePage.ramChartDashboardDevice && <BoxChart
            title="Ram"
            icon="fa-solid fa-memory"
            className={`${stylesCardsInfo.boxChart}`}
          >
            <div className={stylesCardsInfo.containerBoxChart}>
              <KnobChart
                type="individual"
                size={230}
                //* se agrega validacion para tiempo real para que no se rompar en otros lugares
                realTime={true}
                funcSokRam={funcSokRam}
                data={stat}
              />
            </div>
          </BoxChart>}
          {role?.dashboardDevicePage.battery && <BoxChart
            title="Bateria"
            icon="fas fa-battery-full"
            className={`${stylesCardsInfo.boxChart}`}
            >
              <div className={stylesCardsInfo.batteryContainer}>
                {/* se armo un componente de bateria para tenerlo separado */}
                <BatteryChart
                  role={role}
                  //* se agrega validacion para tiempo real para que no se rompar en otros lugares
                  realTime={true}
                  funcSokBattery={funcSokBattery}
                  statBattery={stat?.Battery}
                />
              </div>
            </BoxChart>}
            {role?.dashboardDevicePage.connectionsVerticalBar && <BoxChart
              title="Conexiones"
              icon="fa-solid fa-mobile"
              className={`${stylesCardsInfo.boxChart}`}
            >
              {connections === undefined || connections?.error || connections.length === 0
                ? (
                <h5>Sin data a mostrar</h5>
                  )
                : labelConn
                  ? (
                <VerticalBar
                  data={labelConn.data}
                  labels={labelConn.labels}
                  backgroundColor={labelConn.backgroundColor}
                  title={'Conexiones'}
                />
                    )
                  : (<></>)}
            </BoxChart>}
        </div>
  )
}

CardsStatsRealTime.propTypes = {
  stat: PropTypes.object,
  funcSokRam: PropTypes.func,
  funcSokDisk: PropTypes.func,
  funcSokBattery: PropTypes.func,
  connections: PropTypes.array,
  labelConn: PropTypes.object,
  role: PropTypes.object
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProgressSpinner } from 'primereact/progressspinner'
import Info from '../info'
import styles from '../../../css/generals/CardsInfo.module.css'

export default function CardsInfo ({ device, infoCards, role, socketReceiveActionsCompleted, realTime, setFlagReload }) {
  useEffect(() => {
    if (realTime) {
      socketReceiveActionsCompleted(setFlagReload)
    }
  }, [realTime])

  return (
        <div>
            {device === undefined
              ? (<div className={styles.flexContainer}>
                    <ProgressSpinner />
                </div>)
              : device?.error
                ? (<div className={styles.flexContainer}>
                    <h5>Sin data a mostrar</h5>
                </div>)
                : (<div className={styles.cardsContainer}>
              {device &&
                infoCards.map((el) => {
                  if (el.compare) {
                    if (Array.isArray(el.key)) {
                      const value = ((device.status_lock === false && device.motive_lock === null))
                        ? 'Activo'
                        : (device.status_lock === false && device.motive_lock === 'locked')
                            ? 'Pendiente de Bloqueo'
                            : (device.status_lock === true && device.motive_lock === 'locked')
                                ? 'Bloqueado'
                                : (device.status_lock === true && device.motive_lock === null)
                                    ? 'Pendiente de Desbloqueo'
                                    : (device.status_lock === false && device.motive_lock === 'disabled')
                                        ? 'Pendiente de Desactivación'
                                        : (device.status_lock === true && device.motive_lock === 'disabled')
                                            ? 'Desactivado'
                                            : (device.motive_lock === 'activated')
                                                ? 'Pendiente de Activación'
                                                : ''
                      const icon = (value === 'Bloqueado' || value === 'Desactivado') ? el.icon[0] : value === 'Activo' ? el.icon[1] : el.icon[2]
                      const key = 'statusLock'
                      return (
                        <Info
                          key={key}
                          title={el.title}
                          value={value}
                          icon={icon}
                          className={`${styles.boxChart}`}
                        />
                      )
                    } else {
                      let text = device?.so
                      const textArray = text?.split('_-_')
                      text = textArray[0] + ' ' + textArray[1]
                      return (
                            <Info
                              key={el.key}
                              title={el.title}
                              value={text}
                              icon={device?.so?.includes('Android') ? el.icon[0] : el.icon[1]}
                              className={`${styles.boxChart}`}
                            />
                      )
                    }
                  } else {
                    return (
                      <Info
                        key={el.key}
                        title={el.title}
                        value={device[el.key]}
                        icon={el.icon[0]}
                        className={`${styles.boxChart}`}
                      />
                    )
                  }
                })}
                </div>)
            }
        </div>
  )
}

CardsInfo.propTypes = {
  device: PropTypes.object,
  infoCards: PropTypes.array,
  role: PropTypes.object,
  socketReceiveActionsCompleted: PropTypes.function,
  realTime: PropTypes.bool,
  setFlagReload: PropTypes.func
}

import React, { useState, useEffect } from 'react'
import { getData, postData, putData } from '../../actions/servicesHttp'
import Button from 'react-bootstrap/Button'
import DataTableDemo from '../../components/generals/datatables/datatable'
import Header from '../../components/generals/header'
import styles from '../../css/geofences/Geofences.module.css'
import stylesIndex from './../../css/Index.module.css'
import iconMarker from '../../img/icon_geofence.svg'
import { formatDate } from '../../components/generals/charts/utils/DatesFormats'
import ModalFence from '../../components/modals/modalsGeofences/modalFence'
import ModalInformationFence from '../../components/modals/modalsGeofences/modalInformationFence'
import $ from 'jquery'
import Swal from 'sweetalert2'
import store from '../../reducer/store'

export default function Geofences () {
  const { user } = store.getState()
  const role = user.roles
  const [fences, setFences] = useState(null)
  const [fence, setFence] = useState(null)
  const [modals, setModals] = useState({
    create: false,
    edit: false,
    information: false
  })

  useEffect(() => {
    getGeofence()
  }, [])

  //* efecto de formateo de fecha de la fecha de creación
  useEffect(() => {
    if (fences) {
      const data = fences
      data.forEach((element) => {
        element.date_order = formatDate(element.date_order)
      })
      setFences(data)
    }
  }, [fences])

  const getGeofence = async () => {
    const response = await getData('geofences')
    if (response.data) setFences(response.data)
  }

  const nameFenceValidation = (name, id = null) => {
    let result = false
    if (name === '' || name === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No se puede crear geocercas sin nombre!',
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      let condition
      if (id) condition = (fence) => (fence.name === name.trim()) && (fence.id !== id)
      else condition = (fence) => fence.name === name.trim()
      if (fences?.some(condition)) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'La geocerca ya existe!',
          showConfirmButton: false,
          timer: 2500
        })
      } else {
        result = true
      }
    }
    return result
  }

  function validationsForms (type) {
    let inputAdress
    let inputDescription
    let response = true
    const messageRequire = 'Este campo es Obligatorio'
    const messageRequireHeightStreet = 'Debe ingresar una altura'

    if (type === 'add') {
      inputAdress = document.querySelector('#place')
      inputDescription = document.querySelector('#description')
    } else {
      inputAdress = document.querySelector('#editPlace')
      inputDescription = document.querySelector('#editDescription')
    }

    if (inputAdress.value === undefined || inputAdress.value === null || inputAdress.value === '') {
      $('#place').addClass('error')
      $('#placeError').text(messageRequire)
      $('#placeError').addClass('visible')
      response = false
    } else {
      let flag = false
      try {
        // const input = inputAdress.value
        // console.log(input)
        let prueba = inputAdress.value.split('Argentina')
        prueba = prueba[0].split(',')

        prueba.pop()
        prueba.pop()
        for (let index = 0; index < 2; index++) {
          for (let i = 0; i < 10; i++) {
          // console.log(i, prueba[index], prueba[index].includes(i))
            if (prueba[index].includes(i)) {
              if (!prueba[index].includes('Comuna')) {
                flag = true
                break
              }
            }
          }
        }
      } catch (error) {
        flag = false
      }
      if (!flag) {
        if (type === 'add') {
          $('#place').addClass('error')
          $('#placeError').text(messageRequireHeightStreet)
          $('#placeError').addClass('visible')
          response = false
        } else {
          $('#editPlace').addClass('error')
          $('#editPlaceError').text(messageRequireHeightStreet)
          $('#editPlaceError').addClass('visible')
          response = false
        }
      } else {
        if (type === 'add') {
          $('#place').removeClass('error')
          $('#placeError').text('')
          $('#placeError').removeClass('visible')
        } else {
          $('#editPlace').removeClass('error')
          $('#editPlaceError').text('')
          $('#editPlaceError').removeClass('visible')
        }
      }
    }

    if (inputDescription.value === undefined || inputDescription.value === null || inputDescription.value === '') {
      $('#description').addClass('error')
      $('#descriptionError').text(messageRequire)
      $('#descriptionError').addClass('visible')
      response = false
    } else {
      $('#description').removeClass('error')
      $('#descriptionError').text('')
      $('#descriptionError').removeClass('visible')
    }

    return response
  };

  const saveFence = async (info, type, id = null) => {
    $('#btnModalFence').prop('disabled', true)
    // console.log("data para crear la geocerca:", info);
    const name = info.name

    const json = {
      name,
      description: info.description,
      lat: info.lat,
      lon: info.lon,
      place: info.place,
      area: parseInt(info.area),
      color_range: info.color_range
    }

    if (nameFenceValidation(name, id) && validationsForms(type)) {
      let data
      let succcessTitle
      let errorTitle
      if (type === 'add') {
        succcessTitle = 'Geocerca creada con éxito!'
        errorTitle = 'Error al crear la geocerca!'
        data = await postData('geofences', json)
      } else {
        if (id) json.id = id
        succcessTitle = 'Geocerca actualizada con éxito!'
        errorTitle = 'Error al actualizar la geocerca!'
        data = await putData('geofences', json)
      }

      if (data.data.result) {
        if (type === 'add') {
          setModals({
            ...modals,
            create: false
          })
        } else {
          setModals({
            ...modals,
            edit: false
          })
        }
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: succcessTitle,
          showConfirmButton: false,
          timer: 1500
        })
        getGeofence()
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: errorTitle,
          showConfirmButton: false,
          timer: 2500
        })
      }
    }

    setTimeout(() => {
      $('#btnModalFence').prop('disabled', false)
    }, 1500)
  }

  const showModal = (type) => {
    setModals({
      ...modals,
      [type]: true
    })
  }

  const closeModal = (type) => {
    setModals({
      ...modals,
      [type]: false
    })
  }

  const getFence = (data) => {
    const fence = fences.filter((fence) => fence.id === data)
    setFence(fence[0])
  }

  return (
  <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
    <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Geocercas" />
        <section className={styles.containerToggle}>
        {role?.geofencesPage.newGeocerca && <Button
            className={styles.btnDark}
            onClick={() => showModal('create')}
            variant="dark"
            style={{ marginLeft: '1rem' }}
          >
            <img
              src={iconMarker}
              alt="map-marker"
              style={{ marginRight: '0.5rem', width: '1.5rem' }}
            />
            Crear Geocerca
          </Button>}
        </section>
        <section>
          <div>
            <DataTableDemo
              data={fences}
              table="geofences"
              // onSelecteds={handleChange}
              reload={getGeofence}
              rowStates={false}
              rowSelecteds={false}
              editUsers={false}
              actionDashboard={false}
              optionsMenu={role?.geofencesPage.seeInformationGeocerca || role?.geofencesPage.editGeocerca}
              informationOptionMenu={role?.geofencesPage.seeInformationGeocerca}
              editOptionMenu={role?.geofencesPage.editGeocerca}
              exportsPdf={role?.geofencesPage.exportPdfInGeofences}
              exportCsv={role?.geofencesPage.exportCsvInDevices}
              actionDelete={role?.geofencesPage.removeGeocercas}
              showMenu={showModal}
              getFence={getFence}
              searchs={['name', 'description', 'type', 'place', 'date_order']}
              columns={[
                {
                  field: 'name',
                  header: 'Nombre'
                },
                {
                  field: 'description',
                  header: 'Descripción'
                },
                {
                  field: 'type',
                  header: 'Tipo'
                },
                {
                  field: 'place',
                  header: 'Ubicación'
                },
                {
                  field: 'date_order',
                  header: 'Fecha de creación'
                }
              ]}
            />
          </div>
        </section>

        {/* Modal para crear geofences */}
        <ModalFence
          title="Crear Geocerca"
          show={modals.create}
          onClose={closeModal}
          onClick={saveFence}
          buttonLabel="Crear"
          type="add"
        />

        {/* Modal para editar geofences */}
        <ModalFence
          title={`Editar ${fence?.name}`}
          show={modals.edit}
          onClose={closeModal}
          onClick={saveFence}
          buttonLabel="Actualizar"
          type="edit"
          data={fence}
        />

        {/* Modal para ver la información de una Fence */}
        <ModalInformationFence
          show={modals.information}
          title={`Geocerca ${fence?.name}`}
          onClose={closeModal}
          fence={fence}
        />
      </div>
    </div>
  )
}

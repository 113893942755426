export function homeJson () {
  const items = {
    diskChartHome: {
      name: 'diskChartHome',
      label: 'gráfico de disco'
    },
    ramChartHome: {
      name: 'ramChartHome',
      label: 'gráfico de ram'
    },
    connectionsChartHome: {
      name: 'connectionsChartHome',
      label: 'gráfico de conexiones de equipos'
    },
    appsUseTimeChartHome: {
      name: 'appsUseTimeChartHome',
      label: 'gráfico de uso de aplicaciones'
    },
    locationMapHome: {
      name: 'locationMapHome',
      label: 'mapa de ubicaciones de los equipos'
    }
  }

  return (items)
}

import React from 'react'
import PropTypes from 'prop-types'

const UserIcon = ({ className }) => {
  return (<svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.26434 0C7.47081 0.0595655 7.68605 0.07601 7.89435 0.133017C9.60933 0.601868 10.7641 2.28103 10.5806 4.04607C10.4023 5.76105 9.10978 7.10913 7.41271 7.34958C5.36154 7.6401 3.44192 6.13306 3.23947 4.07348C3.04104 2.05373 4.45819 0.279556 6.47172 0.027042C6.49292 0.024484 6.51813 0.0299655 6.52581 0H7.26398H7.26434Z" fill="url(#paint0_linear_538_27)"/>
  <path d="M6.90789 13.9998C5.02189 13.9998 3.13553 13.9998 1.24953 13.9998C0.90566 13.9998 0.753275 13.8434 0.75766 13.4962C0.79128 10.9667 2.63014 8.76825 5.11179 8.29392C5.40267 8.23837 5.69721 8.20548 5.99394 8.20439C6.58631 8.20256 7.17868 8.19817 7.77068 8.20439C10.1475 8.2296 12.2439 9.85468 12.8666 12.1503C12.9887 12.6005 13.0545 13.0588 13.0603 13.5251C13.064 13.8331 12.9014 13.9991 12.594 13.9994C10.6989 13.9994 8.80339 13.9994 6.90826 13.9994L6.90789 13.9998Z" fill="url(#paint1_linear_538_27)"/>
  <defs>
  <linearGradient id="paint0_linear_538_27" x1="3.22083" y1="3.6927" x2="10.5996" y2="3.6927" gradientUnits="userSpaceOnUse">
  <stop stopColor="#0789E2"/>
  <stop offset="0.94" stopColor="#00D1C0"/>
  </linearGradient>
  <linearGradient id="paint1_linear_538_27" x1="0.75766" y1="11.1004" x2="13.0603" y2="11.1004" gradientUnits="userSpaceOnUse">
  <stop stopColor="#0789E2"/>
  <stop offset="0.94" stopColor="#00D1C0"/>
  </linearGradient>
  </defs>
  </svg>
  )
}

export default UserIcon

UserIcon.propTypes = { className: PropTypes.string }

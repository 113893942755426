export function dashboardDevicesJson () {
  const items = {
    idCard: {
      name: 'idCard',
      label: 'card con el identificador del dispositivo'
    },
    lastConnectionCard: {
      name: 'lastConnectionCard',
      label: 'card con la última conexión del dispositivo'
    },
    soCard: {
      name: 'soCard',
      label: 'card con el sistema operativo del dispositivo'
    },
    statusDeviceCard: {
      name: 'statusDeviceCard',
      label: 'card con el estado del dispositivo'
    },
    diskChartDashboardDevice: {
      name: 'diskChartDashboardDevice',
      label: 'gráfico de disco'
    },
    ramChartDashboardDevice: {
      name: 'ramChartDashboardDevice',
      label: 'gráfico de ram'
    },
    battery: {
      name: 'battery',
      label: 'estado de la batería'
    },
    lastLocationMap: {
      name: 'lastLocationMap',
      label: 'mapa con la última ubicación del dispositivo'
    },
    moreLocationsButton: {
      name: 'moreLocationsButton',
      label: 'mostrar más ubicaciones del dispositivo'
    },
    connectionsVerticalBar: {
      name: 'connectionsVerticalBar',
      label: 'gráfico de barras verticales con las últimas conexiones del dispositivo'
    },
    // buttonSpeedDial: {
    //   name: 'buttonSpeedDial',
    //   label: 'mostrar el botón del menu de accciones'
    // },
    actionShutdown: {
      name: 'actionShutdown',
      label: 'apagar dispositivo'
    },
    actionReboot: {
      name: 'actionReboot',
      label: 'reiniciar dispositivo'
    },
    actionWipe: {
      name: 'actionWipe',
      label: 'formatear de fábrica el dispositivo'
    },
    // actionErrorLogs: {
    //   name: 'actionErrorLogs',
    //   label: 'mostrar los logs de errores del dispositivo'
    // },
    // actionSendCommand: {
    //   name: 'actionSendCommand',
    //   label: 'enviar commandos al dispositivo'
    // },
    actionSendMessage: {
      name: 'actionSendMessage',
      label: 'enviar mensaje'
    },
    actionContentDownload: {
      name: 'actionContentDownload',
      label: 'descargar contenido en el dispositivo'
    },
    actionBackgroundChange: {
      name: 'actionBackgroundChange',
      label: 'cambiar el fondo de pantalla en el dispositivo'
    },
    actionReports: {
      name: 'actionReports',
      label: 'ver los repostes del dispositivo'
    },
    actionLockDevice: {
      name: 'actionLockDevice',
      label: 'bloquear y desbloquear dispositivo'
    },
    actionActivateDevice: {
      name: 'actionActivateDevice',
      label: 'activar y desactivar dispositivo'
    },
    actionDeleteFolders: {
      name: 'actionDeleteFolders',
      label: 'eliminar carpetas del dispositivo'
    },
    actionApplications: {
      name: 'actionApplications',
      label: 'mostrar aplicaciones'
    },
    actionInstallApplications: {
      name: 'actionInstallApplications',
      label: 'instalar y desinstalar aplicaciones'
    },
    actionRxartSecure: {
      name: 'actionRxartSecure',
      label: 'administrar Rxart Secure'
    }
  }

  return (items)
}

import React, { useState, useEffect } from 'react'
import { getData } from '../../actions/servicesHttp'
import { modifyProxy } from '../../components/generals/toolsFunctions'
import DataTableDemo from '../../components/generals/datatables/datatable'
import AddElement from '../../components/modals/addElementSafeweb'
import Header from '../../components/generals/header'
import Button from 'react-bootstrap/Button'
import store from '../../reducer/store'
import styles from '../../css/devices/Devices.module.css'
import stylesIndex from '../../css/Index.module.css'

export default function Words () {
  const { user } = store.getState()
  const [words, setWords] = useState(null)
  const [modal, setModal] = useState({
    show: false,
    type: ''
  })

  const role = user.roles

  useEffect(() => {
    getWords()
  }, [])

  const getWords = async () => {
    const { data } = await getData('safeweb/keyWords')
    setWords(data)
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Palabras Claves" />

        {user.range !== 3 && role?.safeWebWordsPage.addWord &&
          <div className={`container-toggle ${styles.btns}`}>
            <Button
              onClick={() =>
                setModal({
                  show: true,
                  type: 'palabra'
                })
              }
              variant="dark"
            >
              <i
                className="fas fa-plus-circle"
                style={{ marginRight: '0.5rem' }}
              ></i>
              Nueva Palabra
            </Button>
          </div>}

        <DataTableDemo
          reload={getWords}
          data={words}
          table="keyWords"
          rowStates={false}
          exportsPdf={role?.safeWebWordsPage.exportPdfWords}
          exportCsv={role?.safeWebWordsPage.exportCsvWords}
          actionDelete={role?.safeWebWordsPage.deleteWords}
          actionDashboard={false}
          editUsers={false}
          searchs={['keyword']}
          columns={[
            {
              field: 'keyword',
              header: 'Palabra'
            },
            {
              field: 'percent',
              header: 'Porcentaje %'
            }
          ]}
        />
        {/* MODAL AGREGAR WORD */}
        <AddElement
          show={modal.show}
          action="keyWords"
          onHide={() =>
            setModal({
              type: '',
              show: false
            })
          }
          reload={getWords}
          type={modal.type}
          modifyProxy={modifyProxy}
        />
      </div>
    </div>
  )
}

export function treeJson () {
  const items = [
    {
      key: '0',
      label: 'Todo',
      icon: 'pi pi-fw pi-check-square',
      children: [
        {
          key: '1',
          label: 'Inicio',
          icon: 'pi pi-home'
        },
        {
          key: '2',
          label: 'Equipos',
          icon: 'pi pi-tablet',
          children: [
            {
              key: '2-0',
              label: 'Dashboard',
              icon: 'pi pi-circle'
            }
          ]
        },
        {
          key: '3',
          label: 'Grupos',
          icon: 'pi pi-users',
          children: [
            {
              key: '3-0',
              label: 'Dashboard',
              icon: 'pi pi-circle'
            }
          ]
        },
        {
          key: '4',
          label: 'Usuarios',
          icon: 'pi pi-user'
        },
        {
          key: '5',
          label: 'Roles',
          icon: 'pi pi-tags'
        },
        {
          key: '6',
          label: 'Geocercas',
          icon: 'pi pi-map-marker'
        },
        {
          key: '7',
          label: 'Aplicaciones',
          icon: 'pi pi-th-large'
        },
        {
          key: '8',
          label: 'Preferencias',
          icon: 'pi pi-fw pi-wrench',
          children: [
            {
              key: '8-0',
              label: 'Configuraciones',
              icon: 'pi pi-circle'
            },
            {
              key: '8-1',
              label: 'Reportes',
              icon: 'pi pi-circle'
            },
            {
              key: '8-2',
              label: 'Extravios',
              icon: 'pi pi-circle'
            },
            {
              key: '8-3',
              label: 'Tiempos de uso',
              icon: 'pi pi-circle'
            },
            {
              key: '8-4',
              label: 'Geocercas',
              icon: 'pi pi-circle'
            },
            {
              key: '8-5',
              label: 'Servidor',
              icon: 'pi pi-circle'
            },
            {
              key: '8-6',
              label: 'Rxart Secure',
              icon: 'pi pi-circle'
            }
          ]
        },
        {
          key: '9',
          label: 'Safeweb',
          icon: 'pi pi-fw pi-shield',
          children: [
            {
              key: '9-0',
              label: 'Inicio',
              icon: 'pi pi-circle'
            },
            {
              key: '9-1',
              label: 'Listas',
              icon: 'pi pi-circle'
            },
            {
              key: '9-2',
              label: 'Palabras claves',
              icon: 'pi pi-circle'
            },
            {
              key: '9-3',
              label: 'Preferencias',
              icon: 'pi pi-circle'
            }
          ]
        }
      ]
    }
  ]

  return (items)
}

export function optionsTreeJson () {
  const items = [
    { key: '0', json: 'all' },
    { key: '1', json: 'home' },
    { key: '2', json: 'devices' },
    { key: '2-0', json: 'dashboardDevice' },
    { key: '3', json: 'groups' },
    { key: '3-0', json: 'dashboardGroup' },
    { key: '4', json: 'users' },
    { key: '5', json: 'roles' },
    { key: '6', json: 'geofences' },
    { key: '7', json: 'applications' },
    { key: '8', json: 'preferences' },
    { key: '8-0', json: 'preferencesSettings' },
    { key: '8-1', json: 'preferencesReports' },
    { key: '8-2', json: 'preferencesLosses' },
    { key: '8-3', json: 'preferencesUseTime' },
    { key: '8-4', json: 'preferencesGeofence' },
    { key: '8-5', json: 'preferencesServer' },
    { key: '8-6', json: 'preferencesRxartSecure' },
    { key: '9', json: 'safeWeb' },
    { key: '9-0', json: 'safeWebHome' },
    { key: '9-1', json: 'safeWebLists' },
    { key: '9-2', json: 'safeWebWords' },
    { key: '9-3', json: 'safeWebPreferences' }
    // ,
    // { key: '10', json: 'reports' }
  ]

  return (items)
}

//* columnas por defecto para el dataTable de devices
export function defaulColumnsDataTableDevices () {
  const items = [
    { field: 'status_lock', header: 'Estado de bloqueo', sistem: 'all', disabled: true },
    { field: 'id_user', header: 'Usuario del Dispositivo', sistem: 'all', disabled: true },
    { field: 'so', header: 'Sistema Operativo', sistem: 'all', disabled: false },
    { field: 'model', header: 'Modelo', sistem: 'all', disabled: false },
    { field: 'serial_number', header: 'Número de Serie', sistem: 'all', disabled: false },
    { field: 'identity', header: 'Identificador', sistem: 'all', disabled: true },
    { field: 'last_date', header: 'Última Conexión', sistem: 'all', disabled: false }
  ]
  return (items)
}

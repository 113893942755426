import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { config } from '../../config'
import store from '../../reducer/store'

export function ConnectionManager () {
  const URL = config.backend.host + ':' + config.backend.portWs
  const { user } = store.getState()

  const { userId, token } = user

  const socket = io(URL, {
    autoConnect: false,
    withCredentials: true,
    transports: ['websocket'],
    allowedHeaders: ['authorization'],
    auth: { userId, token }
  })

  const [connection, setConnection] = useState(false)

  function connect () {
    socket.connect()
    setConnection(true)
  }

  function disconnect () {
    socket.disconnect()
  }

  useEffect(() => {
    if (!connection && !socket.connected) {
      connect()
      // console.log('se conecta')
      return () => disconnect()
    }
  }, [])

  return socket
}

export function Emmiter (socket, event, arg) {
  socket.emit(event, arg)
}

import { homeJson as home } from '../JSON/homeJson'
import { devicesJson as devices } from '../JSON/devicesJson'
import { groupsJson as groups } from '../JSON/groupsJson'
import { geofencesJson as geofences } from '../JSON/geofencesJson'
import { usersJson as users } from '../JSON/usersJson'
import { rolesJson as roles } from '../JSON/rolesJson'
import { applicationsJson as applications } from '../JSON/applicationsJson'
import { dashboardGroupsJson as dashboardGroup } from '../JSON/dashboardGroupsJson'
import { dashboardDevicesJson as dashboardDevice } from '../JSON/dashboardDeviceJson'
import { safeWebHomeJson as safeWebHome } from '../JSON/safeWebHomeJson'
import { safeWebListsJson as safeWebLists } from '../JSON/safeWebListsJson'
import { safeWebWordsJson as safeWebWords } from '../JSON/safeWebWordsJson'
import { safeWebPreferencesJson as safeWebPreferences } from '../JSON/safeWebPreferencesJson'
import { preferencesSettingsJson as preferencesSettings } from '../JSON/preferencesSettingsJson'
import { preferencesReportsJson as preferencesReports } from '../JSON/preferencesReportsJson'
import { preferencesLossesJson as preferencesLosses } from '../JSON/preferencesLossesJson'
import { preferencesUseTimeJson as preferencesUseTime } from '../JSON/preferencesUseTimeJson'
import { preferencesGeofenceJson as preferencesGeofence } from '../JSON/preferencesGeofenceJson'
import { preferencesRxartSecureJson as preferencesRxartSecure } from '../JSON/preferencesRxartSecureJson'
import { preferencesServerJson as preferencesServer } from '../JSON/preferencesServerJson'
import { defaulColumnsDataTableDevices } from '../JSON/treeJson'
// import { reportsJson as reports } from '../JSON/reportsJson'

export const getJson = (view) => {
  let json
  switch (view) {
    case 'home':
      json = home()
      break
    case 'devices':
      json = devices()
      break
    case 'groups':
      json = groups()
      break
    case 'geofences':
      json = geofences()
      break
    case 'users':
      json = users()
      break
    case 'roles':
      json = roles()
      break
    case 'applications':
      json = applications()
      break
    case 'dashboardGroup':
      json = dashboardGroup()
      break
    case 'dashboardDevice':
      json = dashboardDevice()
      break
    case 'safeWebHome':
      json = safeWebHome()
      break
    case 'safeWebLists':
      json = safeWebLists()
      break
    case 'safeWebWords':
      json = safeWebWords()
      break
    case 'safeWebPreferences':
      json = safeWebPreferences()
      break
    case 'preferencesSettings':
      json = preferencesSettings()
      break
    case 'preferencesReports':
      json = preferencesReports()
      break
    case 'preferencesLosses':
      json = preferencesLosses()
      break
    case 'preferencesUseTime':
      json = preferencesUseTime()
      break
    case 'preferencesGeofence':
      json = preferencesGeofence()
      break
    case 'preferencesRxartSecure':
      json = preferencesRxartSecure()
      break
    case 'preferencesServer':
      json = preferencesServer()
      break
      // case 'reports':
      //   json = reports()
      //   break
    default:
      break
  }

  return json
}

const jsonCreationWithSelectAllValue = (value, json) => {
  const jsonWithSelectAllValue = {}

  Object.keys(json).forEach(key => {
    if (key !== 'columDataTableDevices') jsonWithSelectAllValue[key] = value
    else {
      jsonWithSelectAllValue[key] = defaulColumnsDataTableDevices()
    }
  })

  return jsonWithSelectAllValue
}

export const selectedAll = (value, data, set, component, label, edit) => {
  // console.log(value, data, set, component, label, edit)

  const json = getJson(label)

  if (label === 'dashboardDevice' && edit === true) {
    json.buttonSpeedDial = true
  }

  if (label === 'dashboardGroup' && edit === true) {
    json.buttonSpeedDial = true
  }

  set({
    ...data,
    [label + 'Page']: jsonCreationWithSelectAllValue(value, json)
  })
}

export const selectedAllCheckBox = (value, set, role, title) => {
  const homeJson = home()
  const devicesJson = devices()
  const groupsJson = groups()
  const geofencesJson = geofences()
  const usersJson = users()
  const rolesJson = roles()
  const applicationsJson = applications()
  const dashboardGroupJson = dashboardGroup()
  const dashboardDeviceJson = dashboardDevice()
  const preferencesSettingsJson = preferencesSettings()
  const preferencesReportsJson = preferencesReports()
  const preferencesLossesJson = preferencesLosses()
  const preferencesUseTimeJson = preferencesUseTime()
  const preferencesGeofenceJson = preferencesGeofence()
  const preferencesRxartSecureJson = preferencesRxartSecure()
  const preferencesServerJson = preferencesServer()
  const safeWebHomeJson = safeWebHome()
  const safeWebListsJson = safeWebLists()
  const safeWebWordsJson = safeWebWords()
  const safeWebPreferencesJson = safeWebPreferences()
  // const reportsJson = reports()

  if (title === 'Editar rol') {
    dashboardGroupJson.buttonSpeedDial = true
    dashboardDeviceJson.buttonSpeedDial = true
  }

  set({
    ...role,
    homePage: jsonCreationWithSelectAllValue(value, homeJson),
    devicesPage: jsonCreationWithSelectAllValue(value, devicesJson),
    groupsPage: jsonCreationWithSelectAllValue(value, groupsJson),
    geofencesPage: jsonCreationWithSelectAllValue(value, geofencesJson),
    usersPage: jsonCreationWithSelectAllValue(value, usersJson),
    rolesPage: jsonCreationWithSelectAllValue(value, rolesJson),
    applicationsPage: jsonCreationWithSelectAllValue(value, applicationsJson),
    dashboardGroupPage: jsonCreationWithSelectAllValue(value, dashboardGroupJson),
    dashboardDevicePage: jsonCreationWithSelectAllValue(value, dashboardDeviceJson),
    preferencesSettingsPage: jsonCreationWithSelectAllValue(value, preferencesSettingsJson),
    preferencesReportsPage: jsonCreationWithSelectAllValue(value, preferencesReportsJson),
    preferencesLossesPage: jsonCreationWithSelectAllValue(value, preferencesLossesJson),
    preferencesUseTimePage: jsonCreationWithSelectAllValue(value, preferencesUseTimeJson),
    preferencesGeofencePage: jsonCreationWithSelectAllValue(value, preferencesGeofenceJson),
    preferencesRxartSecurePage: jsonCreationWithSelectAllValue(value, preferencesRxartSecureJson),
    preferencesServerPage: jsonCreationWithSelectAllValue(value, preferencesServerJson),
    safeWebHomePage: jsonCreationWithSelectAllValue(value, safeWebHomeJson),
    safeWebListsPage: jsonCreationWithSelectAllValue(value, safeWebListsJson),
    safeWebWordsPage: jsonCreationWithSelectAllValue(value, safeWebWordsJson),
    safeWebPreferencesPage: jsonCreationWithSelectAllValue(value, safeWebPreferencesJson)
    // reportsPage: jsonCreationWithSelectAllValue(value, reportsJson)
  })
}

export const checkBoxs = () => {
  const checks = ['home', 'devices', 'groups', 'geofences', 'users', 'roles', 'applications', 'dashboardGroup', 'dashboardDevice', 'safeWebHome', 'safeWebLists', 'safeWebWords', 'safeWebPreferences', 'preferencesSettings', 'preferencesReports', 'preferencesLosses', 'preferencesUseTime', 'preferencesGeofence', 'preferencesRxartSecure', 'preferencesServer']
  return checks
}

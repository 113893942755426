import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../css/modals/DeleteFolders.module.css'
import stylesNotifications from '../../../../css/preferences/Notifications.module.css'

export default function CheckDeleteFolders ({ onCheckboxChange }) {
  const [checkboxState, setCheckboxState] = useState({
    deleteImages: false,
    deleteVideos: false,
    deleteMusic: false,
    deleteDocuments: false,
    deleteDownloads: false,
    deleteAll: false
  })

  const handleCheckboxChange = ({ target }) => {
    const { name, checked } = target
    const updatedState = { ...checkboxState }

    if (name === 'deleteAll') {
      for (const key in updatedState) {
        updatedState[key] = checked
      }
    } else {
      updatedState[name] = checked
      updatedState.deleteAll = Object.values(updatedState).every((value) => value === true)
    }

    setCheckboxState(updatedState)
    onCheckboxChange(updatedState)
  }

  useEffect(() => {
    onCheckboxChange(checkboxState)
  }, [checkboxState, onCheckboxChange])

  return (
      <>
        <div className={styles.container_checkbox_delete_folders}>
          <label className={styles.subtitle_notifications}>
            Imágenes:
          </label>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="deleteImages"
              onChange={handleCheckboxChange}
              checked={checkboxState.deleteImages}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className={styles.container_checkbox_delete_folders}>
          <label className={styles.subtitle_notifications}>
            Videos:
          </label>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="deleteVideos"
              onChange={handleCheckboxChange}
              checked={checkboxState.deleteVideos}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className={styles.container_checkbox_delete_folders}>
          <label className={styles.subtitle_notifications}>
            Música:
          </label>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="deleteMusic"
              onChange={handleCheckboxChange}
              checked={checkboxState.deleteMusic}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className={styles.container_checkbox_delete_folders}>
          <label className={styles.subtitle_notifications}>
            Documentos:
          </label>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="deleteDocuments"
              onChange={handleCheckboxChange}
              checked={checkboxState.deleteDocuments}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className={styles.container_checkbox_delete_folders}>
          <label className={styles.subtitle_notifications}>
            Descargas:
          </label>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="deleteDownloads"
              onChange={handleCheckboxChange}
              checked={checkboxState.deleteDownloads}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className={styles.container_checkbox_delete_folders}>
          <label className={styles.subtitle_notifications}>
            Seleccionar todas:
          </label>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="deleteAll"
              onChange={handleCheckboxChange}
              checked={checkboxState.deleteAll}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </>
  )
}

CheckDeleteFolders.propTypes = {
  onCheckboxChange: PropTypes.func
}

export function preferencesGeofenceJson () {
  const items = {
    // notificationsGeofences: { // notificaciones por correo electrónico, notificaciones por mensajes SMS, frecuencia de las notificaciones
    //   name: 'notificationsGeofences',
    //   label: 'configuración de las notificaciones'
    // },
    actionsGeofences: { // acciones automáticas
      name: 'actionsGeofences',
      label: 'configuración de las acciones'
    }
    // ,
    // historyGeofences: { // histórico de geocercas
    //   name: 'historyGeofences',
    //   label: 'configuración del historial de geocercas '
    // }
  }

  return (items)
}

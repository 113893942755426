import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import DataTableDemo from '../../generals/datatables/datatable'
import { getData } from '../../../actions/servicesHttp'

export default function ModalListRoles ({ show, title, handleClose, setRolesUser, data }) {
  // modal que tiene que tener 2 botones y un datatable para listar los roles
  // hacer un get de roles y comparar si los roles que vienen por prop estan para ponerles un check
  // al apretar confirmar el rol se crea con un

  const [allRoles, setAllRoles] = useState()
  const [rolesUrl] = useState('roles')
  const [selected, Setselected] = useState([])
  const [loading, setLoading] = useState(false)

  //* llamador de datos de API
  const getRequest = async (url, set) => {
    const response = await getData(url)
    // console.log(response, url)
    if (response.status === 200) {
      set(response.data)
      setLoading(false)
    } else {
      set({ error: true, code: response.code })
    }
  }
  useEffect(() => {
    if (data) Setselected(data)
  }, [data])

  useEffect(() => {
    setLoading(true)
    if (show) { getRequest(rolesUrl, setAllRoles) }
  }, [rolesUrl, show])

  if (show) {
    return <Modal
    show={show}
    size="md"
    onHide={handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
   >
     <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <DataTableDemo
            loading={loading}
            onSelecteds={Setselected}
            selected = {selected}
            data={allRoles}
            rowSelecteds={true}
            actionDashboard={false}
            actionDelete={false}
            editUsers={false}
            rowStates={false}
            table="roles"
            searchs={['roleName']}
            columns={[
              {
                field: 'roleName',
                header: 'Nombre'
              }
            ]}
          />
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={ handleClose} variant="danger">
                Cerrar
            </Button>
            <Button onClick={() => {
              setRolesUser(selected)
              handleClose()
            }} variant="primary">
                Asignar
            </Button>
            </Modal.Footer>
    </Modal>
  }
}

ModalListRoles.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  setRolesUser: PropTypes.func,
  data: PropTypes.object
}

import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Tooltip } from 'primereact/tooltip'
import { ProgressSpinner } from 'primereact/progressspinner'
import PropTypes from 'prop-types'
import styles from '../../../css/modals/ModalRSUnlockCode.module.css'

export default function ModalRSUnlockCode ({ title, show, btnError, btnSuccess, code, setCode, onClick, closeModal, loading }) {
  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value)

    setCode({
      ...code,
      [e.target.name]: e.target.value
    })
  }

  return (
          <Modal
              show={show}
              size="sm"
              onHide={closeModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter" className={styles.titleModal}>
                      {title}
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: '1.5rem' }}>
                <div>
                    <div className={styles.inputContainer}>
                        <label>CT</label>
                        <input className={styles.input} value={code.ct} name="ct" id='inputCt' type="text" maxLength={8} onChange={handleInputChange} />
                        <p className={styles.errorMessage} id='errorCt'></p>
                    </div>
                    <div className={styles.inputContainer}>
                        <label>UC</label>
                        <input className={styles.input} value={code.uc} name='uc' id='inputUc' type="text" maxLength={8}onChange={handleInputChange} />
                        <p className={styles.errorMessage} id='errorUc'></p>
                    </div>
                    <Tooltip target=".prueba" position='bottom' content='Ingresa el número de serie para desbloquear el dispositivo' />
                    <div className={styles.loadingContainer}>
                        {loading && <ProgressSpinner style={{ width: '2.5rem', height: '2.5rem' }} strokeWidth="5" />}
                    </div>
                    <div className={styles.code} id='codeUnlock'>
                        <p className={styles.labelCodeUnlock}>Código de seguridad:</p>
                        <p id="ticketCodeUnlock" className={`prueba ${styles.codeUnlock}`}></p>
                    </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                  <Button onClick={onClick} variant="primary" id="btnSucessModalRSUnlockCode" >
                      {btnSuccess}
                  </Button>
                  <Button onClick={closeModal} variant="danger">
                      {btnError}
                  </Button>
              </Modal.Footer>
          </Modal>
  )
}

ModalRSUnlockCode.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string,
  code: PropTypes.object,
  setCode: PropTypes.func,
  onClick: PropTypes.func,
  closeModal: PropTypes.func,
  loading: PropTypes.bool
}

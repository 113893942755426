import React from 'react'
import { TabMenu } from 'primereact/tabmenu'
import PropTypes from 'prop-types'

export default function MenuRS ({ setLabelMenu, setSectionMenu }) {
  const items = [
    {
      label: 'Dispositivos',
      command: () => {
        setLabelMenu('dispositivos')
        setSectionMenu('dispositivos')
      }
    },
    {
      label: 'Parámetros',
      command: () => {
        setLabelMenu('parametros')
        setSectionMenu('parametros')
      }
    },
    {
      label: 'Mensajes',
      command: () => {
        setLabelMenu('mensajes')
        setSectionMenu('mensajes')
      }
    },
    {
      label: 'Acciones',
      command: () => {
        setLabelMenu('acciones')
        setSectionMenu('acciones')
      }
    }
  ]

  return (
        <>
            <TabMenu model={items} />
        </>
  )
}

MenuRS.propTypes = {
  setLabelMenu: PropTypes.func,
  setSectionMenu: PropTypes.func
}

import { useMap } from 'react-leaflet'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

const ChangeView = ({ center }) => {
  const geoMap = useMap()

  useEffect(() => {
    geoMap.flyTo(center)
  }, [geoMap, center])

  return null
}

export default ChangeView

ChangeView.propTypes = {
  center: PropTypes.array
}

import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import DataTableDemo from '../../generals/datatables/datatable'

export default function ModalRSTicketsIssued ({ title, show, onHide, btnError }) {
  return (
          <Modal
              show={show}
              size="lg"
              onHide={onHide}
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      {title}
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: '1.5rem' }}>
                <DataTableDemo
                    // data={}
                    table="ticketsIssuedRS"
                    editUsers={false}
                    rowStates={false}
                    rowSelecteds={false}
                    searchs={['date', 'ticketType', 'IP']}
                    columns={[
                      {
                        field: 'date',
                        header: 'Fecha'
                      },
                      {
                        field: 'ticketType',
                        header: 'Tipo de ticket'
                      },
                      {
                        field: 'IP',
                        header: 'IP'
                      }
                    ]}
                />
              </Modal.Body>
              <Modal.Footer>
                  <Button onClick={onHide} variant="danger">
                      {btnError}
                  </Button>
              </Modal.Footer>
          </Modal>
  )
}

ModalRSTicketsIssued.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  btnError: PropTypes.string
}

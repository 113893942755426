import React from 'react'
import PropTypes from 'prop-types'

const LogoPixart = ({ className }) => {
  return (
    <svg className={className} width="113" height="38" viewBox="0 0 113 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_677_195)">
<path d="M50.3438 14.4872C49.1525 13.4029 47.3451 12.8516 44.9687 12.8516C43.3282 12.8516 41.8274 12.9698 40.3812 13.214L40.0625 13.2678V32.2409H43.181V24.9198C43.6697 24.9904 44.1917 25.0027 44.6955 25.0027C47.1873 25.0027 49.2678 24.2394 50.5502 22.8572C51.5563 21.8083 52.0662 20.3907 52.0662 18.6399C52.0662 16.8891 51.4516 15.4593 50.3407 14.4872H50.3438ZM48.9492 18.749C48.9492 21.0465 47.459 22.312 44.7517 22.312C44.1128 22.312 43.5953 22.2736 43.1825 22.1968V15.6344C43.5695 15.5699 44.1917 15.5146 45.0233 15.5146C46.4892 15.5146 48.9507 15.9354 48.9507 18.749H48.9492Z" fill="white"/>
<path d="M57.5443 18.1992H54.3984V32.2409H57.5443V18.1992Z" fill="white"/>
<path d="M55.9649 12.9622C54.898 12.9622 54.0938 13.7761 54.0938 14.8558C54.0938 15.9354 54.8753 16.7218 55.9102 16.7218C56.4732 16.7218 56.9755 16.5206 57.3231 16.1566C57.6508 15.8141 57.8223 15.3503 57.8072 14.8496C57.8041 13.7562 57.0287 12.9622 55.9634 12.9622H55.9649Z" fill="white"/>
<path d="M71.958 18.1993H68.4449L66.4828 21.2064L66.2248 21.6287C65.9744 22.0388 65.7164 22.458 65.4584 22.9065C65.1322 22.3567 64.7968 21.8038 64.4144 21.2233L62.431 18.1993H58.845L63.5509 25.0827L58.625 32.241H62.1912L64.2034 29.0696L64.5449 28.5167C64.8029 28.1005 65.0517 27.6997 65.293 27.2835C65.6527 27.8978 66.0048 28.4799 66.4054 29.0757L68.4419 32.2395H72.0779L67.2157 25.0028L71.958 18.1978V18.1993Z" fill="white"/>
<path d="M83.2536 28.6748V23.7127C83.2536 19.9623 81.3476 17.8982 77.8876 17.8982C76.2183 17.8982 74.5718 18.3513 73.373 19.1391L73.1241 19.3034L73.9208 21.644L74.329 21.3768C75.2137 20.7978 76.4126 20.4507 77.5356 20.4507C79.2489 20.4507 80.1002 21.2938 80.1336 23.0262C77.0712 23.063 74.8541 23.7526 73.5399 25.0749C72.684 25.9365 72.25 27.0361 72.25 28.3462C72.25 30.4318 73.7584 32.5435 76.6402 32.5435C78.1153 32.5435 79.4841 31.986 80.4432 31.0246L80.5813 32.2425H83.545L83.463 31.7879C83.3219 30.9985 83.2521 29.9802 83.2521 28.6764L83.2536 28.6748ZM80.1882 25.465V27.3863C80.1882 27.5767 80.1472 27.8056 80.0729 28.0298C79.7679 28.9344 78.8482 29.9895 77.2639 29.9895C76.0939 29.9895 75.3958 29.2722 75.3958 28.0713C75.3958 27.4462 75.5886 26.9517 75.9846 26.5585C76.7176 25.8321 78.1289 25.4635 80.1806 25.4635H80.1882V25.465Z" fill="white"/>
<path d="M91.6723 17.8967C90.3065 17.8967 89.0667 18.6278 88.273 19.838L88.223 18.1993H85.3594L85.3867 18.6109C85.461 19.7381 85.4944 21.0067 85.4944 22.7252V32.241H88.613V24.7817C88.613 24.4023 88.663 24.0476 88.7146 23.7266C89.0227 22.0111 90.1047 20.9453 91.5372 20.9453C91.821 20.9453 92.0395 20.9668 92.2672 21.019L92.7315 21.125V18.0657L92.4432 17.9919C92.1792 17.9259 91.9561 17.8983 91.6738 17.8983L91.6723 17.8967Z" fill="white"/>
<path d="M102.249 20.8071V18.1993H98.8356V14.2708L95.7429 15.1078V18.1993H93.7109V20.8071H95.7429V27.6598C95.7429 29.4612 96.0692 30.6438 96.7581 31.3687C97.4061 32.1181 98.3956 32.5159 99.6187 32.5159C100.52 32.5159 101.323 32.3884 101.877 32.1581L102.129 32.0536L101.975 29.495L101.523 29.6194C101.274 29.6885 100.849 29.7715 100.242 29.7715C99.511 29.7715 98.8356 29.5058 98.8356 27.5799V20.8086H102.249V20.8071Z" fill="white"/>
<path d="M111.148 13.4764C111.059 13.0787 110.902 12.8483 110.632 12.7208C110.998 12.5857 111.223 12.3092 111.223 11.9867C111.223 11.6949 111.104 11.4277 110.902 11.2695C110.667 11.1313 110.394 11.0376 109.823 11.0376C109.359 11.0376 108.952 11.0698 108.647 11.1328L108.609 11.1405V14.3027H109.408V13.0664H109.693C110.054 13.0664 110.224 13.1954 110.277 13.5179C110.294 13.5839 110.311 13.6484 110.326 13.7083C110.396 13.9863 110.446 14.186 110.528 14.2858L110.541 14.3027H111.411L111.362 14.2274C111.291 14.1184 111.235 13.9218 111.147 13.4749L111.148 13.4764ZM110.368 12.062C110.368 12.4244 109.91 12.4643 109.713 12.4643H109.427V11.6703C109.491 11.6581 109.603 11.6427 109.769 11.6427C110.156 11.6427 110.37 11.7917 110.37 12.0635L110.368 12.062Z" fill="white"/>
<path d="M109.842 9.52026C108.089 9.52026 106.664 10.9209 106.664 12.6425C106.664 14.3641 108.06 15.8016 109.842 15.8016C111.623 15.8016 113.001 14.4133 113.001 12.6425C113.001 10.8718 111.614 9.52026 109.842 9.52026ZM112.129 12.661C112.129 14.0002 111.133 15.0491 109.862 15.0491C108.59 15.0491 107.538 14.014 107.538 12.641C107.538 11.268 108.55 10.2344 109.843 10.2344C111.136 10.2344 112.13 11.2772 112.13 12.661H112.129Z" fill="white"/>
<path d="M9.14844 21.4934V7.95538L17.3628 0H29.0934L36.6036 7.83866V19.7088L28.8612 28.1402H15.2489V22.7972H26.6304L31.2073 17.9288L31.3257 10.2099L26.5151 5.34454H19.4737L14.5463 10.3312V21.4934H9.14844Z" fill="#CB4017"/>
<path d="M8.56343 22.6804H0V28.1401H8.56343V22.6804Z" fill="#CB4017"/>
<path d="M9.14844 28.9727H14.5463V38H9.14844V29.4441" fill="#CB4017"/>
</g>
<defs>
<clipPath id="clip0_677_195">
<rect width="113" height="38" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default LogoPixart

LogoPixart.propTypes = { className: PropTypes.string }

import React from 'react'
import { SplitButton } from 'primereact/splitbutton'
import PropTypes from 'prop-types'
// import { useNavigate } from "react-router-dom";

export default function ActionOptionsMenuGeofence ({ onShowModal, onGetFence, id, informationOptionMenu, editOptionMenu }) {
  // const navigate = useNavigate();
  const items = []

  if (informationOptionMenu) {
    items.push({
      label: 'Información',
      icon: 'pi pi-info-circle',
      command: () => {
        onShowModal('information')
        onGetFence(id)
      }
    })
  }
  if (editOptionMenu) {
    items.push({
      label: 'Editar',
      icon: 'fa-solid fa-pen-to-square',
      command: () => {
        onShowModal('edit')
        onGetFence(id)
      }
    })
  }

  // {
  //   label: "Historial",
  //   icon: "pi pi-map-marker",
  //   command: () => {
  //     //
  //   },
  // },
  // {
  //   label: "Eventos",
  //   icon: "pi pi-calendar",
  //   command: () => {
  //     //
  //   },
  // },
  // {
  //   label: "Notificaciones",
  //   icon: "pi pi-bell",
  //   command: () => {
  //     //
  //   },
  // },
  // {
  //   label: "Configuración",
  //   icon: "pi pi-cog",
  //   command: () => {
  //     navigate(`/mdm/config-geofence/${props.id}`);
  //   },
  // },

  return (
    <>
      <SplitButton dropdownIcon="pi pi-ellipsis-h" model={items} />
    </>
  )
}

ActionOptionsMenuGeofence.propTypes = {
  onShowModal: PropTypes.func,
  onGetFence: PropTypes.func,
  id: PropTypes.number,
  informationOptionMenu: PropTypes.bool,
  editOptionMenu: PropTypes.bool
}

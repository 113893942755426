export function preferencesLossesJson () {
  const items = {
    defaultConfiguration: { // usar configuración por defecto
      name: 'defaultConfiguration',
      label: 'utilizar configuración por defecto'
    },
    editLockSettings: { // bloqueo de táctil, bloqueo de pantalla, cambiar fondo de pantalla, activar alerta, mensaje de notificación, activar tracking
      name: 'editLockSettings',
      label: 'editar la configuración del bloqueo de dispositivos'
    }
  }

  return (items)
}

import React from 'react'
import StateRS from './StateRS'
import PropTypes from 'prop-types'
import styles from '../../../../css/RxartSecure/DevicesRS.module.css'

export default function DevicesRS ({ data, setData, device, bios, setBios }) {
  // console.log(data, device)
  // const [selectedDevice, setSelectedDevice] = useState(null)

  // const devices = ['notebook', 'tablet', 'smartphone']

  // const handleSelectChange = (e) => {
  //   // console.log(e.target.name, e.target.value)

  //   setSelectedDevice(e.target.value)
  //   setData({
  //     ...data,
  //     device: selectedDevice
  //   })
  // }

  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value)

    if (e.target.name === 'bios') {
      setBios(e.target.value)
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
    }
  }

  return (
          <div id="devicesRS" className={styles.devicesContainer}>
            <div className={styles.inputContainer}>
                <label>Serial bios</label>
                <input type="text" value={bios} name="bios" onChange={handleInputChange} />
            </div>
            <div className={styles.inputContainer}>
                <label>Licencia</label>
                <input type="text" value={data?.deviceid} name="license" disabled/>
            </div>
            <div className={styles.stateAndSelectContainer}>
                {/* <select
                    onChange={handleSelectChange}
                    className={styles.selectRS}
                >
                    {devices.map((option) => (
                        <option value={option} key={option}>{option}</option>
                    ))}
                </select> */}
                <p className={styles.selectRS}>{device?.type === null ? 'No disponible' : device.type}</p>
                <div className={styles.stateContainer}>
                    <label className={styles.labelState}>Estado</label>
                    < StateRS state={data?.statusid}/>
                </div>
            </div>
            <div className={styles.inputContainer}>
                <label>Descripción/Comentarios</label>
                <textarea name="description" value={data?.description} onChange={handleInputChange} />
            </div>
          </div>
  )
}

DevicesRS.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  device: PropTypes.object,
  bios: PropTypes.string,
  setBios: PropTypes.func
}

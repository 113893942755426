import React from 'react'
import styles from '../../../../css/modals/ErrorLog.module.css'
import stylesNotifications from '../../../../css/preferences/Notifications.module.css'

export default function CheckErrorLog () {
  return (
    <>
      <div className={styles.container_checkbox_error_log}>
        <label className={styles.subtitle_notifications}>
            Envio de Informe
        </label>
        <label className={stylesNotifications.switch}>
          <input
            type="checkbox"
            name="checkSendInform"
            // onChange={handleInputChange}
            // value={inputData}
            // checked={inputData}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Tree } from 'primereact/tree'
import { treeJson } from '../modals/modalsRole/JSON/treeJson'
import PropTypes from 'prop-types'
import styles from '../../css/generals/Tree.module.css'

export default function SelectionTree ({ selection, setSelectecTree }) {
  const [nodes, setNodes] = useState([])

  useEffect(() => {
    const items = treeJson()
    setNodes(items)
  }, [setNodes])

  return (
    <div className={styles.treeContainer}>
        <Tree
            value={nodes}
            selectionMode="single"
            selectionKeys={selection}
            onSelectionChange={(e) => setSelectecTree(e.value)}
        />
    </div>
  )
}

SelectionTree.propTypes = {
  selection: PropTypes.string,
  setSelectecTree: PropTypes.func
}

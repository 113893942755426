export function groupsJson () {
  const items = {
    newGroup: {
      name: 'newGroup',
      label: 'crear un grupo'
    },
    exportPdfGroups: {
      name: 'exportPdfGroups',
      label: 'exportar grupos en formato pdf'
    },
    exportCsvGroups: {
      name: 'exportCsvGroups',
      label: 'exportar grupos en formato csv'
    },
    removeGroups: {
      name: 'removeGroups',
      label: 'eliminar grupos'
    },
    seeDashboardGroup: {
      name: 'seeDashboardGroup',
      label: 'acceder al dashboard de los grupos'
    }
    // ,
    // columnsDataTable: []
  }

  return (items)
}

import React from 'react'
import { Chart } from 'primereact/chart'
import Loading from '../loading'
import PropTypes from 'prop-types'
import styles from '../../../css/generals/charts/Line.module.css'
import '../../../css/generals/charts/pie.css'
import {
  dateDiff,
  currentAndPreviousDate,
  reverseDateString
} from './utils/DatesFormats'

export default function Line ({ data, ubication }/* props */) {
  // console.log(props);
  const date = currentAndPreviousDate(6, '-')
  const today = date[0]

  const array = [
    { day: '', counter: 0 },
    { day: '', counter: 0 },
    { day: '', counter: 0 },
    { day: '', counter: 0 },
    { day: '', counter: 0 },
    { day: '', counter: 0 },
    { day: '', counter: 0 }
  ]

  const daysLabels = []
  const arrayData = []

  function days (days) {
    const date = new Date()
    date.setDate(date.getDate() - days)
    return date.getDate()
  }

  const day7 = days(0)
  const day6 = days(1)
  const day5 = days(2)
  const day4 = days(3)
  const day3 = days(4)
  const day2 = days(5)
  const day1 = days(6)

  function daysArray (date, day) {
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    const dayArgument = day
    if (dayArgument > date.getDate()) {
      if (month === 1) {
        month = 12
        year -= 1
      } else {
        month -= 1
      }
    }
    return [day, month, year]
  }

  const day7Array = daysArray(today, day7)
  const day6Array = daysArray(today, day6)
  const day5Array = daysArray(today, day5)
  const day4Array = daysArray(today, day4)
  const day3Array = daysArray(today, day3)
  const day2Array = daysArray(today, day2)
  const day1Array = daysArray(today, day1)

  function labels (array) {
    const day = array[0]
    const dayLabel = day > 9 ? day : '0' + day
    const month = array[1]
    const monthLabel = month > 9 ? month : '0' + month
    const year = array[2]

    return `${dayLabel}-${monthLabel}-${year}`
  }

  if (ubication === 'SfWb-home') {
    data?.map(({ day }) => {
      daysLabels.push(reverseDateString(day))
      return day
    })

    data?.map(({ count }) => {
      arrayData.push(count)
      return count
    })
  } else {
    data?.map(({ date }) => {
      const diff = dateDiff(new Date(date), 6, '-')
      if (diff < 7) {
        const index = diff
        array[index].counter += 1
      }
      return date
    })

    array.reverse()

    array[6].day = labels(day7Array)
    array[5].day = labels(day6Array)
    array[4].day = labels(day5Array)
    array[3].day = labels(day4Array)
    array[2].day = labels(day3Array)
    array[1].day = labels(day2Array)
    array[0].day = labels(day1Array)

    array.map(({ day }) => {
      daysLabels.push(day)
      return day
    })

    array.map(({ counter }) => {
      arrayData.push(counter)
      return counter
    })
  }

  const useState = () => {
    const basicData = {
      labels: daysLabels,
      datasets: [
        {
          label: 'Cantidad de visitas',
          data: arrayData,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.4
        }
      ]
    }
    return {
      basicData
    }
  }

  const { basicData } = useState()

  const getLightTheme = () => {
    const basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }

    return {
      basicOptions
    }
  }

  const { basicOptions } = getLightTheme()

  return (
    <>
      <div className={`${styles.containerLine}`}>
        {data !== false
          ? (
          <Chart
            type="line"
            data={basicData}
            options={basicOptions}
            width="100%"
            height="100%"
          />
            )
          : (
          <Loading color="primary" />
            )}
      </div>
    </>
  )
}

Line.propTypes = {
  data: PropTypes.array,
  ubication: PropTypes.string
}

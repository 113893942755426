export function rolesJson () {
  const items = {
    newRol: {
      name: 'newRol',
      label: 'crear un rol'
    },
    exportPdfRoles: {
      name: 'exportPdfRoles',
      label: 'exportar roles en formato pdf'
    },
    exportCsvRoles: {
      name: 'exportCsvRoles',
      label: 'exportar roles en formato csv'
    },
    removeRole: {
      name: 'removeRole',
      label: 'eliminar el rol'
    },
    editRole: {
      name: 'editRole',
      label: 'editar el rol'
    }
    // ,
    // columnsDataTable: []
  }

  return (items)
}

import React, { useState, useEffect, useRef } from 'react'
import { postData } from '../../actions/servicesHttp'
import { useSelector } from 'react-redux'
import ModalGeneric from './modal'
import { popupSimple } from '../../components/generals/popups'
import $ from 'jquery'
import PropTypes from 'prop-types'
import '../../css/generals/paper.css'

export default function SendMessages ({ devices, onHide, type, component, identity, title, show, onSelecteds, group, socketSendActions, realTime }) {
  // console.log(props);
  const userData = useSelector((state) => state.user)
  const user = useRef()
  const [data, setData] = useState({
    title: '',
    message: ''
  })

  const [destiny, setDestiny] = useState('Todos')

  useEffect(() => {
    user.current = userData.userId
  }, [userData])

  useEffect(() => {
    if (Array.isArray(devices) || devices?.length === 0) {
      setDestiny('Todos')
    } else {
      setDestiny('Seleccionados')
    }
  }, [devices])

  function handleOnSendMessage (e) {
    if (e.target.id === 'destiny') {
      setDestiny(e.target.value)
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
    }
  }

  function closeModal () {
    onHide()
    setData({
      title: '',
      message: ''
    })
  }

  async function sendMessage () {
    $('#btnModalSucess').prop('disabled', true)
    const json = {
      action: 'message',
      data: {
        title: data.title,
        body: data.message,
        recursive: {
          status: false,
          repeat: 1,
          finish: 5
        }
      },
      devicesId:
        type === 'dashboardGroup'
          ? []
          : devices !== undefined
            ? devices
            : [],
      groupsId:
        component === 'dashboardDevices'
          ? []
          : group !== undefined
            ? group
            : destiny === 'Todos'
              ? [1]
              : []
    }

    if (data.title !== '' && data.message !== '') {
      if (realTime) {
        socketSendActions([json])
        popupSimple('success', 'Acción realizada con éxito!')
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else {
        const { data } = await postData('actions', [json])
        if (component === 'dashboardDevices') {
          if (
            data[0].result === true &&
          Array.isArray(data[0].sendings) &&
          data[0].sendings.length === 0
          ) {
            popupSimple('success', 'Solicitud enviada con éxito!')
            setTimeout(() => {
              $('#btnModalSucess').prop('disabled', false)
            }, 1500)
          } else if (
            data[0].result === true &&
          Array.isArray(data[0].sendings) &&
          data[0].sendings.length > 0 &&
          data[0].sendings.includes(identity)
          ) {
            popupSimple('success', 'Acción realizada con éxito!')
            setTimeout(() => {
              $('#btnModalSucess').prop('disabled', false)
            }, 1500)
          } else {
            popupSimple('error', 'Error. No se pudo realizar la acción!')
            setTimeout(() => {
              $('#btnModalSucess').prop('disabled', false)
            }, 1500)
          }
        } else {
          if (data[0].result) {
            if (component === 'devices') onSelecteds([])
            popupSimple('success', 'Solicitud enviada con éxito!')
            setTimeout(() => {
              $('#btnModalSucess').prop('disabled', false)
            }, 1500)
          } else {
            popupSimple('error', 'Error. No se pudo realizar la acción!')
            setTimeout(() => {
              $('#btnModalSucess').prop('disabled', false)
            }, 1500)
          }
        }
      }
      closeModal()
    } else {
      popupSimple('error', 'Error. Debe completar todos los campos!')
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    }
  }

  const objectGeneral = [
    {
      key: 'keytitle1',
      label: 'Titulo:',
      placeholder: 'Titulo...',
      value: data.title,
      name: 'title',
      type: 'text',
      id: 'sendMessagesTitle'
    },
    {
      key: 'keymsj2',
      label: 'Mensaje:',
      value: data.message,
      placeholder: 'Mensaje...',
      name: 'message',
      type: 'area',
      id: 'sendMessagesMessage'
    },
    {
      key: 'keySend',
      label: 'Enviar a: ',
      value: destiny,
      id: 'destiny',
      type: 'options',
      default: destiny,
      options:
        devices && devices.length === 0
          ? [
              {
                value: 'Todos',
                title: 'Todos'
              }
            ]
          : [
              {
                value: 'Todos',
                title: 'Todos'
              },
              {
                value: 'Seleccionados',
                title: 'Seleccionados'
              }
            ],
      idMess: 'sendMessagesDestiny'
    }
  ]

  const objectDash = [
    {
      key: 'keytitle2',
      label: 'Titulo',
      placeholder: 'Titulo...',
      value: data.title,
      name: 'title',
      type: 'text',
      id: 'sendMessagesTitleDash'
    },
    {
      key: 'keymsj2',
      label: 'Mensaje',
      value: data.message,
      placeholder: 'Mensaje...',
      name: 'message',
      type: 'area',
      id: 'sendMessagesMessageDash'
    }
  ]

  return (
    <>
      <ModalGeneric
        show={show}
        onHide={closeModal}
        size="md"
        id="sendMessage"
        onChange={handleOnSendMessage}
        onClose={closeModal}
        destiny={destiny}
        title={title}
        objects={component === 'dashboardDevices' || component === 'dashboardGroups' ? objectDash : objectGeneral}
        btnError="Cerrar"
        actionError={closeModal}
        btnSuccess="Enviar"
        actionSuccess={sendMessage}
      />
    </>
  )
}

SendMessages.propTypes = {
  devices: PropTypes.array,
  onHide: PropTypes.func,
  type: PropTypes.string,
  component: PropTypes.string,
  identity: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  onSelecteds: PropTypes.func,
  group: PropTypes.array,
  socketSendActions: PropTypes.func,
  realTime: PropTypes.bool
}

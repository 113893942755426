import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import stylesUsers from '../../css/users/Users.module.css'
import styles from '../../css/generals/Checkbox.module.css'
import PropTypes from 'prop-types'
import ModalListRoles from './modalsRole/modalListRoles'

export default function ModalUsers ({ buttonLabel, show, type, title, onClick, inputs, clearInputs, onChange, modals, handleOnSubmit, setModals, setRolesUser }) {
  const [showModalRoles, setShowModalRoles] = useState(false)
  function ShowModalRoles () { setShowModalRoles(true) }
  function CloseModalRoles () { setShowModalRoles(false) }

  return (<>
        <Modal
            show={show}
            size="md"
            onHide={ type === 'add'
              ? () => setModals({
                  ...modals,
                  create: false
                })
              : () => {
                  setModals({
                    ...modals,
                    edit: false
                  })
                  clearInputs()
                }
            }
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={handleOnSubmit} autoComplete="off">
                <div className="component-users">
                <label className={stylesUsers.labelUsers}>Usuario: </label>
                <input
                    placeholder="Escriba su nombre de usuario..."
                    value={inputs.user}
                    name="user"
                    onChange={onChange}
                    type="text"
                    className={styles.focus}
                    id={type === 'add' ? 'user' : 'editUser'}
                />
                <p id={type === 'add' ? 'userError' : 'editUserError'} className={stylesUsers.errorMessage}></p>
                </div>
                <div className="component-users">
                <label className={stylesUsers.labelUsers}>Nombre: </label>
                <input
                    placeholder="Escriba su nombre..."
                    value={inputs.name}
                    name="name"
                    onChange={onChange}
                    type="text"
                    className={styles.focus}
                    id={type === 'add' ? 'name' : 'editName'}
                />
                <p id={type === 'add' ? 'nameError' : 'editNameError'} className={stylesUsers.errorMessage}></p>
                </div>
                <div className="component-users">
                <label className={stylesUsers.labelUsers}>Apellido: </label>
                <input
                    placeholder="Escriba su apellido..."
                    value={inputs.last}
                    name="last"
                    onChange={onChange}
                    type="text"
                    className={styles.focus}
                    id={type === 'add' ? 'last' : 'editLast'}
                />
                <p id={type === 'add' ? 'lastError' : 'editLastError'} className={stylesUsers.errorMessage}></p>
                </div>
                <div className="component-users">
                <label className={stylesUsers.labelUsers}>Contraseña: </label>
                <input
                    placeholder="Escriba su contraseña..."
                    value={inputs.password}
                    name="password"
                    onChange={onChange}
                    type="password"
                    className={styles.focus}
                    id={type === 'add' ? 'password' : 'editPassword'}
                />
                <p id={type === 'add' ? 'passwordError' : 'editPasswordError'} className={stylesUsers.errorMessage}></p>
                </div>
                <div className="component-users">
                <label className={stylesUsers.labelUsers}>Teléfono: </label>
                <input
                    placeholder="Escriba su teléfono..."
                    value={inputs.phone}
                    name="phone"
                    onChange={onChange}
                    type="number"
                    className={styles.focus}
                    id={type === 'add' ? 'phone' : 'editPhone'}
                />
                <p id={type === 'add' ? 'phoneError' : 'editPhoneError'} className={stylesUsers.errorMessage}></p>
                </div>
                <div className="component-users">
                <label className={stylesUsers.labelUsers}>Email: </label>
                <input
                    placeholder="Escriba su email..."
                    value={inputs.email}
                    name="email"
                    onChange={onChange}
                    type="email"
                    className={styles.focus}
                    id={type === 'add' ? 'email' : 'editEmail'}
                />
                <p id={type === 'add' ? 'emailError' : 'editEmailError'} className={stylesUsers.errorMessage}></p>
                </div >
                <div className="component-users">
                    <div className={stylesUsers.rolesUser} >
                        <label className={stylesUsers.labelUsers}>
                            Asignar Roles: {/* inputs.roles */}
                        </label>
                        <Button onClick={ShowModalRoles}> Ver Roles</Button>
                    </div>
                    <ul className={stylesUsers.listRoles}>
                        {inputs.roles?.length > 0
                          ? (inputs?.roles.map((element) => {
                              return (<li key={element.id}>{element.roleName}</li>)
                            }))
                          : <li key='default'>No tiene un rol asignado</li>}
                    </ul>
                    <p id={type === 'add' ? 'roleError' : 'editRoleError'} className={stylesUsers.errorMessage}></p>
                </div>
            </form>
            </Modal.Body>
            <Modal.Footer>
            <Button
                onClick={ type === 'add'
                  ? () => setModals({
                      ...modals,
                      create: false
                    })
                  : () => setModals({
                      ...modals,
                      edit: false
                    })
                }
                variant="danger"
            >
                Cerrar
            </Button>
            <Button onClick={() => onClick()} variant="primary">
                {buttonLabel}
            </Button>
            </Modal.Footer>
        </Modal>
        <ModalListRoles show={showModalRoles} title={'Lista de Roles'} buttonLabel={'Cerrar'} data={inputs.roles} setRolesUser={setRolesUser} handleClose={CloseModalRoles}></ModalListRoles>
        </>
  )
};

ModalUsers.propTypes = {
  buttonLabel: PropTypes.string,
  show: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  inputs: PropTypes.object,
  clearInputs: PropTypes.func,
  onChange: PropTypes.func,
  modals: PropTypes.object,
  handleOnSubmit: PropTypes.func,
  setModals: PropTypes.func,
  setRolesUser: PropTypes.func
}

import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Header from '../../../components/generals/header'
import styles from '../../../css/preferences/UsageTime.module.css'
import stylesNotifications from '../../../css/preferences/Notifications.module.css'
import stylesIndex from '../../../css/Index.module.css'
import stylesLabels from '../../../css/generals/Checkbox.module.css'
import { getData, putData } from '../../../actions/servicesHttp'
import Swal from 'sweetalert2'
import store from '../../../reducer/store'

export default function UsageTime () {
  const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  const [inputData, setInputData] = useState({})
  const [selectedDays, setSelectedDays] = useState([])
  const [checkUsageTime, setCheckUsageTime] = useState(false)
  const [timeToCompare, setTimeToCompare] = useState({})
  const [usageTime, setUsageTime] = useState({
    start_time: '00:00',
    end_time: '00:00',
    active_use_time: false,
    days_id: []
  })
  const { user } = store.getState()
  const role = user.roles

  const buttonsDisabled = !checkUsageTime

  const toggleDaySelection = (day) => {
    let updatedDays = [...usageTime?.days_id]

    updatedDays?.includes(day)
      ? updatedDays = updatedDays.filter((selectedDay) => selectedDay !== day)
      : updatedDays.push(day)

    setUsageTime({
      ...usageTime,
      days_id: updatedDays
    })

    setSelectedDays(updatedDays)
  }

  const handleInputChange = ({ target }) => {
    const { name, type, checked, value } = target

    const newUsageTime = { ...usageTime }
    if (name === 'checkUsageTime') {
      newUsageTime.active_use_time = checked
      setCheckUsageTime(checked)
    }

    setInputData({
      ...inputData,
      [name]: type === 'checkbox' ? checked : value
    })

    setUsageTime(newUsageTime)
  }

  useEffect(() => {
    const getPreferencesUsageTime = async () => {
      const { data } = await getData('preferences/usage-time', 1)
      if (data) {
        setUsageTime(data)
        setTimeToCompare(data)
        setSelectedDays(data?.days_id)
        setCheckUsageTime(data?.active_use_time)
      }
    }
    getPreferencesUsageTime()
  }, [])

  useEffect(() => {
    if (!checkUsageTime) {
      setUsageTime({
        ...usageTime,
        days_id: [],
        start_time: '00:00',
        end_time: '00:00',
        active_use_time: false
      })
      setSelectedDays([])
    }
  }, [checkUsageTime])

  const addUsageTime = async () => {
    let flag = false
    // console.log(usageTime, timeToCompare)
    if (usageTime?.active_use_time && (usageTime?.days_id.length === 0)) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: '¡Debe seleccionar los días!',
        showConfirmButton: false,
        timer: 1500
      })
      flag = true
    } else if (usageTime?.active_use_time && (usageTime?.end_time === '00:00') && (usageTime?.start_time === '00:00')) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: '¡Debe seleccionar los horarios!',
        showConfirmButton: false,
        timer: 1500
      })
      flag = true
    } else if (JSON.stringify(usageTime) === JSON.stringify(timeToCompare)) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: '¡No hay cambios para guardar!',
        showConfirmButton: false,
        timer: 1500
      })
      flag = true
    } else {
      delete usageTime?.preference
      if (!flag) {
        const { data } = await putData('preferences/usage-time', usageTime)

        if (data?.result === true) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cambios guardados con exito!',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error al guardar los cambios!',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      setTimeToCompare(usageTime)
    }
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Tiempo de uso" className={stylesNotifications.title} />

        {role?.preferencesUseTimePage.restriction && <div className={stylesNotifications.containerCheckbox}>
          <p style={{ fontSize: '1rem' }}>Configure la restricción del tiempo de uso de los dispositivo por días y horarios:</p>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="checkUsageTime"
              onChange={handleInputChange}
              checked={checkUsageTime}
            />
          <span className="slider round"></span>
          </label>
          <label /* className={styles.subtitle_notifications} */ className={`${stylesLabels.sliderLabel}`} htmlFor="checkUsageTime">
              {checkUsageTime ? 'Activado' : 'Desactivado'}
          </label>
        </div>}

        {role?.preferencesUseTimePage.restriction &&
        <>
          <hr />
          <div className={styles.container_days}>
            {days?.map((day, index) => (
              <Button
                key={index + 1}
                variant={selectedDays?.includes(index + 1) ? 'primary' : 'dark'}
                onClick={() => toggleDaySelection(index + 1)}
                className={styles.day_button}
                disabled={buttonsDisabled}
              >
                {day}
              </Button>
            ))}
          </div>
          <hr/>
          <div className={styles.container_time}>
            <label className={`label-start-time ${stylesLabels.sliderLabel}`} htmlFor="startTime">Hora de inicio: </label>
            <input
              id="startTime"
              className="start-time"
              name="start_time"
              type="time"
              disabled={buttonsDisabled}
              value={usageTime?.start_time}
              onChange={(e) => setUsageTime({ ...usageTime, start_time: e.target.value })}
            />
          </div>
          <hr/>
          <div className={styles.container_time}>
            <label className={`${stylesLabels.sliderLabel}`} htmlFor="endTime">Hora de finalización: </label>
            <input
              id="endTime"
              className="end-time"
              name="end_time"
              type="time"
              disabled={buttonsDisabled}
              value={usageTime?.end_time}
              onChange={(e) => setUsageTime({ ...usageTime, end_time: e.target.value })}
            />
          </div>
          <hr className={styles.line_bottom} />
        </>}

        <div className={stylesNotifications.container_btn}>
            <Button
              id="btnSaveTime"
              className={stylesNotifications.buttonSave}
              variant="dark"
              onClick={addUsageTime}
            >
              <i className="fas fa-save" style={{ marginRight: '0.5rem' }}></i>
              Guardar
            </Button>
        </div>
      </div>
    </div>
  )
}

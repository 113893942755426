import { useMapEvents } from 'react-leaflet'
import PropTypes from 'prop-types'
import { config } from '../../config'

// https://us1.locationiq.com/v1/search?key=pk.1e78f23848b77294c56de356c5f5f710&format=json&q=av ingeniero huergo
// como si fuera una barra de búsqueda
// con esta longitud y latitud creo la geocerca

// key : pk.1e78f23848b77294c56de356c5f5f710

// https://us1.locationiq.com/v1/reverse?key=pk.1e78f23848b77294c56de356c5f5f710&lat=${lat}&lon=${lon}&format=json
// cuando posiciona el marker en el mapa

const MapClickLocation = ({ setGeofenceData, info, type }) => {
  // console.log(info)
  useMapEvents({
    click: async (e) => {
      const { lat, lng } = e.latlng

      setGeofenceData({
        ...info,
        lat,
        lon: lng
      })

      const placeInput = type === 'add' ? document.querySelector('#place') : document.querySelector('#editPlace')
      placeInput.value = 'Cargando...'

      try {
        //! ***** Devuelve la calle especifica, pero funciona de vez en cuando ******
        // `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
        //! **************************************************************************
        //! ***** API anterior que devolvía solo las Comunas y no la altura ni la calle específica
        // const response = await fetch(
        //   `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=es`
        // )
        const response = await fetch(
            `https://us1.locationiq.com/v1/reverse?key=${config.map.api_key}&lat=${lat}&lon=${lng}&format=json`
        )
        if (response.status < 300) {
          const data = await response.json()
          // console.log(data)
          const address = data.display_name
          setGeofenceData({
            ...info,
            place: address,
            lat: data.lat,
            lon: data.lon
          })
          //   data.city = data.locality.includes('Comuna ') ? 'Ciudad Autónoma de Buenos Aires' : data.city
          //   const address = `${data.countryName}, ${data.city}, ${data.locality}`
          placeInput.value = address
        } else {
          console.log({ response })
          placeInput.value = 'Error al obtener la dirección'
        }
      } catch (error) {
        console.error('Error en la solicitud de Reverse Geocoding:', error)
        placeInput.value = 'Error en la solicitud'
      }
    }
  })
  return null
}

export default MapClickLocation

MapClickLocation.propTypes = {
  setGeofenceData: PropTypes.func,
  info: PropTypes.object,
  type: PropTypes.string
}

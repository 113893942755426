import React, { useState, useEffect } from 'react'
import Doughnuts from '../../components/generals/charts/doughnut'
import Pie from '../../components/generals/charts/pie'
import BoxChart from '../../components/generals/boxCharts'
import KnobChart from '../../components/generals/charts/knob'
import Mapa from '../../components/generals/map'
import { getData } from '../../actions/servicesHttp'
import Header from '../../components/generals/header'
import { ProgressSpinner } from 'primereact/progressspinner'
import store from '../../reducer/store'
import styles from '../../css/Home.module.css'
import stylesIndex from '../../css/Index.module.css'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import VerticalBar from '../../components/generals/charts/verticalBar'
import { Dropdown } from 'primereact/dropdown'
import formatUseTimeApps from './Assets/formatUseTimeApps'

export default function Home () {
  const [urlStat] = useState('stats')
  const [urlConn] = useState('connections')
  const [urlMap] = useState('locations')
  const [urlAppsUseTime] = useState('applications/mostUsedApplications')
  const [loadingCharts, setLoadingCharts] = useState(false)
  const [loadingStats, setLoadingStats] = useState(false)
  const [loadingAppsUseTime, setLoadingAppsUseTime] = useState(false)

  const [charts, setCharts] = useState(null)
  const [stats, setStats] = useState(null)
  const [map, setMap] = useState(null)
  const [appsUseTime, setAppsUseTime] = useState(null)
  const [dateFilter, setDateFilter] = useState(8)
  const [category, setCategory] = useState(5)

  const { user } = store.getState()
  const role = user.roles

  // Filtro de fecha
  const dates = [
    { name: 'Semana', value: 8 },
    { name: 'Mes', value: 31 }
  ]

  // Categorias
  const [categories] = useState([
    { name: 'No Permitidas', id: 0 },
    { name: 'Sistema', id: 1 },
    { name: 'Educativas', id: 2 },
    { name: 'No Educativas', id: 3 },
    { name: 'Productividad', id: 4 },
    { name: 'Internet', id: 5 },
    { name: 'Ocio', id: 6 },
    { name: 'Otras', id: 7 }
  ])

  const getDataCharts = async (conn) => {
    setLoadingCharts(true)
    const data = await getData('home', conn)
    if (data) setCharts(data?.data)
    else setCharts([])
    setLoadingCharts(false)
  }

  const getDataStats = async (stat) => {
    setLoadingStats(true)
    const data = await getData('home', stat)
    if (data) setStats(data?.data)
    else setStats([])
    setLoadingStats(false)
  }

  const getDataMap = async (loc) => {
    const data = await getData('home', loc)
    if (data) setMap(data.data)
  }

  const getDataAppsUseTime = async (url, params) => {
    setLoadingAppsUseTime(true)
    const data = await getData(url, JSON.stringify(params))
    // console.log(data)
    if (data) {
      const res = formatUseTimeApps(data?.data, dateFilter, 3600)
      setAppsUseTime(res)
    } else {
      setAppsUseTime({
        labels: [],
        data: []
      })
    }
    setLoadingAppsUseTime(false)
  }

  useEffect(() => {
    getDataStats(urlStat)
  }, [urlStat])

  useEffect(() => {
    getDataCharts(urlConn)
  }, [urlConn])

  useEffect(() => {
    getDataMap(urlMap)
  }, [urlMap])

  useEffect(() => {
    const json = {
      groupApp: category,
      dateFilter,
      view: 'home'
    }

    getDataAppsUseTime(urlAppsUseTime, json)
  }, [urlAppsUseTime, category, dateFilter])

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
          <Header title="Inicio" />
          <div className={styles.spaceCharts}>
            {role.homePage.diskChartHome && <BoxChart
              heightBody="80%"
              height="25rem"
              title="Disco"
              icon="fa-solid fa-floppy-disk"
              className={`${styles.boxChart}`}
            >
              {loadingStats && <ProgressSpinner />}
              {stats && stats?.percentageDisk !== null && (
                <Pie data={stats} type="general"/>
              )}
              {stats && stats?.percentageDisk === null && <h5>Sin data a mostrar</h5>}
            </BoxChart>}
            {role.homePage.ramChartHome && <BoxChart
              heightBody="80%"
              height="25rem"
              title="Ram"
              icon="fa-solid fa-memory"
              className={`${styles.boxChart}`}
            >
              {loadingStats && <ProgressSpinner />}
              {stats && stats?.percentageRam !== null && (
                <KnobChart data={stats} type="general" size={210} />
              )}
              {stats && stats?.percentageRam === null && <h5>Sin data a mostrar</h5>}
            </BoxChart>}
            {role.homePage.connectionsChartHome && <BoxChart
              heightBody="80%"
              height="25rem"
              title="Conexiones de Equipos"
              icon="pi pi-tablet"
              className={`${styles.boxChart}`}
            >
              {loadingCharts && <ProgressSpinner />}
              {charts && charts?.total !== 0 && (
                <Doughnuts data={charts} />
              )}
              {charts && charts?.total === 0 && <h5>Sin data a mostrar</h5>}
            </BoxChart>}
          </div>
          <div className={styles.bodyHome}>
          {role.homePage.appsUseTimeChartHome && <BoxChart
              heightBody="80%"
              height="35rem"
              title={`Tiempo de Uso de ${categories[category]?.name}`}
              icon="fa-solid fa-shapes"
              className={`${styles.boxChart}`}
            >
              {loadingAppsUseTime && <ProgressSpinner />}
              {appsUseTime && appsUseTime?.data.length !== 0 && <>
                <div className={styles.filters}>
                  <div className={styles.categoriesContainer}>
                    <p className={styles.floatLabel}>Categorías:</p>
                    <Dropdown
                      value={category}
                      options={categories}
                      onChange={(e) => setCategory(e.value)}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Seleccionar categoría"
                      className={styles.dropdown}
                    />
                  </div>
                  <ButtonGroup className={styles.dateFilterContainer}>
                    {dates.map((date, index) => (
                      <ToggleButton
                        key={index}
                        id={`date-home-${index}`}
                        type="radio"
                        variant="secondary"
                        name="date-home"
                        value={date.value}
                        checked={date.value === dateFilter}
                        className={styles.btnDateUseTimeApps}
                        onChange={(e) => setDateFilter(parseInt(e.target.value))}
                      >
                        {date.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
                <VerticalBar title='Tiempo de uso en horas (hs)' labels={appsUseTime?.labels} data={appsUseTime?.data} backgroundColor='#3F9BFF'className={ `${styles.marginTop}`}/>
              </>}
              {appsUseTime && appsUseTime?.data.length === 0 && <h5>Sin data a mostrar</h5>}
            </BoxChart>}
          {role.homePage.locationMapHome && <BoxChart
              heightBody="80%"
              icon="pi pi-map-marker"
              height="35rem"
              title="Geoposicionamiento"
              className={`${styles.boxChart}`}
            >
              {!map ? <ProgressSpinner /> : <Mapa info={map} />}
            </BoxChart>}
        </div>
      </div>
    </div>
  )
}

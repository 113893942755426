import React, { useState, useEffect } from 'react'
import BoxChart from '../../components/generals/boxCharts'
import { getData } from '../../actions/servicesHttp'
import Header from '../../components/generals/header'
import Line from '../../components/generals/charts/line'
import styles from '../../css/Safeweb/HomeSW.module.css'
import Wanted from '../../components/SafeWeb/Wanted'
import stylesIndex from '../../css/Index.module.css'
import { currentAndPreviousDate } from '../../components/generals/charts/utils/DatesFormats.js'
import store from '../../reducer/store'

export default function SafeWeb () {
  const [blackList, setBlackList] = useState([])
  const [whiteList, setWhiteList] = useState([])
  const [queryList, setQueryList] = useState([])
  const [searchLast, setSearchLast] = useState([])
  const [searchMost, setSearchMost] = useState([])

  const { user } = store.getState()
  const role = user.roles

  const date = currentAndPreviousDate(6, '-')
  const day = new Date(date[1]).toISOString().split('T')[0]

  const getSafeWebLast = async () => {
    const last = await getData('safeweb/home/search', 'searchLast')
    if (last)setSearchLast(last.data)
  }

  const getSafeWebMost = async () => {
    const most = await getData('safeweb/home/search', 'searchMost')
    if (most)setSearchMost(most.data)
  }

  const getBlackList = async (date) => {
    const black = await getData('safeweb/home/blackList', date)
    if (black)setBlackList(black.data)
  }

  const getWhiteList = async (date) => {
    const white = await getData('safeweb/home/whiteList', date)
    if (white)setWhiteList(white.data)
  }

  const getQueryList = async (date) => {
    const query = await getData('safeweb/home/queryList', date)
    if (query)setQueryList(query.data)
  }

  useEffect(() => {
    getSafeWebLast()
    getSafeWebMost()
    getBlackList(day)
    getWhiteList(day)
    getQueryList(day)
  }, [day])

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
    <div className={`content  ${stylesIndex.mainContent}`}>
      <div>
        <Header title="SafeWeb" />

        <div className={styles.headerHome}>
          <div className={styles.SFWBList}>
          {role?.safeWebHomePage.blackListLine && <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="80%"
              title="Listas Negras"
              icon="pi pi-bookmark-fill"
            >
              {blackList && blackList.length === 0
                ? (
                <p>Sin data a mostrar</p>
                  )
                : (
                <Line data={blackList} ubication="SfWb-home" />
                  )}
            </BoxChart>}
            {role?.safeWebHomePage.whiteListLine && <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="80%"
              title="Listas Blancas"
              icon="pi pi-bookmark"
            >
              {whiteList && whiteList.length === 0
                ? (
                <p>Sin data a mostrar</p>
                  )
                : (
                <Line data={whiteList} ubication="SfWb-home" />
                  )}
            </BoxChart>}
          </div>
          <div className={styles.SFWBList2}>
          <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="90%"
              heightHeader="10%"
              title="Últimos Buscados"
              icon="pi pi-history"
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '10px'
                }}
              >
                <Wanted safeWebHomePage={role?.safeWebHomePage} data={{ last: searchLast, most: searchMost }} />
              </div>
            </BoxChart>
          </div>
        </div>

        <div className={styles.SFWBQuery}>
        {role?.safeWebHomePage.querysLine && <BoxChart
            margin="auto"
            width="100%"
            height="30rem"
            heightHeader="20%"
            heightBody="80%"
            title="Visitas"
            icon="pi pi-tags"
          >
            {queryList && queryList.length === 0
              ? (
              <p>Sin data a mostrar</p>
                )
              : (
              <Line data={queryList} ubication="SfWb-home" />
                )}
          </BoxChart>
          }
        </div>
      </div>
      </div>
      </div>
  )
}

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import FormAddFence from '../../Geofences/formAddFence'
import PropTypes from 'prop-types'

export default function ModalFence ({ onClose, type, onClick, show, title, buttonLabel, data }) {
  // console.log(props);
  const [fenceData, setFenceData] = useState(null)
  const idFence = type === 'edit' ? data?.id : null

  return (
    <Modal
      show={show}
      className="modal-content-geofences"
      size="xl"
      onHide={type === 'add'
        ? () => onClose('create')
        : () => onClose('edit')
        // props.clearInputs()
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === 'add'
          ? (
          <FormAddFence setFenceData={setFenceData} type={type}/>
            )
          : (
          <FormAddFence setFenceData={setFenceData} fenceData={data} type={type}/>
            )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={type === 'add'
            ? () => onClose('create')
            : () => onClose('edit')
          }
          variant="danger">
          Cerrar
        </Button>
        <Button
          onClick={() => onClick(fenceData, type, idFence)}
          variant="primary"
          id="btnModalFence"
        >
          {buttonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ModalFence.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.func,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  data: PropTypes.array
}

import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import stylesNotifications from '../../css/preferences/Notifications.module.css'
import styles from '../../css/preferences/UsageTime.module.css'
import { getData, putData } from '../../actions/servicesHttp'
import { popupSimple } from '../generals/popups'

export default function ModalUseTimePreferences ({ title, show, btnError, btnSuccess, onHide, group }) {
  // ? urls de la API
  const [groupPreferenceUrl] = useState('preferences/group')

  const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  const [inputData, setInputData] = useState({})
  const [selectedDays, setSelectedDays] = useState([])
  const [checkUsageTime, setCheckUsageTime] = useState(false)
  const [timeToCompare, setTimeToCompare] = useState({})
  const [usageTime, setUsageTime] = useState({
    start_time: '',
    end_time: '',
    active_use_time: false,
    days_id: []
  })
  const [preferenceId, setPreferenceId] = useState(null)

  const buttonsDisabled = !checkUsageTime

  const toggleDaySelection = (day) => {
    let updatedDays = [...usageTime?.days_id]

    updatedDays.includes(day)
      ? updatedDays = updatedDays.filter((selectedDay) => selectedDay !== day)
      : updatedDays.push(day)

    setUsageTime({
      ...usageTime,
      days_id: updatedDays
    })

    setSelectedDays(updatedDays)
  }

  const handleInputChange = ({ target }) => {
    const { name, type, checked, value } = target

    const newUsageTime = { ...usageTime }
    if (name === 'checkUsageTime') {
      newUsageTime.active_use_time = checked
      setCheckUsageTime(checked)
    }

    setInputData({
      ...inputData,
      [name]: type === 'checkbox' ? checked : value
    })

    setUsageTime(newUsageTime)
  }

  const getGroupPreferences = async (url) => {
    const params = {
      id: group[0],
      typeOfPreferences: 'use_time'
    }
    // console.log(params)

    const { data } = await getData(url, JSON.stringify(params))

    if (data) {
      setPreferenceId(data.preferenceId)
      setUsageTime(data.use_time)
      setTimeToCompare(data.use_time)
      setSelectedDays(data?.use_time.days_id)
      setCheckUsageTime(data?.use_time.active_use_time)
    }
  }

  const addUsageTime = async (url) => {
    const comparation = usageTime === timeToCompare
    const body = {
      groupId: group,
      typeOfPreferences: 'use_time',
      data: usageTime,
      preferenceId: preferenceId === 1 ? undefined : preferenceId
    }

    if (comparation) {
      popupSimple('info', '¡No hay cambios para guardar!')
    } else {
      const { data } = await putData(url, body)

      if (data.result) {
        popupSimple('success', 'Cambios guardados con exito!')
      } else {
        popupSimple('error', 'Error al guardar los cambios!')
      }
    }
    setTimeToCompare(usageTime)
  }

  useEffect(() => {
    if (show) {
      getGroupPreferences(groupPreferenceUrl)
    }
  }, [show])

  return (
        <Modal
            show={show}
            size="lg"
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            key={'useTimeGroupPreference'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '1.5rem' }}>

        <div className={stylesNotifications.containerCheckbox}>
          <label className={stylesNotifications.switch}>
            <input
              type="checkbox"
              name="checkUsageTime"
              onChange={handleInputChange}
              checked={checkUsageTime}
            />
          <span className="slider round"></span>
          </label>
          <label className={styles.subtitle_notifications} htmlFor="checkUsageTime">
              Restricción por días y horarios
          </label>
        </div>

          <hr />
          <div className={styles.container_days}>
            {days?.map((day, index) => (
              <Button
                key={index + 1}
                variant={selectedDays.includes(index + 1) ? 'primary' : 'dark'}
                onClick={() => toggleDaySelection(index + 1)}
                className={styles.day_button}
                disabled={buttonsDisabled}
              >
                {day}
              </Button>
            ))}
          </div>
          <hr/>
          <div className={styles.container_time}>
            <label className="label-start-time" htmlFor="startTime">Hora de inicio: </label>
            <input
              id="startTime"
              className="start-time"
              name="start_time"
              type="time"
              disabled={buttonsDisabled}
              value={usageTime?.start_time}
              onChange={(e) => setUsageTime({ ...usageTime, start_time: e.target.value })}
            />
          </div>
          <hr/>
          <div className={styles.container_time}>
            <label className="label-end-time" htmlFor="endTime">Hora de finalización: </label>
            <input
              id="endTime"
              className="end-time"
              name="end_time"
              type="time"
              disabled={buttonsDisabled}
              value={usageTime?.end_time}
              onChange={(e) => setUsageTime({ ...usageTime, end_time: e.target.value })}
            />
          </div>
          <hr className={styles.line_bottom} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="danger">
                    {btnError}
                </Button>
                <Button onClick={() => addUsageTime(groupPreferenceUrl)} variant="primary" id="btnSucessModalUseTimePreferences" >
                    {btnSuccess}
                </Button>
            </Modal.Footer>
        </Modal>
  )
}

ModalUseTimePreferences.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string,
  onHide: PropTypes.func,
  group: PropTypes.number
}

import React from 'react'
import '../../css/generals/header.css'
import HeaderEdit from '../../components/generals/headerEdit'
import PropTypes from 'prop-types'

export default function Header (props) {
  if (props.edit === true) {
    return (
      <div className='HeaderDashboardModal'>

        <HeaderEdit
          title={props.title}
          data={props.data}
          type={props.type}
          reload={props.reload}
        />
        {props.realTime && props.type === 'device' &&
          <div className='RealTimeIconDashboardModal'>
            <i className='pi pi-circle-fill inLine'></i>
            <p>En linea</p>
          </div>
        }
        {!props.realTime && props.type === 'device' &&
          <div className='RealTimeIconDashboardModal'>
            <i className='pi pi-circle-fill offLine'></i>
            <p>Desconectado</p>
          </div>
        }
      </div>
    )
  } else {
    return (
      <div className="header">
        <h4 className="title">{`${props.title}`}</h4>
      </div>
    )
  }
}

Header.propTypes = {
  edit: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  reload: PropTypes.func,
  data: PropTypes.object,
  realTime: PropTypes.bool
}

import React, { useState } from 'react'
import store from '../reducer/store'
import { NavLink } from 'react-router-dom'
import '../css/menu.css'
import Logo from '../components/svg/LogoPixart'
import iconMarker from '../img/icon_geofence.svg'

export default function Menu () {
  const { user } = store.getState()
  const [views, setViews] = useState({
    preferences: false,
    safeweb: false
  })

  const role = user.roles

  const showSubMenu = (data, views, set) => {
    const liPreferences = document.getElementById('list-item-preferences')
    const liSafeweb = document.getElementById('list-item-safeweb')
    const subMenu = data
    if (subMenu === 'preferences') {
      set({
        preferences: !views?.preferences,
        safeweb: false
      })

      liPreferences.classList.toggle('menu-is-opening')
      liPreferences.classList.toggle('menu-open')

      if (liSafeweb) {
        liSafeweb.classList.remove('menu-is-opening')
        liSafeweb.classList.remove('menu-open')
      }
    } else {
      set({
        preferences: false,
        safeweb: !views?.safeweb
      })

      liSafeweb.classList.toggle('menu-is-opening')
      liSafeweb.classList.toggle('menu-open')

      if (liPreferences) {
        liPreferences.classList.remove('menu-is-opening')
        liPreferences.classList.remove('menu-open')
      }
    }
  }

  const closeMenu = () => {
    const body = document.querySelector('body')
    const windowWidth = document.documentElement.clientWidth
    // console.log(windowWidth)
    if (windowWidth < 975) {
      body.className = 'sidebar-closed sidebar-collapse'
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 containerAside">
      <NavLink to="/mdm/home" className="brand-link ">
        <Logo className="mdm-logo"/>
      </NavLink>
      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {Object.keys(role?.homePage).length !== 0 &&
            <li className="nav-item" onClick={() => closeMenu()}>
              <NavLink to="/mdm/home" className="nav-link hoverly" >
                <i className="fa-solid fa-house nav-icon"></i>
                <p className="colorLeters">Inicio</p>
              </NavLink>
            </li>}
            {Object.keys(role?.devicesPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
              <NavLink to="/mdm/devices" className="nav-link hoverly">
                <i className="fa-solid fa-tablet-screen-button nav-icon"></i>
                <p className="colorLeters">Equipos</p>
              </NavLink>
            </li>}
            { Object.keys(role?.groupsPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
              <NavLink to="/mdm/groups" className="nav-link hoverly">
                <i className="fas fa-users nav-icon"></i>
                <p className="colorLeters">Grupos</p>
              </NavLink>
            </li>}
            {Object.keys(role?.usersPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                <NavLink to="/mdm/users" className="nav-link hoverly">
                  <i
                    className="fas fa-user-friends nav-icon"
                    aria-hidden="true"
                  ></i>
                  <p className="colorLeters">Usuarios</p>
                </NavLink>
              </li>}
              {Object.keys(role?.rolesPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                <NavLink to="/mdm/roles" className="nav-link hoverly">
                  <i
                    className="fas fa-user-tag nav-icon"
                    aria-hidden="true"
                  ></i>
                  <p className="colorLeters">Roles</p>
                </NavLink>
              </li>}
              {Object.keys(role?.geofencesPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                <NavLink to="/mdm/geofences" className="nav-link hoverly">
                  <img
                    src={iconMarker}
                    alt="map-marker"
                    style={{ margin: '0 0.25rem', width: '11%' }}
                  />
                  <p className="colorLeters">Geocercas</p>
                </NavLink>
              </li>}
              {Object.keys(role?.applicationsPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                <NavLink to="/mdm/applications" className="nav-link hoverly">
                  <i
                    className="fa fa-solid fa-shapes nav-icon"
                    aria-hidden="true"
                  ></i>
                  <p className="colorLeters">Aplicaciones</p>
                </NavLink>
              </li>}
              {(Object.keys(role?.preferencesSettingsPage).length !== 0 || Object.keys(role?.preferencesReportsPage).length !== 0 ||
              Object.keys(role?.preferencesLossesPage).length !== 0 || Object.keys(role?.preferencesUseTimePage).length !== 0 ||
              Object.keys(role?.preferencesGeofencePage).length !== 0 || Object.keys(role?.preferencesRxartSecurePage).length !== 0 || Object.keys(role?.preferencesServerPage).length !== 0) &&

              <li className="nav-item dropdown" id='list-item-preferences'>
                <div
                  className="nav-link"
                  onClick={() => showSubMenu('preferences', views, setViews)}
                >
                  <i className="fas fa-cogs nav-icon "></i>
                  <p className="colorLeters">
                    Preferencias
                    <i className="fas fa-angle-left float-right right" />
                  </p>
                </div>
                <ul
                  className={`nav nav-treeview ${views.preferences ? 'subMenu' : null}`}
                  // style={{
                  //   display: views.preferences ? 'block' : 'none'
                  // }}
                  id="preferences-submenu"
                >
                  {Object.keys(role?.preferencesSettingsPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                    <NavLink
                      to="/mdm/preferences/configs"
                      className="nav-link hoverly"
                    >
                      <i className="far fa-circle nav-icon" />
                      <p className="colorLeters">Configuraciones</p>
                    </NavLink>
                  </li>}
                  {Object.keys(role.preferencesReportsPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                    <NavLink
                      to="/mdm/preferences/reports"
                      className="nav-link hoverly"
                    >
                      <i className="far fa-circle nav-icon" />
                      <p className="colorLeters">Reportes</p>
                    </NavLink>
                  </li>}
                  {Object.keys(role?.preferencesLossesPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                    <NavLink
                      to="/mdm/preferences/lost"
                      className="nav-link hoverly"
                    >
                      <i className="far fa-circle nav-icon" />
                      <p className="colorLeters">Extravios</p>
                    </NavLink>
                  </li>}
                  {Object.keys(role?.preferencesUseTimePage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                        <NavLink
                          to="/mdm/preferences/usage-time"
                          className="nav-link hoverly"
                        >
                          <i className="far fa-circle nav-icon" />
                          <p className="colorLeters">Tiempos de uso</p>
                        </NavLink>
                      </li>}
                      {Object.keys(role?.preferencesGeofencePage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                        <NavLink
                          to="/mdm/preferences/geofence-notifications"
                          className="nav-link hoverly"
                        >
                          <i className="far fa-circle nav-icon" />
                          <p className="colorLeters">Geocercas</p>
                        </NavLink>
                      </li>}
                      {Object.keys(role?.preferencesRxartSecurePage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                        <NavLink
                          to="/mdm/preferences/rxart-secure"
                          className="nav-link hoverly"
                        >
                          <i className="far fa-circle nav-icon" />
                          <p className="colorLeters">Rxart Secure</p>
                        </NavLink>
                  </li>}
                      {Object.keys(role?.preferencesServerPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                        <NavLink
                          to="/mdm/preferences/server"
                          className="nav-link hoverly"
                        >
                          <i className="far fa-circle nav-icon" />
                          <p className="colorLeters">Servidor</p>
                        </NavLink>
                      </li>}
                </ul>
              </li>}
            {(Object.keys(role?.safeWebHomePage).length !== 0 || Object.keys(role?.safeWebListsPage).length !== 0 ||
            Object.keys(role?.safeWebWordsPage).length !== 0 || Object.keys(role?.safeWebPreferencesPage).length !== 0) &&
            <li className="nav-item dropdown" id='list-item-safeweb'>
              <div
                className="nav-link"
                onClick={() => showSubMenu('safeweb', views, setViews)}
              >
                <i className="fa-solid fa-shield-halved nav-icon"></i>
                <p className="colorLeters">
                  Safeweb
                  <i className="fas fa-angle-left right float-right" />
                </p>
              </div>
              <ul
                className={`nav nav-treeview ${views.safeweb ? 'subMenu' : null}`}
                // style={{
                //   display: views.safeweb ? 'block' : 'none'
                // }}
                id="safeweb-submenu"
              >
                {Object.keys(role?.safeWebHomePage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                  <NavLink to="/mdm/safeweb" className="nav-link hoverly">
                    <i className="far fa-circle nav-icon" />
                    <p className="colorLeters">Inicio</p>
                  </NavLink>
                </li>}
                {Object.keys(role?.safeWebListsPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                  <NavLink to="/mdm/lists" className="nav-link hoverly">
                    <i className="far fa-circle nav-icon" />
                    <p className="colorLeters">Listas</p>
                  </NavLink>
                </li>}
                {Object.keys(role?.safeWebWordsPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                  <NavLink to="/mdm/words" className="nav-link hoverly">
                    <i className="far fa-circle nav-icon" />
                    <p className="colorLeters">Palabras claves</p>
                  </NavLink>
                </li>}
                {Object.keys(role?.safeWebPreferencesPage).length !== 0 && <li className="nav-item" onClick={() => closeMenu()}>
                      <NavLink
                        to="/mdm/safeweb-preferences"
                        className="nav-link hoverly"
                      >
                        <i className="far fa-circle nav-icon" />
                        <p className="colorLeters">Preferencias</p>
                      </NavLink>
                    </li>}
              </ul>
            </li>}
            <li className="nav-item">
              <NavLink
                to="/"
                className="nav-link hoverly"
                onClick={() => sessionStorage.clear()}
              >
                <i className="fa fa-arrow-left nav-icon" aria-hidden="true"></i>
                <p className="colorLeters">Salida</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <p className="version">Versión 4.0.3</p>
    </aside>
  )
}

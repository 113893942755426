import React from 'react'
import { Badge } from 'primereact/badge'
import '../../css/generals/states.css'
import PropTypes from 'prop-types'

export default function States ({ title, alert, type }) {
  return (
        <>
            {alert && alert.checkAlert
              ? (
                <Badge
                    value="!"
                    severity="danger"
                    className="alert"
                ></Badge>
                )
              : (
                <></>
                )}

            <i
              className={`pi ${type === 'computadora' ? 'pi-desktop' : type === 'tablet' ? 'pi-tablet' : 'pi-circle-fill'}`}
              style={{
                fontSize: 'x-large',
                color: title === 'Desbloqueado' ? '#08BE5B' : title === 'Bloqueado' ? '#d82c2c' : '#989898'
              }}
            ></i>
        </>
  )
}

States.propTypes = {
  title: PropTypes.string,
  alert: PropTypes.object,
  type: PropTypes.object
}

import Swal from 'sweetalert2'
import { getData } from '../../../actions/servicesHttp'
import { formatDate, secondsToString } from '../../../components/generals/charts/utils/DatesFormats'
import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export const exportPdf = async (date, columns, table, previousData = []) => {
  const exportColumns =
  columns.map((col) => { return { dataKey: col.field, header: col.header } }).sort((a, b) => a.header.length - b.header.length)

  const json = {
    like: null,
    include: date + ' 00:00:00.000 +00:00',
    reports: true
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons
    .fire({
      title: 'Descargar tabla en formato PDF?',
      icon: 'warning',
      text: 'Puede tardar el proceso de descarga',
      showCancelButton: true,
      confirmButtonText: 'Si, descargar',
      cancelButtonText: 'No, cerrar',
      reverseButtons: true
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let timerInterval

        const spiner = Swal.fire({
          title: '¡Alerta de descarga de archivo!',
          html: '',
          timer: 30000,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(500)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })

        let data = previousData
        if (data.length === 0) {
          if (date !== undefined) {
            const res = await getData('reports', JSON.stringify(json))
            data = res.data
          } else {
            const res = table === 'devices' ? await getData(table, null) : await getData(table)
            data = res.data
          }
        }
        if (data.length !== 0) {
          data.map((info) => {
            const soSplit = info?.so.split('_-_')
            const so = `${soSplit[0]} ${soSplit[1]}`
            info.so = so
            const modelSplit = info?.model.split('_-_')
            const model = `${modelSplit[0]} ${modelSplit[1]}`
            info.model = model
            info.last_date = formatDate(info.last_date)
            return info.last_date
          })
        }

        const doc = new JsPDF({
          orientation: 'landscape'
        })
        autoTable(doc, {
          columns: exportColumns,
          body: data
        })
        doc.save(`table-${table}.pdf`)
        spiner.close()
      }
    })
}

const convertToCsv = (data, columns, columnsHeader, separador = ',') => {
  return [
    columnsHeader.join(separador),
    ...data.map((info) =>
      columns.reduce(
        (a, p) =>
          `${a}${!a.length ? '' : separador}${info[p] ? info[p] : ''}`,
        ''
      )
    )
  ].join('\n')
}

export const exportCSV = async (date, columns) => {
  // console.log(columns)
  const json = {
    like: null,
    include: date + ' 00:00:00.000 +00:00',
    reports: true
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons
    .fire({
      title: 'Descargar tabla en formato CSV?',
      icon: 'warning',
      text: 'Puede tardar el proceso de descarga',
      showCancelButton: true,
      confirmButtonText: 'Si, descargar',
      cancelButtonText: 'No, cerrar',
      reverseButtons: true
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let timerInterval
        Swal.fire({
          title: '¡Alerta de descarga de archivo!',
          html: '',
          timer: 5000,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })

        let data

        if (date !== undefined) {
          const res = await getData('reports', JSON.stringify(json))
          data = res.data
        } else {
          const res = await getData('devices', null)
          data = res.data
        }

        if (data.length !== 0) {
          data.map((info) => {
            info.last_date = formatDate(info.last_date)
            const soSplit = info?.so.split('_-_')
            const so = `${soSplit[0]} ${soSplit[1]}`
            info.so = so
            const modelSplit = info?.model.split('_-_')
            const model = `${modelSplit[0]} ${modelSplit[1]}`
            info.model = model
            delete info.groups
            delete info.type
            delete info.thiefId
            delete info.status_lock
            delete info.statId
            delete info.stat
            delete info.motive_lock
            delete info.locationId
            delete info.connectionId
            delete info.id
            delete info.id_device
            delete info.version
            return info.connection
          })
        }

        const csvContent = convertToCsv(
          data,
          columns.map((column) => { return column.field }),
          columns.map((column) => { return column.header })
        )
        const blob = new Blob([csvContent], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'table-devices.csv')
        a.click()
      }
    })
}

// Categorias
const categories = [
  { name: 'No Permitidas', id: 0 },
  { name: 'Sistema', id: 1 },
  { name: 'Educativas', id: 2 },
  { name: 'No Educativas', id: 3 },
  { name: 'Productividad', id: 4 },
  { name: 'Internet', id: 5 },
  { name: 'Ocio', id: 6 },
  { name: 'Otras', id: 7 },
  { name: 'Sin Asignar', id: 8 },
  { name: 'Todas', id: 9 }
]

export const exportCSVApps = async (groupApp, dateFilter, search) => {
  const json = {
    groupApp,
    search,
    dateFilter,
    exportData: true
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons
    .fire({
      title: 'Descargar tabla en formato CSV?',
      icon: 'warning',
      text: 'Puede tardar el proceso de descarga',
      showCancelButton: true,
      confirmButtonText: 'Si, descargar',
      cancelButtonText: 'No, cerrar',
      reverseButtons: true
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let timerInterval
        Swal.fire({
          title: '¡Alerta de descarga de archivo!',
          html: '',
          timer: 5000,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })

        const res = await getData('applications', JSON.stringify(json))

        const data = res.data

        if (data?.length !== 0) {
          data.map((info) => {
            info.totalTimeSeconds = secondsToString(info.totalTimeSeconds)
            info.groupApp = info.groupApp === null ? categories[8].name : categories[info.groupApp].name
            delete info.id
            delete info.last_date
            delete info.installPath
            delete info.system
            delete info.version
            return info
          })
        }

        const csvContent = convertToCsv(
          data,
          ['app', 'uninstall', 'totalTimeSeconds', 'groupApp'],
          ['Nombre', 'Package', 'Tiempo de Uso(hs:min:seg)', 'Categoría']
        )
        const blob = new Blob([csvContent], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'table-apps.csv')
        a.click()
      }
    })
}

export const exportPdfApps = async (groupApp, dateFilter, search, columns) => {
  // console.log(previousData)
  const exportColumns =
  columns.map((col) => { return { dataKey: col.field, header: col.header } }).sort((a, b) => a.header.length - b.header.length)

  const json = {
    groupApp,
    search,
    dateFilter,
    exportData: true
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons
    .fire({
      title: 'Descargar tabla en formato PDF?',
      icon: 'warning',
      text: 'Puede tardar el proceso de descarga',
      showCancelButton: true,
      confirmButtonText: 'Si, descargar',
      cancelButtonText: 'No, cerrar',
      reverseButtons: true
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let timerInterval

        const spiner = Swal.fire({
          title: '¡Alerta de descarga de archivo!',
          html: '',
          timer: 30000,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(500)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })

        const res = await getData('applications', JSON.stringify(json))
        const data = res.data

        if (data?.length !== 0) {
          data.map((info) => {
            info.totalTimeSeconds = secondsToString(info.totalTimeSeconds)
            info.groupApp = info.groupApp === null ? categories[8].name : categories[info.groupApp].name
            delete info.id
            delete info.last_date
            delete info.installPath
            delete info.system
            delete info.version
            return info
          })
        }

        const doc = new JsPDF({
          orientation: 'landscape'
        })
        autoTable(doc, {
          columns: exportColumns,
          body: data
        })
        doc.save('table-apps.pdf')
        spiner.close()
      }
    })
}

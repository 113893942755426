import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputsTree from './InputsTree'
import { ProgressSpinner } from 'primereact/progressspinner'
import { getJson } from '../utils/utils'
import styles from '../../../../css/Roles/OptionsSectionsTree.module.css'
import DynamicCheckBox from './DynamicCheckBox'

export default function ContainerInputsTree ({ checks, setChecks, show, role, setRole, onChange, component, label, title, setCheckSelectAll, edit }) {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState([])

  useEffect(() => {
    setInputs([])
  }, [show])

  useEffect(() => {
    setInputs([])
    if (component && label) {
      setLoading(true)
      const json = getJson(label)
      const array = []
      if (array.length === 0) {
        Object.entries(json).forEach(([key, value]) => {
          value.keyJson = label + 'Page'
          array.push(value)
        })
      }
      setInputs(array)
      setLoading(false)
    }
  }, [component])

  return (
        <div className={styles.containerInputsSection} id={`${label}`}>
          {loading && <ProgressSpinner style={{ width: '5rem', height: '5rem' }} strokeWidth="5" />}
          <div className={styles.containerTitleSection}>
            <DynamicCheckBox
              show={show}
              label={label}
              role={role}
              component={component}
              setRole={setRole}
              checks={checks}
              setChecks={setChecks}
              setCheckSelectAll={setCheckSelectAll}
              edit={edit}
            />
            <p className={styles.title}>{title}:</p>
          </div>
          <InputsTree inputs={inputs} role={role} setRole={setRole} onChange={onChange} component={component} label={label} />
        </div>
  )
}

ContainerInputsTree.propTypes = {
  checks: PropTypes.array,
  setChecks: PropTypes.func,
  role: PropTypes.object,
  setRole: PropTypes.func,
  onChange: PropTypes.func,
  component: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  setCheckSelectAll: PropTypes.func,
  edit: PropTypes.bool
}

import React from 'react'
import { Button } from 'primereact/button'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../../css/generals/dataTable.css'

export default function ActionDashboard ({ type, id, data }) {
  return (
    <div>
      <NavLink
        to={`/mdm/${type}/${id}`}
        className="nav-link hoverly"
        state={data}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }}
      >
        <Button
          icon="pi pi-ellipsis-h"
          className="btn-white p-button-rounded"
          aria-label="Search"
        />
      </NavLink>
    </div>
  )
}

ActionDashboard.propTypes = {
  type: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object
}
